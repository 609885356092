import React from "react";
import "./App.scss";

declare type AppColorMode = "light" | "dark" | "white" | null | undefined;

function Regulations() {
  const queryParams = new URLSearchParams(window.location.search);
  const theme = queryParams.get("theme") as AppColorMode;
  let className = 'light-theme';
  if(theme === "dark"){
     className = "dark-theme";
  } else if(theme === "white"){
     className = "white-theme"
  }
  return (
    <article className={className}>
        <h1>REGULAMIN PLATFORMY APETIGO.COM OBOWIĄZUJĄCY W DNIACH OD 14 LISTOPADA 2022 ROKU DO 5 LIPCA 2023 ROKU</h1>
        <h2>1. DEFINICJE</h2>
        <ol>
            <li>
                Użyte w Regulaminie zwroty pisane wielką literą należy rozumieć w następujący sposób:
                <ol type={"a"}>
                    <li><strong>Aplikacja Mobilna</strong> - aplikacja mobilna, która może być udostępniana Klientowi przez Usługodawcę, przeznaczona do instalacji na posiadanym przez Klienta urządzeniu przenośnym i pozwalająca w szczególności na korzystanie z Platformy, w tym Usług Elektronicznych, bez potrzeby uruchamiania przeglądarki internetowej;</li>
                    <li><strong>Cena</strong> – określona w złotych polskich lub w innej walucie kwota brutto (uwzględniająca podatek), którą Klient zobowiązany jest zapłacić w związku z Zamówieniem i zawarciem Umowy dot. Zamówienia;</li>
                    <li><strong>Dostawa</strong> – opcjonalna odpłatna usługa dla Klienta powiązana z Zamówieniem polegająca na odbiorze Produktów z Lokalu, transport oraz doręczeniu Produktów Klientowi na wskazany przez niego w ramach Zamówienia adres;</li>
                    <li><strong>Dzień Roboczy</strong> – jeden dzień od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy;</li>
                    <li><strong>Hasło</strong> – ciąg znaków alfanumerycznych, konieczny do dokonania autoryzacji w trakcie uzyskiwania dostępu do Konta. Klient jest zobowiązany do zachowania Hasła w ścisłej poufności (do nieujawniana go jakimkolwiek osobom trzecim). Hasło może być zmienione w ustawieniach Konta. Usługodawca w zależności od swojej decyzji może udostępnić Klientowi mechanizm tzw. odzyskiwania Hasła;</li>
                    <li><strong>Klient</strong> – podmiot składający Zamówienie za pośrednictwem Platformy, zawierający Umowę dot. Zamówienia lub korzystający z Usługi Elektronicznej zgodnie z Regulaminie, który posiada zdolność do czynności prawnych w zakresie umożliwiającym skuteczne dokonanie określonej czynności w ramach Platformy. Klient będący osobą fizyczną o ograniczonej zdolności do czynności prawnych, jest zobowiązany do uzyskania odpowiedniej zgody swojego przedstawiciela ustawowego na zawarcie Umowy o Świadczenie Usług lub Umowy dot. Zamówienia oraz przedstawić taką zgodę na żądanie Usługodawcy lub Restauracji (w zależności od tego kto jest stroną danej umowy), przy czym co do zasady Umowa o Świadczenie Usług oraz Umowy dot. Zamówienia zawierane za pośrednictwem Platformy mają charakter umów powszechnie zawieranych w drobnych bieżących sprawach życia codziennego. W uzasadnionych przypadkach zawarcie Umowy dot. Zamówienia za pośrednictwem Platformy będzie możliwe jedynie przez osoby powyżej 18 roku życia oraz posiadające pełną zdolność do czynności prawnych;</li>
                    <li><strong>Kodeks Cywilny</strong> – ustawa z dnia 23 kwietnia 1964 r. Kodeks cywilny (t.j. Dz. U. z 2022 r. poz. 1360 ze zm.);</li>
                    <li><strong>Konsument</strong> – Klient, który jest osobą fizyczną dokonującą z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową;</li>
                    <li><strong>Konto</strong> – Usługa Elektroniczna udostępniana Klientowi w ramach Platformy, pozwalająca na korzystanie przez Klienta z funkcjonalności/usług w ramach Platformy. Klient uzyskuje dostęp do Konta za pomocą Loginu i powiązanego z nim Hasła. Klient loguje się na swoje Konto po dokonaniu rejestracji w Platformie. Konto umożliwia zapisywanie i przechowywanie informacji o danych adresowych, dostęp do historii Zamówień oraz innych usług udostępnianych przez Usługodawcę;</li>
                    <li><strong>Koszyk</strong> – Usługa Elektroniczna udostępniana Klientowi w ramach Platformy, polegająca na umożliwieniu mu złożenia Zamówienia, w tym wyświetlenia podsumowania Ceny związanej z Zamówieniem;</li>
                    <li><strong>Login</strong> – indywidualny identyfikator Klienta w Platformie i jednocześnie adres e-mail Klienta podany Usługodawcy podczas tworzenia Konta;</li>
                    <li><strong>Lokal</strong> – miejsce, w którym Restauracja prowadzi działalność gospodarczą – miejsce odbioru Produktów przez Klientów lub osoby realizujące Dostawę;</li>
                    <li><strong>Menu</strong> – lista Produktów oferowanych na sprzedaż w ramach Platformy przez Restaurację wraz z ich opisem, zdjęciem oraz ceną, dodana w ramach Platformy na stronie Restauracji i dostępna dla Klienta w ramach Platformy, przy czym nie stanowi ona oferty w rozumieniu Kodeksu Cywilnego;</li>
                    <li><strong>Newsletter</strong> – Usługa Elektroniczna, która umożliwia Klientowi otrzymywanie od Usługodawcy informacji handlowych, w szczególności o Produktach, Platformie, Restauracjach, w tym nowościach i promocjach, na podany przez Klienta adres e-mail lub numer telefonu, za zgodą Klienta;</li>
                    <li><strong>Platforma</strong> – oprogramowanie Usługodawcy (internetowa platforma handlowa) umożliwiające świadczenie Usług Elektronicznych, dostępna pod następującym adresem internetowym: apetigo.com lub za pośrednictwem Aplikacji Mobilnej;</li>
                    <li><strong>Prawo Autorskie</strong> – ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t.j. Dz. U. z 2021 r. poz. 1062 ze zm.);</li>
                    <li><strong>Produkt</strong> – towar znajdujący się w Menu, stanowiący wyłącznie posiłki (w tym przetworzone termicznie), napoje i inne produkty spożywcze;</li>
                    <li><strong>Przedsiębiorca</strong> – Klient, niebędący Konsumentem, który zawiera Umowę dot. Zamówienia lub Umowę o Świadczenie Usług w celu związanym bezpośrednio lub pośrednio z prowadzoną działalnością gospodarczą lub zawodową;</li>
                    <li><strong>Regulamin / Umowa o Świadczenie Usług</strong> – niniejszy dokument określający zasady funkcjonowania Platformy, w tym korzystania z Usług Elektronicznych. Regulamin określa prawa oraz obowiązki Klienta i Usługodawcy oraz Restauracji (w zakresie w jakim Restauracje są stronami umów z Klientami). Regulamin jest regulaminem, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, a także stanowi wzorzec umowny w rozumieniu art. 384 § 1 Kodeksu Cywilnego;</li>
                    <li><strong>Restauracja</strong> – przedsiębiorca w rozumieniu Kodeksu Cywilnego, z którym można za pośrednictwem Platformy zawierać Umowy dot. Zamówienia; Restauracje w ramach Platformy prowadzą jedynie sprzedaż detaliczną;</li>
                    <li><strong>Treść</strong> – element tekstowy, graficzny lub multimedialny (np. informacja o Produkcie, jego fotografia, opis) w tym utwór w rozumieniu Prawa Autorskiego oraz wizerunek osoby fizycznej, jaki jest zamieszczany i rozpowszechniany w ramach Platformy przez Usługodawcę, Restauracje kontrahentów Usługodawcy lub inne osoby korzystające z Platformy;</li>
                    <li><strong>Umowa dot. Zamówienia</strong> – umowa dot. Zamówienia zawierana pomiędzy Restauracją a Klientem za pośrednictwem Platformy, której przedmiotem jest sprzedaż Produktu przez Restaurację na rzecz Klienta, a w przypadku Zamówienia z Dostawą obejmuje ona także usługę dostarczenia Produktów Klientowi; Usługodawca nie jest stroną Umowy dot. Zamówienia;</li>
                    <li><strong>Usługa Elektroniczna</strong> – usługa świadczona drogą elektroniczną w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t.j. Dz. U. z 2020 r. poz. 344, ze zm.), przez Usługodawcę na rzecz Klienta za pośrednictwem Platformy, zgodnie z Regulaminem;</li>
                    <li><strong>Usługodawca</strong> – APETIGO.COM spółka z ograniczoną odpowiedzialnością z siedzibą w Zielonej Górze, adres: ul. Wrocławska nr 17B, 65-427 Zielona Góra, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Zielonej Górze; VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000925315, NIP: 9731080892; kapitał zakładowy 100.000,00 złotych; adres poczty elektronicznej: info@apetigo.com.</li>
                    <li><strong>Wymagania Techniczne</strong> – minimalne wymagania techniczne, których spełnienie jest niezbędne do współpracy z systemem teleinformatycznym, którym posługuje się Usługodawca, w tym zawarcia umowy określonej Regulaminem oraz korzystania z Platformy, w tym Usług Elektronicznych, tj.: (1) Urządzenie mobilne zainstalowanym systemem operacyjnym iOS w wersji 13 i wyższej, systemem Android w wersji 9 i wyższej, z dostępem do Internetu (2) dostęp do poczty elektronicznej; (3) dostępem do sklepu mobilnego Apple Store lub Google Play oraz wystarczająca liczba miejsca na dysku twardym do zainstalowania aplikacji apetigo (4) zalecana minimalna rozdzielczość ekranu: 1334x750.</li>
                    <li><strong>Zamówienie</strong> – oświadczenie woli Klienta wyrażające wolę zawarcia Umowy dot. Zamówienia, określające Produkty, co do których Klient składa ofertę zawarcia umowy, dane Klienta konieczne dla ewentualnego zawarcia i wykonania umowy, oraz formę odbioru / dostawy. Zamówienie każdego Produktu jest traktowane jako niezależna oferta Klienta zawarcia umowy (ułatwienie techniczne). Akceptacja Zamówienia oznacza zawarcie Umowy dot. Zamówienia. W ramach Platformy może zostać wskazana minimalna wartość Zamówienia, które może zostać złożone za pośrednictwem Platformy (minimalna wartość Zamówienia może się różnić w zależności od Restauracji).</li>
                </ol>
            </li>
        </ol>
        <h2>2. POSTANOWIENIA OGÓLNE</h2>
        <ol>
            <li>Przedmiotem Regulaminu jest określenie ogólnych warunków korzystania z Platformy, w tym składania Zamówień, zawierania Umów dot. Zamówienia oraz zasad korzystania z innych Usług Elektronicznych świadczonych przez Usługodawcę.</li>
            <li>Regulamin skierowany jest zarówno do Klientów będących Konsumentami, jak i Przedsiębiorców.</li>
            <li>Akceptacja Regulaminu jest dobrowolna, ale konieczna w celu utworzenia Konta oraz składania Zamówienia i zawierania Umów dot. Zamówienia, a także może okazać się konieczna w celu korzystania z innych Usług Elektronicznych świadczonych przez Usługodawcę.</li>
            <li>Informacje prezentowane w ramach Platformy oraz w Newsletterze stanowią jedynie zaproszenie do zawarcia umowy w rozumieniu art. 71 Kodeksu Cywilnego, kierowane do Klientów, a nie ofertę w myśl przepisów Kodeksu Cywilnego.</li>
            <li>Korzystanie z Platformy za pośrednictwem przeglądarki internetowej lub Aplikacji Mobilnej, w tym składanie Zamówień, może być związane z koniecznością poniesienia kosztów połączenia z siecią Internet (opłata za przesyłanie danych), zgodnie z pakietem taryfowym dostawcy usług, z którego korzysta Klient.</li>
            <li>Korzystanie z Usług Elektronicznych jako usług świadczonych drogą elektroniczną, wiąże się z typowymi zagrożeniami dotyczącymi przekazywania danych poprzez Internet, takimi jak rozpowszechnienie danych Klienta, uzyskiwanie do nich dostępu przez osoby nieuprawnione lub utrata danych Klienta. Strony zobowiązane są podejmować działania mające na celu minimalizację tych ryzyk. Usługodawca w szczególności poprzez stosowanie odpowiednich zabezpieczeń, o których mowa w Regulaminie, a Klient w szczególności poprzez nieudostępnianie osobom nieupoważnionym Loginu i Hasła.</li>
            <li>Usługodawca może powierzyć wykonywanie swoich poszczególnych obowiązków lub bieżącą obsługę Platformy podwykonawcom. Usługodawca dołoży starań, aby nie miało to wpływu na zakres oraz jakość Usług Elektronicznych.</li>
        </ol>
        <h2>3. USŁUGI ELEKTRONICZNE</h2>
        <ol>
            <li>
                Usługodawca zgodnie z Regulaminem za pośrednictwem Platformy świadczy na rzecz Klientów nieodpłatnie następujące Usługi Elektroniczne:
                <ol type={"a"}>
                    <li>umożliwienie założenia Konta;</li>
                    <li>przechowywanie i udostępnianie Klientowi za pośrednictwem Konta historii Zamówień;</li>
                    <li>umożliwianie Klientom składania Zamówień i zawierania Umów dot. Zamówienia na zasadach określonych w Regulaminie;</li>
                    <li>umożliwienie korzystania z Koszyka;</li>
                    <li>umożliwienie przeglądania Treści umieszczonych w ramach Platformy;</li>
                    <li>Newsletter;</li>
                    <li>dodawanie relacji, ocen, oraz opinii dotyczących Zamówienia, Produktów oraz Restauracji.</li>
                </ol>
            </li>
            <li>Umowa o Świadczenie Usług zostaje zawarta z chwilą łącznego spełnienia następujących warunków: (i) skutecznego wypełnienia i zaakceptowania przez Klienta (przesłania Usługodawcy) formularza rejestracyjnego w celu utworzenia Konta; (ii) akceptacji Regulaminu; oraz (iii) potwierdzenia przez Klienta zawarcia Umowy o Świadczenie Usług poprzez kliknięcie w link aktywacyjny wysłany przez Usługodawcę na adres poczty elektronicznej podany przez Klienta w procesie rejestracji Konta.</li>
            <li>Usługodawca może odmówić zarejestrowania Konta, jeżeli Klient narusza postanowienia ust. 9 lit. a), b), f), g) poniżej.</li>
            <li>Konto świadczone jest bez dodatkowych opłat na czas nieoznaczony. Założenie Konta jest wymagane, aby korzystać z Usług Elektronicznych, z wyjątkiem usługi Newsletter. Klient ma możliwość wypowiedzenia Umowy o Świadczenie Usług (rezygnacji z Konta) na zasadach wskazanych w pkt 13 Regulaminu. Usługodawca może ingerować w strukturę techniczną Konta, w celu zdiagnozowania ew. nieprawidłowości w funkcjonowaniu Platformy.</li>
            <li>Korzystanie z Koszyka rozpoczyna się z momentem dodania przez Klienta pierwszego Produktu do Koszyka. Koszyk świadczony jest bez dodatkowych opłat oraz ma charakter jednorazowy i ulega zakończeniu z chwilą złożenia lub zaprzestania składania Zamówienia za jego pośrednictwem przez Klienta. Koszyk zapamiętuje informacje o Produktach wybranych przez Klienta również po zakończeniu sesji przeglądarki, w tym również wylogowaniu, przez okres dostępności Produktów lub do momentu usunięcia ich przez Klienta z Koszyka. Koszyk nie zapewnia jednak dostępności Produktów wybranych przez Klienta w celu umożliwienia złożenia Zamówienia w późniejszym terminie.</li>
            <li>W celu rozpoczęcia świadczenia Newslettera, wymagane jest wyrażenie przez Klienta zgody na otrzymywanie informacji handlowych przez zaznaczenie odpowiedniego checkboxa oraz udostępnienie swojego adresu e-mail w odpowiednim polu na stronie Platformy (moment rozpoczęcia świadczenia Newslettera). Klient może również wyrazić zgodę na otrzymywanie Newslettera za pośrednictwem wiadomości SMS przez udostępnienie swojego numeru telefonu komórkowego oraz zaznaczenie odpowiedniego checkboxa w ramach Platformy.</li>
            <li>Newsletter świadczony jest bez dodatkowych opłat przez czas nieoznaczony. Klient ma możliwość rezygnacji z Newslettera w każdej chwili i bez podania przyczyny, w szczególności poprzez kliknięcie w link dezaktywacyjny znajdujący się w wiadomości e-mail przesyłanej do Klienta lub wysłanie stosownego żądania do Usługodawcy za pośrednictwem poczty elektronicznej na adres: rodo@apetigo.com.</li>
            <li>Jedną z funkcjonalności Platformy jest możliwość dodawania relacji, ocen, oraz opinii dotyczących Zamówienia, Produktów jak i Restauracji. W ramach tej funkcjonalności Klienci mają możliwość filtrowania Produktów lub Restauracji z wyborem kryterium ocen innych Klientów. Usługodawca nie ingeruje w treść relacji, ocen, oraz opinii, z zastrzeżeniem uprawnienia Usługodawcy do usuwania treści w szczególności niezgodnych z prawem, naruszających prawa autorskie, dobra osobiste i inne prawa osób trzecich, wulgarne lub sprzeczne z dobrymi obyczajami.</li>
            <li>
                Klient zobowiązany jest w szczególności do:
                <ol type={"a"}>
                    <li>podawania w Zamówieniu oraz w ramach Konta wyłącznie prawdziwych, aktualnych i koniecznych danych Klienta, a w przypadku ich zmiany do niezwłocznego aktualizowania danych, w tym danych osobowych, podanych przez Klienta w związku z zawarciem Umowy dot. Zamówienia lub korzystaniem z Usług Elektronicznych;</li>
                    <li>korzystania z Usług Elektronicznych udostępnianych przez Usługodawcę w sposób niezakłócający funkcjonowania Usługodawcy, Restauracji oraz Platformy;</li>
                    <li>korzystania z Usług Elektronicznych w sposób zgodny z powszechnie obowiązującymi przepisami prawa, postanowieniami Regulaminu, a także z przyjętymi w danym zakresie zwyczajami i zasadami współżycia społecznego;</li>
                    <li>zachowania w poufności Loginu i Hasła, w szczególności nieudostępniania ich osobom nieupoważnionym. Usługodawca, a także Restauracje nie ponoszą odpowiedzialności w szczególności za utratę danych lub wgląd do danych zapisanych na Koncie, wynikające z naruszenia powyższego obowiązku przez Klienta;</li>
                    <li>terminowej zapłaty Ceny oraz innych ustalonych w Umowie dot. Zamówienia kosztów w pełnej wysokości;</li>
                    <li>niedostarczania i nieprzekazywania w ramach Platformy jakichkolwiek treści zabronionych przez powszechnie obowiązujące przepisy prawa, w szczególności treści naruszających prawa autorskie osób trzecich lub ich dobra osobiste;</li>
                    <li>niepodejmowania działań takich jak: (i) rozsyłanie lub umieszczanie w Platformie niezamówionej informacji handlowej lub umieszczanie jakichkolwiek treści naruszających powszechnie obowiązujące przepisy prawa (zakaz umieszczania treści bezprawnych), (ii) podejmowanie czynności informatycznych lub wszelkich innych czynności mających na celu wejście w posiadanie informacji nieprzeznaczonych dla Klienta, w tym danych innych Klientów lub ingerencję w zasady lub techniczne aspekty funkcjonowania Platformy, (iii) modyfikowanie w sposób nieuprawniony Treści dostarczanych przez Usługodawcę lub Restauracje, w szczególności podanych Cen lub opisów Produktów w ramach Platformy.</li>
                </ol>
            </li>
            <li>Usługodawca może umożliwić Klientowi bezpłatne pobranie Aplikacji Mobilnej na urządzenie przenośne ze sklepów internetowych z aplikacjami mobilnymi (np. Apple App Store lub Google Play). Aplikacja Mobilna łączy się Platformą za pomocą sieci Internet. Klient chcąc skorzystać z Aplikacji Mobilnej powinien przejść następujące etapy: (i) zapoznanie się i akceptacja regulaminu odpowiedniego sklepu internetowego z aplikacjami mobilnymi, (ii) pobranie Aplikacji Mobilnej oraz jej instalacja na urządzeniu przenośnym.</li>
            <li>Aplikacja Mobilna może korzystać z technologii przechowującej i uzyskującej dostęp do identyfikatora Klienta, umożliwiającej działanie Aplikacji Mobilnej w trybie offline, a także zapisywanie preferencji Klienta (co może wiązać się z dostępem do pamięci urządzenia przenośnego). W przypadku chęci skorzystania z funkcjonalności Aplikacji Mobilnej niezbędne może okazać się wyrażenie przez Klienta dobrowolnej zgody na uzyskiwanie przez Aplikację Mobilną dostępu do niektórych funkcjonalności urządzenia przenośnego, wyrażenie przez Klienta dobrowolnej zgody na otrzymywanie powiadomień „push” lub udzielenie Usługodawcy informacji o preferencjach Klienta. Klient w dowolnym momencie może cofnąć zgody, o których mowa powyżej lub odinstalować (usunąć) Aplikację Mobilną ze swojego urządzenia przenośnego.</li>
            <li>Usługodawca może ze względów technicznych czasowo, nie dłużej jednak niż na 72 godzin wyłączyć niektóre funkcjonalności Platformy w celu jej ulepszenia, dodawania usług lub przeprowadzania konserwacji za uprzednim poinformowaniem Klienta z odpowiednim wyprzedzeniem poprzez umieszczenie stosownego komunikatu na stronie głównej Platformy.</li>
            <li>Reklamacje związane z Usługami Elektronicznymi można składać np. pisemnie na adres APETIGO.COM sp. z o.o. ul. Wrocławska 17B, 65-427 Zielona Góra lub na adres e-mail: info@apetigo.com. W zgłoszeniu reklamacji związanej z Usługami Elektronicznymi zaleca się opisanie jej przyczyny, żądania Klienta oraz danych kontaktowych Klienta - ułatwi to i przyspieszy rozpatrzenie takiej reklamacji. Usługodawca rozpatruje reklamację, o której mowa w niniejszym ustępie w terminie do 30 dni od dnia jej złożenia, chyba że z powszechnie obowiązujących przepisów prawa wynika inny termin.</li>
        </ol>
        <h2>4. ZAMÓWIENIA</h2>
        <ol>
            <li>W ramach Platformy, na zasadach określonych w Regulaminie, możliwe jest składanie Zamówień oraz zawieranie Umów dot. Zamówienia z Restauracjami. Usługodawca nie jest stroną Umów dot. Zamówienia i nie ponosi odpowiedzialności za ich realizację przez Restaurację. Wszelkie warunki związane z Umową dot. Zamówienia są ustalane pomiędzy Klientem a Restauracją, z którą zawierana jest Umowa dot. Zamówienia.</li>
            <li>
                Zawarcie Umowy dot. Zamówienia pomiędzy Klientem a Restauracją następuje po uprzednim złożeniu przez Klienta Zamówienia. Klient składa Zamówienie w ramach Platformy w następujący sposób:
                <ol type={"a"}>
                    <li>Klient dodaje wybrany/e Produkt/y do Koszyka;</li>
                    <li>Klient przechodzi do formularza danych do Zamówienia;</li>
                    <li>Klient składając Zamówienie ma możliwość wyboru formy realizacji Zamówienia w formie: (i) Zamówienie z Dostawą; (ii) Zamówienie z odbiorem osobistym w Lokalu; albo (iii) Zamówienie do wybranego stolika w Lokalu (dostępne formy realizacji Zamówienia mogą się różnić w zależności od tego w jakiej Restauracji jest składane Zamówienie);</li>
                    <li>dodatkowo Klient wypełnia formularz Zamówienia w zakresie jego danych niezbędnych do zawarcia i realizacji Umowy dot. Zamówienia;</li>
                    <li>w przypadku Przedsiębiorców niezbędne jest także podanie nazwy (firmy) oraz numeru NIP. Dodatkowo podanie numeru NIP jest wymagane na etapie składania Zamówienia, jeśli Klient chce otrzymać fakturę VAT za Zamówienie. Odmowa wskazania numeru NIP na etapie składania Zamówienia przez Klientów wskazanych w niniejszym postanowieniu, oznacza brak możliwości wystawienia faktury VAT;</li>
                    <li>Klient wybiera sposób zapłaty Ceny i ewentualnych dodatkowych kosztów;</li>
                    <li>Klient z pomocą przycisku „Zamawiam i płacę” potwierdza Zamówienie za pomocą udostępnionej w tym celu funkcjonalności Platformy;</li>
                    <li>w zależności od wybranego sposobu zapłaty, Klient może zostać przekierowany do strony zewnętrznego dostawcy usług płatniczych, w celu dokonania płatności.</li>
                </ol>
            </li>
            <li>Klient w odpowiedzi na Zamówienie niezwłocznie otrzyma wiadomość e-mail z potwierdzeniem otrzymania Zamówienia przez Restaurację i rozpoczęcia jego weryfikacji.</li>
            <li>
                Po zweryfikowaniu Zamówienia, Klient otrzymuje wiadomość e-mail z:
                <ol type={"a"}>
                    <li>potwierdzeniem przyjęcia Zamówienia do realizacji, albo</li>
                    <li>informacją o braku przyjęcia Zamówienia do realizacji.</li>
                </ol>
            </li>
            <li>Umowa dot. Zamówienia zawarta jest z chwilą otrzymania przez Klienta wiadomości wskazanej w ust. 4 lit. a) powyżej w zakresie wskazanych w niej Produktów. W przypadku nieprzyjęcia Zamówienia do realizacji Umowa dot. Zamówienia nie zostaje zawarta, a Klient niezwłocznie, nie później niż w ciągu 5 dni, otrzyma zwrot płatności uiszczonych w związku Zamówieniem przy użyciu takiego samego sposobu płatności, jakiego użył Klient. Restauracja ma prawo nie przyjąć Zamówienia do realizacji, w szczególności w przypadku naruszenia przez Klienta postanowień pkt 3 ust. 9 Regulaminu lub wystąpienia zdarzeń, które skutkują brakiem możliwości przyjęcia Zamówienia do realizacji, np. brak Produktów.</li>
            <li>Całkowita wartość Zamówienia obejmuje Cenę, w tym koszty Dostawy (w przypadku wyboru formy realizacji Zamówienia z Dostawą) oraz ew. inne koszty związane z Zamówieniem. O łącznej Cenie wraz z podatkami, a także o kosztach Dostawy oraz ew. innych kosztach związanych z Zamówieniem, Klient jest informowany w trakcie składania Zamówienia, w tym również w chwili wyrażenia przez Klienta woli związania się Umową dot. Zamówienia.</li>
        </ol>

        <h2>5. DOSTAWA / ODBIÓR ZAMÓWIENIA</h2>
        <ol>
            <li>W przypadku, gdy Restauracja udostępnia możliwość złożenia Zamówienia z Dostawą, Dostawa jest realizowana na adres wskazany w Zamówieniu przez Klienta jako adres Dostawy. O dostępności Dostawy Klient jest informowany w ramach Platformy, w tym w chwili składania przez Klienta Zamówienia. Dostępność Dostawy może zależeć w szczególności od tego, czy adres Dostawy wskazany przez Klienta znajduje się w obszarze realizacji Dostawy. Możliwość skorzystania z Dostawy może zostać ograniczona z uwagi na próg minimalnej wartości Zamówienia. Dostawa Produktu do Klienta jest odpłatna, chyba że co innego wynika z zawartej Umowy dot. Zamówienia.</li>
            <li>W przypadku Zamówienia z Dostawą, w ramach Platformy może zostać wskazany przybliżony czas Dostawy (czas może się różnić w zależności od lokalizacji Klienta lub położenia Lokalu). Przybliżony czas Dostawy nie jest wiążący i może ulegać zmianie.</li>
            <li>W przypadku Zamówienia z Dostawą, Klient jest zobowiązany do przebywania pod adresem wskazanym w Zamówienia jako adres Dostawy w celu odbioru Zamówienia od osoby realizującej Dostawę. W przypadku nieobecności Klienta pod adresem wskazanym w Zamówieniu jako adres Dostawy, osoba realizująca Dostawę podejmie próbę kontaktu z Klientem w celu ustalenia miejsca pozostawienia Zamówienia, przy czym Klient nie ma możliwości zmiany adresu Dostawy. W przypadku braku możliwości skontaktowania się z Klientem, Zamówienie zostanie zutylizowane lub zwrócone do Restauracji.</li>
            <li>W przypadku Zamówienia z odbiorem osobistym w Lokalu, Klient jest zobowiązany do odbioru Zamówienia o godzinie wskazanej w ramach Platformy lub w ramach wiadomości mailowej ze wskazaniem możliwej godziny odbioru Zamówienia w Lokalu.</li>
            <li>Status realizacji Zamówienia jest udostępniany w ramach Platformy.</li>
        </ol>
        <h2>6. PŁATNOŚCI</h2>
        <ol>
            <li>Dostępne sposoby płatności są określone w ramach Platformy, w tym w chwili składania przez Klienta Zamówienia. Dostępne sposoby płatności mogą zależeć od tego z jaką Restauracją Klient zawiera Umowę dot. Zamówienia, oraz wybranego sposobu realizacji Zamówienia. Rozliczenia transakcji płatnościami elektronicznymi i kartą płatnicza przeprowadzane są zgodnie z wyborem Klienta za pośrednictwem upoważnionych serwisów.</li>
            <li>Restauracja każdorazowo wystawi i załączy do Produktu paragon fiskalny (lub fakturę VAT, jeżeli dotyczy) potwierdzający uiszczenie Ceny (z wyróżnieniem kosztu Dostawy poniesionego przez Klienta). Usługodawca nie ponosi odpowiedzialności za wystawienie i wydanie paragonu fiskalnego (lub faktury VAT, jeżeli dotyczy).</li>
            <li>W przypadku nieuiszczenia przez Klienta płatności w związku z Zamówieniem, Zamówienie nie zostanie przyjęte do realizacji, o czym Klient zostanie poinformowany w ramach Platformy lub za pośrednictwem wiadomości e-mail.</li>
        </ol>
        <h2>7. REKLAMACJE</h2>
        <ol>
            <li>Restauracja ponosi odpowiedzialność za należytą realizację Umowy dot. Zamówienia wobec Klienta zgodnie z powszechnie obowiązującymi przepisami prawa, w tym przepisami Kodeksu Cywilnego. Usługodawca nie ponosi odpowiedzialności za należytą realizację Umów dot. Zamówień, a także nie udziela żadnych gwarancji. Usługodawca jest upoważniony do realizowania obsługi posprzedażowej Klientów w imieniu i na rzecz Restauracji. Restauracje są zobowiązane do dostarczenia Produktów bez wad.</li>
            <li>
                Reklamacje związane z Umową dot. Zamówienia można składać w następujący sposób:
                <ol type={"a"}>
                    <li>adres poczty elektronicznej: info@apetigo.com;</li>
                    <li>za pośrednictwem dedykowanego formularza w ramach Platformy.</li>
                </ol>
            </li>
            <li>Zaleca się podanie w opisie reklamacji numeru Zamówienia oraz informacji i okoliczności dotyczących przedmiotu reklamacji, w szczególności rodzaju i daty wystąpienia wady oraz danych kontaktowych składającego reklamację – ułatwi to i przyspieszy rozpatrzenie reklamacji. Wymogi podane w zdaniu poprzednim mają formę jedynie zalecenia i nie wpływają na skuteczność reklamacji złożonych z pominięciem zalecanego opisu reklamacji.</li>
            <li>Reklamacja zostanie rozpatrzona niezwłocznie, nie później niż w terminie 14 dni od dnia jej złożenia. Jeżeli reklamacja nie zostanie rozpatrzona w terminie 14 dni od dnia jej złożenia, uważa się, że reklamacja jest uzasadniona.</li>
        </ol>
        <h2>8. POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA ROSZCZEŃ</h2>
        <ol>
            <li>Skorzystanie z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń ma charakter dobrowolny. Poniższe postanowienia mają charakter informacyjny i nie stanowią zobowiązania Usługodawcy lub Restauracji do skorzystania z pozasądowych sposobów rozwiązywania sporów. Oświadczenie Usługodawcy lub Restauracji o zgodzie lub odmowie wzięcia udziału w postępowaniu w sprawie pozasądowego rozwiązywania sporów konsumenckich składane jest na papierze lub innym trwałym nośniku w przypadku, gdy w następstwie złożonej przez Konsumenta reklamacji spór nie został rozwiązany.</li>
            <li>Zasady prowadzenia postępowań w sprawie pozasądowego rozwiązywania sporów konsumenckich oraz obowiązki przedsiębiorców w tym zakresie określone są odrębnie w przepisach prawa lub w regulacjach stosowanych przez odpowiednie podmioty właściwe w zakresie rozwiązywania sporów konsumenckich. Szczegółowe informacje dotyczące możliwości skorzystania Konsumenta z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur mogą być dostępne w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników Konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona Konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej, w szczególności również pod następującym adresem internetowym Urzędu Ochrony Konkurencji i Konsumentów <i>https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php</i>.</li>
            <li>
                Konsument posiada następujące przykładowe możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:
                <ol type={"a"}>
                    <li>Konsument uprawniony jest do zwrócenia się do stałego polubownego sądu konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej, z wnioskiem o rozstrzygnięcie sporu wynikłego z zawartej umowy;</li>
                    <li>Konsument uprawniony jest do zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej, z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie pozasądowego rozwiązywania sporu;</li>
                    <li>Konsument może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu, korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika Konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona Konsumentów (m. in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).</li>
                </ol>
            </li>
            <li>Konsument może złożyć skargę za pośrednictwem platformy internetowej ODR: <i>http://ec.europa.eu/consumers/odr/</i>. Platforma ODR stanowi także źródło informacji na temat form pozasądowego rozstrzygania sporów mogących powstać pomiędzy przedsiębiorcami i Konsumentami.</li>
        </ol>
        <h2>9. PRAWO ODSTĄPIENIA OD UMOWY</h2>
        <ol>
            <li><u>Z zastrzeżeniem ust. 7 poniżej</u>, Konsument, który zawarł umowę na odległość, może od niej odstąpić w terminie 14 dni bez podawania przyczyny i bez ponoszenia jakichkolwiek kosztów innych niż te przewidziane przez powszechnie obowiązujące przepisy prawa. Do zachowania terminu wystarczy złożenie przez Konsumenta odpowiedniego oświadczenia Usługodawcy – w przypadku Umowy o Świadczenie Usług, lub Restauracji – w przypadku Umowy dot. Zamówienia przed jego upływem. Konsument może złożyć jakiekolwiek jednoznaczne oświadczenie, w którym poinformuje o swoim odstąpieniu od umowy. Oświadczenie o odstąpieniu od Umowy o Świadczenie Usług może zostać złożone na przykład w formie wiadomości e-mail na adres info@apetigo.com. Oświadczenie o odstąpieniu od Umowy dot. Zamówienia należy złożyć na adres określony przez Restaurację w ramach jej strony. W przypadku odstąpienia od umowy zawartej na odległość umowę uważa się za niezawartą</li>
            <li>Konsument może skorzystać z wzoru formularza odstąpienia, który stanowi załącznik do Regulaminu, jednak nie jest to obowiązkowe. W przypadku chęci odstąpienia od Umowy dot. Zamówienia prosi się o dodanie w miarę możliwości w oświadczeniu o odstąpieniu numeru Zamówienia – może to przyśpieszyć obsługę złożonego oświadczenia o odstąpieniu.</li>
            <li>
                Bieg terminu do odstąpienia od Umowy o Świadczenie Usług rozpoczyna się od dnia jej zawarcia, natomiast w przypadku Umowy dot. Zamówienia bieg terminu rozpoczyna się od dnia objęcia Produktu w posiadanie przez Konsumenta lub wskazaną przez niego osobę trzecią, a w przypadku Umowy dot. Zamówienia, która:
                <ol type={"a"}>
                    <li>obejmuje wiele Produktów, które są dostarczane osobno, partiami lub w częściach – od objęcia w posiadanie ostatniego Produktu, partii lub części; albo</li>
                    <li>polega na regularnym dostarczaniu Produktów przez czas oznaczony – od objęcia w posiadanie pierwszego z Produktów.</li>
                </ol>
            </li>
            <li>W przypadku odstąpienia przez Konsumenta od Umowy dot. Zamówienia, Restauracja ma obowiązek niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od umowy, zwrócić Konsumentowi wszystkie dokonane przez niego płatności, w tym koszty Dostawy (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Klienta sposobu dostawy innego niż najtańszy zwykły sposób dostawy dostępny w Platformie). Jeżeli Konsument wybrał sposób dostawy Produktu inny niż najtańszy zwykły sposób dostawy dostępny w Platformie, Usługodawca nie jest zobowiązany do zwrotu Konsumentowi poniesionych przez niego dodatkowych kosztów w wysokości przekraczającej koszt najtańszego zwykłego sposobu dostawy.</li>
            <li>Zwrot płatności następuje przy użyciu takiego samego sposobu płatności, jakiego użył Konsument, chyba że Konsument wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami. Jeżeli Restauracja nie zaproponowała, że sama odbierze Produkt od Konsumenta, może wstrzymać się ze zwrotem płatności otrzymanych od Konsumenta do chwili otrzymania Produktu z powrotem lub dostarczenia przez Konsumenta dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.</li>
            <li>Konsument ma obowiązek niezwłocznie, nie później niż w terminie 14 dni od dnia, w którym odstąpił od Umowy dot. Zamówienia, zwrócić Produkt Restauracji lub przekazać go osobie upoważnionej przez Restaurację do odbioru, chyba że Usługodawca zaproponował, że sam odbierze Produkt. Konsument pokrywa wszystkie bezpośrednie koszty zwrotu Produktu tytułem odstąpienia od umowy tj. koszty opakowania, zabezpieczenia i nadania Produktu, chyba że Restauracja zaproponowała, że sam je poniesie (np. w ramach czasowej promocji obowiązującej w Platformie). Do zachowania terminu wystarczy odesłanie Produktu przed jego upływem. Konsument zwraca Produkt na adres Restauracji wskazany w Platformie.</li>
            <li><strong>[WYJĄTKI OD PRAWA DO ODSTĄPIENIA OD UMOWY]</strong> Prawo odstąpienia od umowy zawartej na odległość nie przysługuje Konsumentowi w odniesieniu do umów: (i) o świadczenie usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną zgodą Konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od umowy; (ii) w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji Konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb; (iii) w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia; (iv) w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu; (v) w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami; (vi) o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą Konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia od umowy.</li>
            <li>Restauracja jest uprawniona do odstąpienia od realizacji Zamówienia (Umowy dot. Zamówienia) niezwłocznie po przyjęciu Zamówienia do realizacji (nie później niż w ciągu godziny od przyjęcia Zamówienia do realizacji) w następujących przypadkach: (i) Klient podał nieprawidłowe dane w ramach Zamówienia, (ii) wystąpienie siły wyższej (zdarzenie nadzwyczajne, zewnętrzne, pozostają poza jakąkolwiek kontrolą strony, które pozostawało niemożliwe do przewidzenia i zapobieżenia). W takim przypadku Klient niezwłocznie, nie później niż w ciągu 5 dni, otrzyma zwrot płatności uiszczonych w związku Zamówieniem przy użyciu takiego samego sposobu płatności, jakiego użył Klient. Klient jest informowany o odstąpieniu od Umowy dot. Zamówienia przez Restaurację w ramach Platformy lub za pośrednictwem wiadomości e-mail.</li>
        </ol>
        <h2>10. PRAWA WŁASNOŚCI INTELEKTUALNEJ</h2>
        <ol>
            <li>Wszelkie i wyłącznie prawa do Treści udostępnianych/umieszczanych w ramach Platformy przez Usługodawcę lub jego kontrahentów, w tym Restauracje, w szczególności elementy graficzne, logotypy, nazwy, oprogramowanie (w tym także Aplikacja Mobilna) oraz prawa w zakresie baz danych podlegają ochronie prawnej i przysługują Usługodawcy lub podmiotom, z którymi Usługodawca zawarł odpowiednie umowy. Klient jest uprawniony do korzystania z Treści bez dodatkowych opłat, a także do korzystania z Treści zamieszczanych zgodnie z powszechnie obowiązującymi przepisami prawa i już rozpowszechnionych przez innych Klientów w ramach Platformy, jednak jedynie w zakresie niezbędnym do prawidłowego korzystania ze Platformy, bez ograniczeń terytorialnych. Wykorzystywanie Treści w innym zakresie jest dopuszczalne jedynie na podstawie wyraźnej, uprzedniej zgody, udzielonej przez uprawniony do tego podmiot, w formie pisemnej - pod rygorem nieważności. Klient nie jest uprawniony w szczególności do tłumaczenia, przystosowywania, zmiany układu lub dokonywania jakichkolwiek innych zmian w Platformie, z wyjątkiem sytuacji dozwolonych powszechnie obowiązującymi przepisami prawa.</li>
            <li>Klient, umieszczając w ramach Platformy jakiekolwiek treści udziela Usługodawcy niewyłącznej, nieodpłatnej licencji na wykorzystywanie, utrwalanie, zmienianie, usuwanie, uzupełnianie, wykonywanie publiczne, wyświetlanie publiczne, zwielokrotnianie i rozpowszechnianie tych treści, bez ograniczeń terytorialnych. Prawo to obejmuje uprawnienie do udzielania sublicencji, a także upoważnienie do wykonywania samodzielnie lub za pomocą podmiotów trzecich praw zależnych w zakresie opracowania, dostosowywania, przerabiania i tłumaczenia utworu w rozumieniu Prawa Autorskiego. W zakresie, w którym Klient nie jest uprawniony do udzielania licencji, o których mowa w niniejszym ust. 2, Klient zobowiązuje się uzyskać dla Usługodawcy takie odpowiednie licencje.</li>
            <li>Z chwilą zainstalowania Aplikacji Mobilnej na urządzeniu przenośnym Klienta, Usługodawca udziela Klientowi niewyłącznej licencji na korzystanie z Aplikacji Mobilnej, obejmującej zwielokrotnianie Aplikacji Mobilnej wyłącznie w związku z jej pobraniem na urządzenie przenośne oraz instalację i uruchamianie Aplikacji Mobilnej na urządzeniu przenośnym Klienta. Klient może zainstalować Aplikację Mobilną na dowolnej liczbie urządzeń przenośnych Klienta, przy czym możliwe jest zainstalowanie tylko jednej kopii Aplikacji Mobilnej na jednym urządzeniu przenośnym.</li>
        </ol>
        <h2>11. OCHRONA DANYCH OSOBOWYCH</h2>
        <ol>
            <li>Usługodawca oraz poszczególne Restauracje są co do zasady niezależnymi administratorami danych osobowych. Podanie danych osobowych przez Klienta jest dobrowolne, ale niezbędne w celu założenia Konta, korzystania z określonych Usług Elektronicznych, zawarcia i realizacji Umowy dot. Zamówienia.</li>
            <li>W przypadku, w którym Usługodawca dysponuje danymi osobowymi Klienta niezbędnymi do złożenia Zamówienia i realizacji Umowy dot. Zamówienia zawartej z Restauracją, Usługodawca może udostępnić je Restauracji.</li>
            <li>W przypadku, w którym Usługodawca nie dysponuje danymi osobowymi Klienta niezbędnymi do złożenia Zamówienia i realizacji Umowy dot. Zamówienia zawartej z Restauracja, Usługodawca umożliwi Klientowi podanie danych osobowych niezbędnych do realizacji Umowy dot. Zamówienia zawartej z Restauracją oraz udostępni je Restauracji po podaniu ich przez Klienta.</li>
            <li>Szczegółowe informacje na temat przetwarzania danych osobowych przez Usługodawcę są zamieszczone w Polityce Prywatności, dostępnej pod następującym adresem: https://apetigo.com/pl/privacy-policy. Informacje na temat przetwarzania danych osobowych przez Restauracje są udostępniane przez Restauracje (np. w ramach strony internetowej Restauracji lub w inny sposób przewidziany przez Restaurację). Usługodawca nie ponosi odpowiedzialności za prawidłowość przetwarzania danych osobowych przez Restauracje jako niezależnych administratorów danych osobowych, w tym za realizację przez Restauracje obowiązków z tym związanych.</li>
            <li>W przypadku, gdyby między Usługodawcą a daną Restauracją dochodziło do powierzenia przetwarzania danych osobowych Klientów, odbywa się to zgodnie z przepisami regulującymi ochronę danych osobowych oraz stosowną umową powierzenia przetwarzania danych osobowych.</li>
        </ol>
        <h2>12. POSTANOWIENIA DOTYCZĄCE PRZEDSIĘBIORCÓW</h2>
        <ol>
            <li>Postanowienia zawarte w niniejszym punkcie (pkt 12 Regulaminu) odnoszą się jedynie do Przedsiębiorców, tj. Klientów niebędących Konsumentami, oraz mają pierwszeństwo względem pozostałych postanowień Regulaminu w przypadku Przedsiębiorców.</li>
            <li>W przypadku Przedsiębiorców mogą zostać ograniczone sposoby płatności, w tym również może być wymagana przedpłata Ceny w całości albo części. Uprawnienie to jest niezależne od wybranego przez Przedsiębiorcę sposobu płatności.</li>
            <li>Z chwilą wydania Produktu osobie realizującej Dostawę na Przedsiębiorcę przechodzą korzyści i ciężary związane z Produktem oraz niebezpieczeństwo przypadkowej utraty lub uszkodzenia Produktu. Restauracja nie ponosi odpowiedzialności za utratę, ubytek lub uszkodzenie Produktu powstałe od przyjęcia go do Dostawy aż do wydania go Przedsiębiorcy oraz za opóźnienie w Dostawie Produktu.</li>
            <li>Zgodnie z art. 558 § 1 Kodeksu Cywilnego odpowiedzialność Restauracji z tytułu rękojmi za Produkty wobec Przedsiębiorcy zostaje wyłączona.</li>
            <li>Usługodawca może wypowiedzieć Umowę o Świadczenie Usług zawartą z Przedsiębiorcą ze skutkiem natychmiastowym i bez wskazywania przyczyn poprzez przesłanie Przedsiębiorcy stosownego oświadczenia w dowolnej formie.</li>
            <li>Usługodawca, a także jego pracownicy, upoważnieni przedstawiciele i pełnomocnicy nie ponoszą odpowiedzialności wobec Przedsiębiorcy, jego podwykonawców, pracowników, upoważnionych przedstawicieli lub pełnomocników za wszelkie szkody, w tym utratę zysków, w tym z tytułu czynu niedozwolonego (chyba że bezwzględnie obowiązującego przepisy prawa stanowią inaczej), chyba że szkoda została spowodowana przez nich umyślnie. Niniejsze postanowienie dotyczy odpowiednio odpowiedzialności Restauracji.</li>
            <li>W przypadku ustalenia odpowiedzialności Usługodawcy, jego pracowników, upoważnionych przedstawicieli lub pełnomocników, odpowiedzialność ta w stosunku Przedsiębiorcy, bez względu na jej podstawę prawną, jest ograniczona do kwoty 500 złotych - zarówno w ramach pojedynczego roszczenia, jak również za wszelkie roszczenia w sumie. Niniejsze postanowienie dotyczy odpowiednio odpowiedzialności Restauracji.</li>
            <li>Okoliczność, że działanie lub zaniechanie, z którego szkoda wynikła, stanowiło niewykonanie lub nienależyte wykonanie zobowiązania, wyłącza roszczenia Przedsiębiorcy o naprawienie szkody z tytułu czynu niedozwolonego.</li>
            <li>Wszelkie spory powstałe pomiędzy Usługodawcą a Przedsiębiorcą zostają poddane sądowi właściwemu ze względu na siedzibę Usługodawcy, natomiast wszelkie spory powstałe pomiędzy Restauracją a Przedsiębiorcą zostają poddane sądowi właściwemu ze względu na siedzibę Restauracji.</li>
            <li>Usługodawca, na podstawie powszechnie obowiązujących przepisów prawa, może dokonać w każdym czasie zmiany postanowień Regulaminu, odnoszących się do Przedsiębiorców.</li>
            <li>Postanowienia zawarte w niniejszym punkcie (pkt 12 Regulaminu), z wyjątkiem ust. 4 powyżej, nie znajdują zastosowania do Przedsiębiorców będących osobami fizycznymi prowadzącymi działalności gospodarczą, które zawierają Umowę dot. Zamówienia, Umowę o Świadczenie Usług lub jakąkolwiek umowę licencji zawartą na podstawie Regulaminu bezpośrednio związaną z ich działalnością gospodarczą, gdy z treści określonej umowy wynika, że nie posiada ona dla nich charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nich działalności gospodarczej, określonego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej. Przedsiębiorcom, o których mowa w zdaniu poprzedzającym, przysługuje również uprawnienie do odstąpienia od umowy na zasadach przewidzianych w pkt 9 Regulaminu.</li>
        </ol>
        <h2>13. ROZWIĄZANIE UMOWY O ŚWIADCZENIE USŁUG ORAZ ZMIANA REGULAMINU</h2>
        <ol>
            <li>
                Usługodawca może dokonać zmiany Regulaminu (z wyjątkiem pkt 12 Regulaminu, który dotyczy Przedsiębiorców i może ulec zmianie w każdym czasie) w przypadku wystąpienia przynajmniej jednego z poniżej wskazanych ważnych powodów (katalog zamknięty):
                <ol type={"a"}>
                    <li>zmiana przepisów prawa regulujących świadczenie usług drogą elektroniczną przez Usługodawcę wpływająca na wzajemne prawa i obowiązki wynikające z umowy określonej Regulaminem, zmiana interpretacji powyższych przepisów prawa wskutek orzeczeń sądów, decyzji, rekomendacji lub zaleceń właściwych w danym zakresie urzędów lub organów;</li>
                    <li>zmiana sposobu świadczenia usług spowodowana względami technicznymi lub technologicznymi (w szczególności aktualizacji Wymagań Technicznych wskazanych w Regulaminie);</li>
                    <li>zmiana zakresu lub świadczenia usług, do których stosuje się postanowienia Regulaminu, poprzez wprowadzenie nowych, modyfikację lub wycofanie przez Usługodawcę dotychczasowych funkcjonalności lub usług objętych Regulaminem;</li>
                    <li>zmiana zakresu lub świadczenia usług przez podmioty współpracujące z Usługodawcą poprzez wprowadzenie nowych, modyfikację lub wycofanie przez te podmioty dotychczasowych funkcjonalności lub usług, wpływające na wzajemne prawa i obowiązki pomiędzy Klientem a Usługodawcą.</li>
                </ol>
            </li>
            <li>W przypadku dokonania zmian w Regulaminie, Usługodawca udostępni tekst jednolity Regulaminu poprzez publikację na stronie Platformy oraz za pomocą wiadomości, przesłanej na podany przy zawieraniu Umowy o Świadczenie Usług przez Klienta adres e-mail, co strony takiej umowy uznają za wprowadzenie informacji o zmianie do środka komunikacji elektronicznej w taki sposób, żeby Klient mógł zapoznać się z jej treścią.</li>
            <li>Zmiana Regulaminu wchodzi w życie z upływem 15 dni od dnia przesłania informacji o zmianie. W przypadku Klientów, którzy zawarli Umowę o Świadczenie Usług, tj. posiadających Konto, mają oni prawo do wypowiedzenia Umowy o Świadczenie Usług w terminie 15 dni od dnia powiadomienia ich o zmianie Regulaminu. Zmiana Regulaminu pozostaje bez znaczenia dla Umów dot. Zamówienia zawartych przed zmianą Regulaminu.</li>
            <li>Klient może w każdym czasie wypowiedzieć Umowę o Świadczenie Usług. Oświadczenie o wypowiedzeniu Umowy o Świadczenie Usług składane przez Klienta może zostać złożone w szczególności przez przesłanie jego treści na adres e-mail np. pisemnie na adres „Apetigo.com sp. z o.o., ul. Wrocławska 17B, Zielona Góra) lub na adres e-mail: info@apetigo.com.</li>
            <li>Usługodawca może wypowiedzieć Umowę o Świadczenie Usług w każdym czasie z zachowaniem 14-dniowego okresu wypowiedzenia w przypadku wystąpienia przynajmniej jednej z przyczyn określonych w ust. 1 powyżej. Ponadto, Usługodawca może wypowiedzieć Umowę o Świadczenie Usług w przypadku naruszenia przez Klienta postanowień Regulaminu, w pkt 3 ust. 9 Regulaminu. Oświadczenie o wypowiedzeniu Umowy o Świadczeniu Usług składane przez Usługodawcę zostanie przesłane na podany przez Klienta adres e-mail.</li>
        </ol>
        <h2>14. POSTANOWIENIA KOŃCOWE</h2>
        <ol>
            <li>Regulamin wchodzi w życie w dniu 14 listopada 2022 roku.</li>
            <li>W sprawach nieokreślonych w Regulaminie zastosowanie mają powszechnie obowiązujące przepisy prawa Rzeczypospolitej Polskiej. Wybór prawa Rzeczypospolitej Polskiej na podstawie Regulaminu nie pozbawia Konsumenta ochrony przyznanej mu na podstawie przepisów, których nie można wyłączyć w drodze umowy, na mocy prawa które zgodnie z właściwymi regulacjami byłoby właściwe w przypadku braku jego wyboru.</li>
            <li>Treść Regulaminu jest udostępniana Klientowi bez dodatkowych opłat w ramach Platformy.</li>
        </ol>
        <hr />
        <h2>ZAŁĄCZNIK NR 1 - WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY</h2>
        <p><i>WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY</i></p>
        <p><i>(formularz ten należy wypełnić i odesłać tylko w przypadku chęci odstąpienia od umowy)</i></p>
        <p><i><strong>– Adresat (Umowa o Świadczenie Usług):</strong></i></p>
        <p><i>
            APETIGO.COM sp. z o.o.<br />
            ul. Wrocławska 17B, 65-427 Zielona Góra<br />
            e-mail: info@apetigo.com
        </i></p>
        <p><i>bądź</i></p>
        <p><i><strong>– Adresat (Umowa dot. Zamówienia):</strong></i></p>
        <p><i>[w tym miejscu należy wpisać dane Restauracji, w tym: pełny adres pocztowy oraz, o ile są dostępne, numer faksu i adres e-mail]</i></p>
        <p><i>– Ja/My(*) niniejszym informuję/informujemy(*) o moim/naszym odstąpieniu od umowy sprzedaży następujących rzeczy(*), umowy dostawy następujących rzeczy(*) umowy o dzieło polegającej na wykonaniu następujących rzeczy(*)/o świadczenie następującej usługi(*)</i></p>
        <p><i>– Data zawarcia umowy(*)/odbioru(*)</i></p>
        <p><i>– Imię i nazwisko konsumenta(-ów)</i></p>
        <p><i>– Adres konsumenta(-ów)</i></p>
        <p><i>– Podpis konsumenta(-ów) (tylko jeżeli formularz jest przesyłany w wersji papierowej)</i></p>
        <p><i>– Data</i></p>
        <p><i>(*) Niepotrzebne skreślić.</i></p>
    </article>
  );
}

export default Regulations;

