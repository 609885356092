import React from "react";
import "./App.scss";
import "./list.scss";

declare type AppColorMode = "light" | "dark" | "white" | null | undefined;

function Regulations() {
  const queryParams = new URLSearchParams(window.location.search);
  const theme = queryParams.get("theme") as AppColorMode;

 let className = 'light-theme';
 if(theme === "dark"){
    className = "dark-theme";
 } else if(theme === "white"){
    className = "white-theme"
 }

  return (
    <article className={`${className} numeric`}>
        <h1>REGULAMIN ŚWIADCZENIA USŁUG DLA RESTAURACJI – PLATFORMA APETIGO.COM</h1>
        <ol>
            <li>DEFINICJE
                <ol>
                    <strong>Cena</strong> - określona w złotych polskich lub w innej walucie kwota brutto (uwzględniająca podatek), którą Klient zobowiązany jest zapłacić w związku z Zamówieniem;<br/>
                    <strong>Dostawa</strong> – odpłatna Usługa świadczona przez Usługodawcę na rzecz Restauracji polegająca na odbiorze Produktów z Lokalu, transport oraz doręczenie Produktów Klientowi na wskazany przez niego w ramach Zamówienia adres dostawy;<br/>
                    <strong>Hasło</strong> – ciąg liter, cyfr i znaków specjalnych umożliwiający zalogowanie na Koncie z użyciem Loginu przypisanego Restauracji. Szczegółowe wymogi związane z ilością poszczególnych znaków i innymi kryteriami związanymi z bezpieczeństwem zostaną udostępnione przy zakładaniu Konta;<br/>
                    <strong>Informacje Poufne</strong> – wszelkie informacje i dane niezależnie od ich formy, źródła, sposobu pozyskania czy też utrwalenia, w tym zwłaszcza specyfikacje, plany, opisy, dane techniczne, dane finansowe, dotyczące Umowy oraz celów i zasad współpracy Stron, w szczególności wszelkie informacje techniczne, technologiczne, ekonomiczne, finansowe (w tym przyjęte stawki wynagrodzenia oraz opłat), prawne, organizacyjne oraz inne otrzymane, powierzone lub pozyskane do przetwarzania w związku z zawarciem lub realizacją Umowy;<br/>
                    <strong>Klient</strong> – podmiot składający Zamówienie za pośrednictwem Platformy, w tym podmiot, zawierający umowę dot. zakupu Produktów, a w przypadku Zamówienia z Dostawą także umowę dot. dostarczenia Produktów; umowa zawierana jest między Klientem a Restauracją;<br/>
                    <strong>Konto</strong> – usługa elektroniczna Usługodawcy pozwalająca na korzystanie przez Restaurację z Platformy, w tym Usług. Restauracja uzyskuje dostęp do Konta za pomocą Loginu i Hasła;<br/>
                    <strong>Kurier</strong> – podmiot realizujący faktycznie Dostawę;<br/>
                    <strong>Login</strong> – adres poczty elektronicznej umożliwiający identyfikację Restauracji jako użytkownika Platformy, służy do autoryzacji dostępu do Platformy i zalogowania;<br/>
                    <strong>Lokal</strong> – miejsce, w którym Restauracja prowadzi działalność gospodarczą – miejsce odbioru Produktów przez Klientów lub Kurierów;<br/>
                    <strong>Lokalizacja</strong> – obszar geograficzny lub administracyjny na terenie Rzeczypospolitej Polskiej, w ramach którego możliwe jest korzystanie z Platformy, w tym Usług (wskazane na stronie internetowej apetigo.com);<br/>
                    <strong>Menu / Oferta</strong> – lista Produktów oferowanych na sprzedaż w ramach Platformy przez Restaurację wraz z ich opisem, zdjęciem oraz ceną, dodana w ramach Konta i dostępna dla Klienta w ramach Platformy, przy czym nie stanowi ona oferty w rozumieniu ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (t.j. Dz. U. 1964 Nr 16 poz. 93 ze zm.);<br/>
                    <strong>Napój Alkoholowy</strong> – towar przeznaczony do spożycia zawierający alkohol etylowy pochodzenia rolniczego w stężeniu przekraczającym 0,5% objętościowych alkoholu;<br/>
                    <strong>Okres Rozliczeniowy</strong> – okres następujących bezpośrednio po sobie dni kalendarzowych liczonych od pierwszego do ostatniego dnia miesiąca kalendarzowego;<br/>
                    <strong>Platforma</strong> – oprogramowanie Usługodawcy (platforma internetowa) umożliwiające Usługodawcy świadczenie Usług, dostępne za pośrednictwem adresu URL https://apetigo.com;<br/>
                    <strong>Produkt</strong> – towar znajdujący się w Ofercie, stanowiący wyłącznie posiłki (w tym przetworzone termicznie), napoje, Napoje Alkoholowe i inne produkty spożywcze;<br/>
                    <strong>Regulamin</strong> – niniejszy Regulamin świadczenia usług dla Restauracji, który stanowi integralną część Umowy; niniejszy dokument jest regulaminem, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (tj. Dz.U.2020.344, ze zm.);<br/>
                    <strong>Reklamacja Klienta</strong> – wszelkie zastrzeżenie Klienta związane z Zamówieniem;<br/>
                    <strong>Restauracja</strong> – przedsiębiorca w rozumieniu Kodeksu cywilnego, który zawarł Umowę z Usługodawcą w ramach prowadzonej działalności gospodarczej;<br/>
                    <strong>RODO</strong> – rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE;<br/>
                    <strong>Siła Wyższa</strong> – zdarzenie nadzwyczajne, zewnętrzne, pozostające poza jakąkolwiek kontrolą Strony, które pozostawało niemożliwe do przewidzenia jak i zapobieżenia. Nie obejmuje zdarzeń, które wynikają z braku dołożenia przez Stronę wymaganej staranności, bądź niewykonania lub nienależytego wykonania Umowy;<br/>
                    <strong>Treści</strong> – wszelkie materiały Restauracji udostępnione w związku z Umową, np. w ramach Konta założonego w ramach Platformy, a dotyczące w szczególności Restauracji, Menu, Produktów, w tym zwłaszcza zdjęcia, opisy, logotypy, znaki towarowe, grafiki, animacje, filmy, oznaczenia;<br/>
                    <strong>Umowa</strong> – oznacza Umowę o świadczenie usług dla Restauracji zawartą między Restauracją a Usługodawcą, w tym jej załączniki;<br/>
                    <strong>Umowa dot. Zamówienia</strong> – umowa sprzedaży w rozumieniu przepisów ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (t.j. Dz. U. 1964 Nr 16 poz. 93 ze zm.) zawierana pomiędzy Restauracją a Klientem za pośrednictwem Platformy, której przedmiotem jest sprzedaż Produktu przez Restaurację na rzecz Klienta, a w przypadku Zamówienia z Dostawą obejmuje ona także usługę dostarczenia Produktów Klientowi;<br/>
                    <strong>Usługa</strong> – odpłatna usługa świadczona na rzecz Restauracji przez Usługodawcę, w szczególności za pośrednictwem Platformy na zasadach przewidzianych w Regulaminie;<br/>
                    <strong>Usługodawca</strong> – APETIGO.COM spółka z ograniczoną odpowiedzialnością z siedzibą w Zielonej Górze, adres: ul. Wrocławska nr 17B, 65-427 Zielona Góra, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Zielonej Górze; VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000925315, NIP: 9731080892; kapitał zakładowy 100.000,00 złotych;<br/>
                    <strong>Wymagania Techniczne</strong> – minimalne wymagania techniczne, których spełnienie jest niezbędne do współpracy z systemem teleinformatycznym, którym posługuje się Usługodawca, w tym zawarcia Umowy oraz korzystania z Platformy, w tym Usług, tj.: (1) komputer, laptop lub inne urządzenie multimedialne z dostępem do Internetu, drukarka (2) dostęp do poczty elektronicznej; (3) zalecana przeglądarka internetowa: Mozilla FireFox w wersji 17.0 i wyższej, Internet Explorer w wersji 10.0 i wyższej, Microsoft Edge w wersji 88.0 i wyższej, Opera w wersji 12.0 i wyższej, Google Chrome w wersji 23.0. i wyższej, Safari w wersji 5.0 i wyższej; (4) zalecana minimalna rozdzielczość ekranu: 1024x768; (5) włączenie w przeglądarce internetowej możliwości zapisu plików cookie oraz obsługi JavaScript;<br/>
                    <strong>Wynagrodzenie</strong> – wynagrodzenie należne Usługodawcy od Restauracji na zasadach przewidzianych w Umowie (w tym w Regulaminie);<br/>
                    <strong>Zamówienie</strong> – oświadczenie woli Klienta wyrażające wolę zawarcia Umowy dot. Zamówienia, określające Produkty, co do których Klient składa ofertę zawarcia umowy, dane Klienta konieczne dla ewentualnego zawarcia i wykonania umowy, oraz formę odbioru / dostawy. Zamówienie każdego Produktu jest traktowane jako niezależna oferta Klienta zawarcia umowy (ułatwienie techniczne). Akceptacja Zamówienia oznacza zawarcie Umowy dot. Zamówienia;<br/> 
                </ol>
            </li>
            <li>POSTANOWIENIA OGÓLNE
                <ol>
                    <li>Regulamin określa zasady korzystania przez Restaurację z Platformy, w tym Usług. Akceptacja Regulaminu przez Restaurację jest dobrowolna, ale konieczna do zawarcia Umowy.</li>
                    <li>Warunkiem korzystania z Platformy, w tym Usług, jest założenie Konta oraz spełnienie Warunków technicznych oraz przestrzeganie postanowień Umowy, w tym Regulaminu.</li>
                    <li>Strony zobowiązane są podejmować działania mające na celu minimalizację ryzyk związanych z usługami świadczonymi drogą elektroniczną, w szczególności Restauracja jest zobowiązana do zachowania Hasła i Loginu w ścisłej poufności i nieudostępniania ich osobom nieupoważnionym. Ponadto, Restauracja ponosi względem Usługodawcy i osób trzecich odpowiedzialność za działania i zaniechania osób działających w jej imieniu jak za własne działania i zaniechania.</li>
                    <li>Platforma, w tym Usługi, jest dostępna na terytorium Rzeczypospolitej Polskiej w określonych Lokalizacjach. Usługodawca zastrzega, iż Lokalizacje będą podlegać aktualizacji, tj. możliwe jest dodanie nowych obszarów, w ramach których Platforma, w tym Usługi, będzie dostępna.</li>
                    <li>Usługodawca może powierzyć wykonywanie swoich poszczególnych obowiązków przewidzianych w Regulaminie podwykonawcom. Usługodawca podejmuje działania, aby nie wpływało to na funkcjonowanie Platformy, w tym Usług.</li>
                    <li>Restauracja zobowiązana jest w szczególności do:
                        <ol>
                            <li>podawania w ramach korzystania z Platformy, w tym Usług, wyłącznie prawdziwych, aktualnych i wszystkich koniecznych danych;</li>
                            <li>korzystania z Platformy, w tym Usług, w sposób niezakłócający funkcjonowania Platformy, Usługodawcy lub innych Restauracji korzystających z Platformy, w tym Usług, a także w sposób zgodny z obowiązującymi przepisami prawa, postanowieniami Umowy oraz Regulaminu, a także z przyjętymi w danym zakresie zwyczajami i zasadami współżycia społecznego;</li>
                            <li>podawania w ramach korzystania z Platformy, w tym Usług, wyłącznie takich danych osobowych, do których posiada aktualną podstawę przetwarzania w rozumieniu przepisów RODO;</li>
                            <li>niezwłocznego aktualizowania danych, w tym danych osobowych podanych Usługodawcy w związku z korzystaniem z Platformy, w szczególności w zakresie w jakim jest to konieczne dla świadczenia Usług;</li>
                            <li>niedostarczania i nieprzekazywania w ramach Platformy, w tym Usług, jakichkolwiek treści zabronionych przez obowiązujące przepisy prawa, w szczególności treści naruszających majątkowe prawa autorskie osób trzecich albo ich dobra osobiste;</li>
                            <li>niepodejmowania działań takich jak:
                                <ol>
                                    <li>rozsyłanie lub umieszczanie w Platformie niezamówionej informacji handlowej lub umieszczanie jakichkolwiek treści naruszających obowiązujące przepisy prawa (zakaz umieszczania treści bezprawnych);</li>
                                    <li>podejmowanie czynności informatycznych lub wszelkich innych czynności mających na celu wejście w posiadanie informacji nieprzeznaczonych dla Restauracji, w tym danych innych Restauracji lub ingerencję w zasady lub techniczne aspekty funkcjonowania Platformy, w tym Usług;</li>
                                </ol>
                            </li>
                            <li>w przypadku wystąpienia wobec Usługodawcy organów administracji publicznej bądź wszczęcia jakiejkolwiek kontroli postępowania wobec Usługodawcy - niezwłocznego (w terminie nie dłuższym niż 24 godzin) przekazywania na żądanie Usługodawcy wszelkich informacji zażądanych przez właściwe organy administracji publicznej informacji.</li>
                        </ol>
                    </li>
                    <li> Usługodawca może w szczególności ze względów technicznych lub organizacyjnych czasowo wyłączyć niektóre funkcjonalności Platformy lub wyłączyć świadczenie Usług, w szczególności w celu ich ulepszenia, dodania nowych funkcjonalności, przeprowadzania konserwacji, zapewnienia najwyższej jakości świadczonych Usług, a także ze względów bezpieczeństwa. Usługodawca każdorazowo poinformuje Restaurację o zawieszeniu lub ograniczeniu możliwości korzystania z Platformy lub Usług poprzez wysłanie odpowiedniej wiadomości na adres poczty elektronicznej Restauracji stanowiący Login oraz zamieszczenie stosownego komunikatu w ramach Platformy lub Konta.</li>
                    <li>Strony zgodnie ustalają, iż zawarcie Umowy nie stanowi gwarancji ani zapewnienia ze strony Usługodawcy co do zawarcia przez Restaurację Umów dot. Zamówienia czy też ich określonej ilości w okresie trwania Umowy, zaś jedynie umożliwia sprzedaż Produktów przez Restaurację za pośrednictwem Platformy.</li>
                </ol>
            </li>
            <li>USŁUGI W RAMACH PLATFORMY
                <ol>
                    <li>Usługodawca na zasadach opisanych w Umowie oraz Regulaminie może świadczyć na rzecz Restauracji w szczególności następujące Usługi:
                        <ol>
                            <li>umożliwienie utworzenia Konta oraz korzystanie z jego funkcjonalności;</li>
                            <li>umożliwienie Restauracjom przetwarzania Zamówień składanych przez Klientów, w tym zawierania Umów dot. Zamówienia za pośrednictwem Platformy;</li>
                            <li>usługa Dostawy – w przypadku, gdy Usługodawca wyraził zgodę na świadczenie usługi Dostawy;</li>
                            <li>prowadzenie działań marketingowych zmierzających do promowania Produktów za pośrednictwem Platformy;</li>
                            <li>wsparcie w prowadzeniu komunikacji z Klientami w zakresie dotyczącym Zamówień / zawieranych Umów dot. Zamówienia;</li>
                            <li>inne Usługi uzgodnione między Stronami (np. w ramach Umowy).</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>REJESTRACJA (ZAŁOŻENIE KONTA)
                <ol>
                    <li>Korzystanie przez Restaurację z Platformy, w tym Usług, jest możliwe po dokonaniu przez Restaurację rejestracji w ramach Platformy (założenie Konta). Restauracja może posiadać wyłącznie jedno Konto powiązane z danym Lokalem, chyba że co innego wynika z indywidualnych ustaleń między Stronami.</li>
                    <li>Do rejestracji w ramach Platformy (założenia Konta) konieczne jest:
                        <ol>
                            <li>złożenie za pośrednictwem odpowiednich checkboxów oświadczenia o (i) potwierdzeniu zapoznania się z Regulaminem, który stanowi integralną część Umowy, (ii) zapoznaniu się z informacją o przetwarzaniu danych osobowych (klauzula informacyjna dot. przetwarzania danych osobowych);</li>
                            <li> prawidłowe wypełnienie formularza rejestracyjnego w ramach Platformy poprzez wskazanie wymaganych w formularzu rejestracyjnym danych, w szczególności firma oraz używana w obrocie nazwa prowadzonej działalności, NIP, siedziba oraz adres (ulica, numer lokalu, miejscowość, kod pocztowy, kraj), dane osoby umocowanej do działania w imieniu Restauracji, tj. imię, nazwisko, numer telefonu, adres poczty elektronicznej, wraz ze wskazaniem podstawy umocowania, oznaczenie Lokalu (adres z dokładną instrukcją dotarcia – m.in. numer lokalu, wskazówki nawigacyjne, dot. parkingu), informacje o prowadzonej działalności wyszczególnione w formularzu (np. typ sprzedawanych Produktów, kategoria Restauracji), numer telefonu i adresem e-mail osób kontaktowych w sprawach Reklamacji Klientów;</li>
                            <li>pozytywna weryfikacja Restauracji przeprowadzona przez Usługodawcę, która obejmuje w szczególności poprawności danych wskazanych w pkt 4.2.2 powyżej.</li>
                        </ol>
                    </li>
                    <li>W ramach weryfikacji, o której mowa w pkt 4.2.3 powyżej, Usługodawca ma prawo żądać od Restauracji przedłożenia stosownych dokumentów lub złożenia wyjaśnień w celu potwierdzenia prawidłowości danych podanych w ramach formularza rejestracyjnego.</li>
                    <li>Usługodawca niezwłocznie przesyła potwierdzenie dokonania rejestracji (założenia Konta) na adres poczty elektronicznej Restauracji stanowiący Login.</li>
                    <li>Na zasadach przewidzianych w Umowie oraz Regulaminie, Restauracja w ramach Konta w szczególności:
                        <ol>
                            <li>ma możliwość zmiany Hasła;</li>
                            <li>zarządza danymi podanymi w ramach rejestracji (zakładania Konta) oraz w trakcie korzystania z Platformy, w tym Usług, w szczególności danych widocznych w ramach Platformy (np. dane identyfikacyjne Restauracji);</li>
                            <li>ma możliwość generowania listy Produktów wraz ze zdjęciami Produktów (zdjęcia wykonane w ramach sesji zdjęciowej przewidzianej w Umowie), ceną (Menu), które mogą być przedmiotem Zamówienia oraz Umowy dot. Zamówienia;</li>
                            <li>zarządza Zamówieniami Klientów;</li>
                            <li>ma możliwość wyznaczenia osób upoważnionych do korzystania z Konta oraz określenia zakresu tego upoważnienia.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>RESTAURACJA
                <ol>
                    <li>Restauracja oświadcza i zapewnia, że:
                        <ol>
                            <li>dysponuje odpowiednią wiedzą, umiejętnościami, kwalifikacjami, doświadczeniem zawodowym, narzędziami oraz materiałami, oraz zasobami ludzkimi i produktowymi, które umożliwiają jej należytą realizację Umowy, w tym postanowień Regulaminu, oraz poszczególnych Umów dot. Zamówienia;</li>
                            <li>pozostaje uprawniona do zawarcia Umowy, zaś zawarcie i wykonanie Umowy nie naruszy uprzednio zawartych umów lub zobowiązań przyjętych przez Restaurację, ani praw osób trzecich;</li>
                            <li>będzie wykonywać Umowę (w tym postanowienia Regulaminu), w szczególności obsługiwać Zamówienia oraz realizować Umowy dot. Zamówienia z należytą starannością właściwą dla podmiotów wykonujących działalność gospodarczą ze szczególnym uwzględnieniem specyfiki branży gastronomicznej, z uwzględnieniem obowiązujących przepisów prawa, zachowaniem odpowiednich warunków higienicznych, zgodnie z zaleceniami odpowiednich instytucji w zakresie związanym z sytuacją epidemiologiczną, uwzględnieniem właściwych przepisów bezpieczeństwa i higieny pracy;</li>
                            <li>spełnia wszelkie wymagania o charakterze administracyjnoprawnym, w tym wszelkie pozwolenia, dokonania wszelkich zgłoszeń, uzgodnień, a także posiada wszelkie niezbędne pozwolenia, zgody i koncesje niezbędne do prowadzenia działalności gospodarczej - o ile te, zgodnie z przepisami, są wymagane do należytego wykonania Umowy, w tym przygotowania Produktów, zgodnie z powszechnie obowiązującymi przepisami prawa jak i przyjętymi standardami dla przygotowania Produktów;</li>
                            <li> w przypadku prowadzenia sprzedaży Napojów Alkoholowych, przez cały jej okres będzie spełniała wymogi zawarte w powszechnie obowiązujących przepisach prawa (w szczególności w ustawie z dnia 26 października 1982 r. o wychowaniu w trzeźwości i przeciwdziałaniu alkoholizmowi) oraz posiadała wszelkie niezbędne zezwolenia do prowadzenia tego typu działalności;</li>
                            <li>dysponuje odpowiednią technologią, rozwiązaniami logistycznymi oraz odpowiednim zapleczem technicznym umożliwiającym prawidłowe realizowanie obowiązków przyjętych w ramach Umowy, jak również w ramach poszczególnych Umów dot. Zamówienia (w szczególności sprzętem przeznaczonym dla personelu, zwłaszcza odpowiednimi urządzeniami elektronicznymi ze stałym połączeniem z Internetem oraz oprogramowaniem umożliwiającym przesyłanie danych do Platformy – w tym umożliwiającym aktualizację statusu realizacji Zamówienia).</li>
                        </ol>
                    </li>
                    <li>Restauracja zobowiązuje się, zgodnie z postanowieniami Umowy, w tym Regulaminu, do:
                        <ol>
                            <li>wykonywania Umowy (w tym postanowień Regulaminu) zgodnie z obowiązującymi przepisami prawa, ogólnie przyjętymi zasadami postępowania w odniesieniu do tego typu zobowiązań, a także przy zachowaniu należytej staranności;</li>
                            <li>przestrzegania wszelkich obowiązków wynikających z powszechnie obowiązujących przepisów prawa dotyczących prowadzenia sprzedaży Napojów Alkoholowych (jeżeli taka sprzedaż jest prowadzona), w szczególności wynikających z ustawy z dnia 26 października 1982 r. o wychowaniu w trzeźwości i przeciwdziałaniu alkoholizmowi oraz innych właściwych przepisów prawa;</li>
                            <li>nieumieszczania w ramach Platformy jakichkolwiek treści mogących stanowić reklamę lub promocję Napojów Alkoholowych w rozumieniu ustawy z dnia 26 października 1982 r. o wychowaniu w trzeźwości i przeciwdziałaniu alkoholizmowi, w szczególności zakazane jest umieszczenie w ramach Platformy znaków towarowych Napojów Alkoholowych lub symboli graficznych z nimi związanych, a także nazw i symboli graficznych przedsiębiorców produkujących Napoje Alkoholowe, nieróżniących się od nazw i symboli graficznych Napojów Alkoholowych, służące popularyzowaniu znaków towarowych Napojów Alkoholowych;</li>
                            <li>wdrożenia i aktualizacji wewnętrznych regulaminów i standardów dotyczących przygotowywania produktów spożywczych (w tym produktów poddanych obróbce termicznej), w tym odpowiedniego przeszkolenia własnego personelu w zakresie obsługi Zamówień, prawidłowej realizacji Umów dot. Zamówień oraz postępowania z Produktami;</li>
                            <li>zapewnienia należytej staranności w zakresie przyjmowania oraz obsługi Zamówień, a także podejmowania działań gwarantujących należytą obsługę przyjętych do realizacji Zamówień, a także ich terminową realizację zgodnie z postanowieniami Umowy (w tym postanowieniami Regulaminu);</li>
                            <li>bezpiecznego wydania Produktów Kurierowi lub Klientowi (tj. w szczególności w sposób zapewniający zachowanie najwyższych standardów higieny, nienaruszający integralności Produktów jak i ich opakowań, gwarantujący właściwe przechowanie w ramach pojemników służących do transportu Produktów – w miarę możliwości dedykowanych do utrzymania właściwej temperatury Produktu (spowalniającej proces stygnięcia / ocieplenia posiłku), jego świeżości jak i zdatności do spożycia, uniemożliwiający wymieszanie i/lub pomylenie Produktów);</li>
                            <li>posługiwania się przy obsłudze Zamówień oraz realizacji Umów dot. Zamówienia personelem gwarantującym najwyższą jakość usług, jak i właściwą komunikację z Klientem;</li>
                            <li>przechowywania oraz udostępniania Usługodawcy wszelkich informacji i/lub danych związanych z wykonywaniem Umowy, w tym dotyczących poszczególnych Zamówień oraz Umów dot. Zamówień, w tym w szczególności w związku z Reklamacjami Klienta.</li>
                        </ol>
                    </li>
                    <li>W przypadku gdy w trakcie obowiązywania Umowy, Restaurację będą obowiązywały inne umowy współpracy w ramach prowadzonej przez nią działalności gospodarczej (w tym umowy zawarte bezpośrednio z innymi przedsiębiorstwami oferującymi podobne usługi do Usług świadczonych przez Usługodawcę w ramach Platformy), Restauracja zobowiązuje się, że ani te umowy ani własna bieżąca działalność nie będą realizowane w sposób priorytetowy względem Umowy.</li>
                    <li>Restauracja zobowiązuje się zapewnić, aby Produkty oferowane w ramach Platformy były nie gorszej jakości niż ta oferowana przez Restaurację poza Platformą. Restauracja zobowiązuje się, że ceny, rabaty i oferty specjalne oferowane przez Restaurację w ramach Platformy nie będą gorsze niż te oferowane przez Restaurację we własnych kanałach sprzedaży.</li>
                    <li>Restauracja przyjmuje do wiadomości, że może otrzymywać od Usługodawcy komunikację związaną z Umową, działaniem Platformy, w tym z Usługami świadczonymi za jej pośrednictwem, a także planowanymi działaniami marketingowymi. Komunikacja może być kierowana za pośrednictwem poczty elektronicznej lub telefonicznie.</li>
                </ol>
            </li>
            <li>SPRZEDAŻ PRODUKTÓW
                <ol>
                    <li>Restauracja zawiera Umowy dot. Zamówienia we własnym imieniu i na własny rachunek. Restauracja oświadcza i zapewnia, że sprzedaż prowadzona przez nią w ramach Platformy odbywa się w ramach jej działalności gospodarczej i ma ona dla niej charakter zawodowy. Restauracja przyjmuje do wiadomości, że Umowa dot. Zamówienia zostaje zawarta w momencie otrzymania przez Klienta potwierdzenia, że Zamówienie zostało przyjęte do realizacji.</li>
                    <li>Restauracja może prowadzić w ramach Platformy sprzedaż Napojów Alkoholowych, z zastrzeżeniem warunków określonych w Regulaminie oraz w powszechnie obowiązujących przepisach prawa. Rozpoczęcie prowadzenia sprzedaży Napojów Alkoholowych jest równoznaczne z oświadczeniem Restauracji o spełnianiu wszelkich wymogów dotyczących prowadzenia sprzedaży Napojów Alkoholowych. W przypadku chęci rozpoczęcia prowadzenia sprzedaży Napojów Alkoholowych lub prowadzenia sprzedaży Napojów Alkoholowych, Restauracja zobowiązana jest do przedłożenia Usługodawcy na jego żądanie wszelkich dokumentów świadczących o możliwości prowadzenia sprzedaży Napojów Alkoholowych przez Restaurację, w szczególności odpowiedniego zezwolenia. Usługodawca może uniemożliwić Restauracji prowadzenie sprzedaży Napojów Alkoholowych oraz zamieszczenia przez Restaurację Napojów Alkoholowych w Menu jeżeli nie przedłoży ona dokumentów, o których mowa w zdaniu poprzedzającym, lub w przypadku powzięcia przez Usługodawcę wątpliwości co do przedłożonych dokumentów. Usługodawca poinformuje Restaurację o powziętych wątpliwościach w celu ich wyjaśnienia.</li>
                    <li>W przypadku, gdy Produkt stanowi Napój Alkoholowy, Restauracja jest zobowiązana do każdorazowej weryfikacji spełnienia przez Klienta wymagań zawartych w art. 15 ustawy z dnia 26 października 1982 r. o wychowaniu w trzeźwości i przeciwdziałaniu alkoholizmowi i ponosi za to pełną odpowiedzialność. Usługodawca nie przeprowadza weryfikacji spełnienia wymagań przez Klientów, o których mowa w zdaniu poprzedzającym, oraz nie weryfikuje prawdziwości oświadczeń składanych przez Klientów w ramach Platformy. W przypadku, gdy Napój Alkoholowy wymaga przygotowania (np. przygotowanie drinka, nalanie do szklanki), Restauracja jest zobowiązana do przeprowadzenia weryfikacji spełnienia przez Klienta wymagań, o których mowa w niniejszym punkcie, przed rozpoczęciem przygotowania Napoju Alkoholowego. Wszelkie koszty poniesione w związku z rozpoczęciem przygotowania Napoju Alkoholowego przed przeprowadzeniem weryfikacji ponosi Restauracja.</li>
                    <li>W ramach profilu Restauracji widocznego w ramach Platformy udostępniane są dane pozwalające na identyfikację Restauracji (w tym Lokalu) oraz Menu.</li>
                    <li>Produkt znajdujący się w Menu posiada opis, która zawiera w szczególności:
                        <ol>
                            <li>informacje dotyczące Produktu (specyfikacja Produktu), w szczególności opis, parametry;</li>
                            <li>zdjęcia Produktu (zdjęcia wykonane w ramach sesji zdjęciowej przewidzianej w Umowie)</li>
                            <li>informacje o Cenie Produktu;</li>
                            <li>informacje o alergenach zawartych w Produkcie – Platforma umożliwia filtrowanie Produktów przez Klienta z uwzględnieniem alergenów.</li>
                        </ol>
                    </li>
                    <li>Produkty w Menu:
                        <ol>
                            <li>muszą należeć do kategorii Produktów określonych w ramach Platformy;</li>
                            <li>muszą być świeże, uwzględniające datę przydatności do spożycia, a Restauracja musi być w pełni uprawniona do ich sprzedaży Klientom, a taka sprzedaż nie może naruszać jakichkolwiek praw osób trzecich; muszą być świeżo przygotowane (poddane obróbce termicznej możliwie najbliżej czasu wydania do odbioru przez Kuriera lub Klienta) i zapakowane w dedykowane do transportu opakowania;</li>
                            <li>muszą być dopuszczone do obrotu oraz spełniać wszelkie wymagania zgodnie z powszechnie obowiązującymi przepisami prawa oraz spełniać wszelkie normy jakościowe obowiązujące na całym świecie, ze szczególnym uwzględnieniem terenu Unii Europejskiej.</li>
                        </ol>
                    </li>
                    <li>Produkty podlegające ograniczeniom sprzedaży, w szczególności Napoje Alkoholowe, mogą być prezentowane w Menu i sprzedawane wyłącznie, jeżeli Restauracja spełnia odpowiednie wymagania oraz posiada niezbędne zezwolenia wynikające z przepisów powszechnie obowiązującego prawa.</li>
                    <li>Produkty, które nie spełniają wymogów wskazanych w pkt 6.5. - 6.7. powyżej nie mogą być prezentowane i sprzedawane za pośrednictwem Platformy. Usługodawca ma prawo żądać od Restauracji niezwłocznego przedłożenia stosownych dokumentów lub złożenia wyjaśnień w celu potwierdzenia spełnienia przez Produkt wymogów wskazanych w pkt 6.5. - 6.7. powyżej. Restauracja w przypadku powzięcia informacji o braku spełnienia przez Produkt któregokolwiek z wymogów wskazanych w pkt 6.5. - 6.7. powyżej jest zobowiązana do natychmiastowego usunięcia Produktu z Menu oraz poinformowania o tym Usługodawcy niezwłocznie, nie później niż w ciągu 24 godzin od momentu powzięcia wskazanej informacji, mailowo na adres restauracja@apetigo.com.</li>
                    <li>W przypadku powzięcia przez Usługodawcę uzasadnionego podejrzenia o tym, że Produkt nie spełnia wymogów wskazanych w pkt 6.5. - 6.7. powyżej, Usługodawca wezwie Restaurację do usunięcia naruszenia, a także ma prawo do natychmiastowego usunięcia Menu lub Produktu z Menu niezgodnego z Regulaminem i/lub zawieszenia Konta do momentu usunięcia naruszenia. Usługodawca poinformuje Restaurację o zawieszeniu Konta wraz ze wskazaniem przyczyny zawieszenia.</li>
                    <li>Restauracja w przypadku powzięcia informacji o braku składników niezbędnych do przygotowania Produktu lub braku któregokolwiek z Produktów zawartych w Menu, jest zobowiązana do natychmiastowego oznaczenia danego Produktu jako niedostępnego.</li>
                    <li>Restauracja samodzielnie dodaje Menu. Usługodawca ma prawo do weryfikowania Menu, w szczególności pod kątem zgodności z Umową (w tym postanowieniami Regulaminu) oraz powszechnie obowiązującymi przepisami prawa.</li>
                    <li>Restauracja jest wyłącznie odpowiedzialna (Usługodawca nie ponosi z tego tytułu żadnej odpowiedzialności) i zobowiązana do przekazywania Klientom wszelkich informacji w sposób rzetelny, zgodny z prawdą oraz obowiązującymi przepisami prawa, w szczególności w zakresie cech oraz dostępności Produktu, Ceny, informacji o wszelkich składnikach i alergenach zawartych w Produkcie, minimalnej kwoty Zamówienia.</li>
                    <li>RestauracjazobowiązujesiędonieumieszczaniawramachPlatformyorazwramachobsługiZamówień/realizowania Umów dot. Zamówienia jakichkolwiek treści, które pośrednio lub bezpośrednio zmierzają do zachęcania Klientów do dokonania zakupu Produktów poza Platformą lub mogących stanowić reklamę lub promocję Napojów Alkoholowych w rozumieniu ustawy z dnia 26 października 1982 r. o wychowaniu w trzeźwości i przeciwdziałaniu alkoholizmowi, w szczególności zakazane jest umieszczenie w ramach Platformy znaków towarowych Napojów Alkoholowych lub symboli graficznych z nimi związanych, a także nazw i symboli graficznych przedsiębiorców produkujących Napoje Alkoholowe, nieróżniących się od nazw i symboli graficznych Napojów Alkoholowych, służące popularyzowaniu znaków towarowych Napojów Alkoholowych. Restauracja przyjmuje do wiadomości, że Usługodawca nie jest podmiotem świadczącym usługi będące reklamą Napojów Alkoholowych w rozumieniu ustawy z dnia 26 października 1982 r. o wychowaniu w trzeźwości i przeciwdziałaniu alkoholizmowi.</li>
                    <li>W przypadku, gdy Produkt stanowi towar objęty ograniczeniami związanymi z wiekiem, w szczególności wynikającymi z powszechnie obowiązujących przepisów prawa, Restauracja jest zobowiązana do weryfikacji wieku Klienta i ponosi za to pełną odpowiedzialność. Usługodawca nie przeprowadza weryfikacji wieku Klientów.</li>
                    <li>Restauracja zobowiązuje się do udostępniania Klientom w Lokalu odpowiednich kodów QR określonych przez Usługodawcę. Kody QR mają na celu:
                        <ol>
                            <li>uruchomienie strony mobilnej Platformy z udostępnieniem Menu danej Restauracji, umożliwiające złożenie Zamówienia przez Klienta w formie realizacji Zamówienia do stolika w Lokalu (o której mowa w pkt 8.3.3. poniżej). Wersja mobilna strony Platformy (bez zalogowania przez Klienta) umożliwia Klientowi wyłącznie złożenie Zamówienia w formie realizacji do stolika w Lokalu, zaś płatność za Zamówienie odbywa się bezpośrednio w Lokalu;</li>
                            <li>uruchomienie aplikacji mobilnej Platformy (w przypadku, gdy Klient uprzednio zainstalował aplikację na swoim urządzeniu mobilnym), zalogowanie na konto Klienta i złożenie Zamówienia przez Klienta w formie realizacji Zamówienia do stolika w Lokalu (o której mowa w pkt 8.3.3. poniżej), wezwanie kelnera do stolika, złożenie dodatkowego Zamówienia na kolejne Produkty oraz możliwość zakończenia składania Zamówień i wyboru sposobu rozliczenia płatności za złożone Zamówienia w następującej formie: (i) online za pośrednictwem Platformy (wybór tego sposobu płatności przez Klienta wiąże się z naliczeniem i pobraniem prowizji przez Usługodawcę) lub (ii) bezpośrednio w Lokalu.</li>
                        </ol>
                    
                    </li>
                    <li>WramachPlatformyUsługodawcadopuszczadoOfertyisprzedażyprzezRestauracjekategorieProduktówokreślone w ramach Platformy. Restauracja może zwrócić się do Usługodawcy z prośbą o dodanie kategorii Produktów, przy czym dodanie kategorii zależy wyłącznie od Usługodawcy. W ramach każdej kategorii Usługodawca może tworzyć dodatkowe podkategorie. Dodanie nowej kategorii nie stanowi zmiany Umowy, w tym Regulaminu – nowa kategoria będzie widoczna w ramach dodawania Produktu do Menu. Próba bądź wprowadzenie Produktu spoza kategorii przez Restaurację stanowić będzie rażące naruszenie Umowy, w tym Regulaminu, które uprawnia Usługodawcę do usunięcia Oferty, zablokowania Konta lub rozwiązania umowy w trybie przewidzianym w pkt 15.4 Regulaminu (wedle uznania Usługodawcy).</li>
                </ol>
            </li>
            <li>REALIZACJA ZAMÓWIEŃ ORAZ REKLAMACJE KLIENTÓW
                <ol>
                    <li>Restauracja zarządza złożonymi Zamówieniami za pośrednictwem Konta, w tym w szczególności monitoruje składane Zamówienia oraz zarządza oznaczaniem statusu realizacji Zamówień. W przypadku braku możliwości przyjmowania przez Restaurację Zamówień do realizacji w określonym dniu lub okresie, Restauracja jest zobowiązana, aby z możliwym wyprzedzeniem, za pośrednictwem odpowiednich ustawień w ramach Konta uniemożliwić Klientom składanie Zamówień w określonym dniu lub okresie, (tj. włącznie trybu „offline” Konta Restauracji – ustawienie Restauracji jako niedostępnej do składania Zamówień).</li>
                    <li>Restauracja jest zobowiązana do bieżącego aktualizowania statusu Zamówienia z uwzględnieniem statusów przewidzianych w ramach Platformy. Usługodawca przesyła Klientowi powiadomienie w ramach Platformy o zmianie statusu Zamówienia. Restauracja zobowiązuje się do nieprzesyłania wiadomości mailowych dotyczących Zamówienia we własnym zakresie, chyba że Umowa (w tym postanowienia Regulaminu) przewiduje taką możliwość.</li>
                    <li>Restauracja jest zobowiązana do przygotowania Produktu będącego przedmiotem zaakceptowanego przez Restaurację Zamówienia w terminie przez nią określonym w momencie przyjęcia Zamówienia do realizacji, a następnie wydania Produktów Kurierowi lub bezpośrednio Klientowi w zależności od wybranego przez Klienta sposobu dostawy / odbioru. Restauracja zobowiązana jest do należytego opakowania oraz oznakowania Produktów odpowiadający wybranemu przez Klienta sposobowi dostawy / odbioru.</li>
                    <li>Z zastrzeżeniem postanowień Umowy oraz Regulaminu, po przyjęciu Zamówienia do realizacji, Restauracja nie może odstąpić od jego realizacji. Restauracja zobowiązana jest zapewnić każdorazowo dostępność Produktu i realizację Zamówienia.</li>
                    <li>Restauracja ma możliwość zgłoszenia Usługodawcy chęci odstąpienia od realizacji Zamówienia (anulowanie Zamówienia przyjętego do realizacji) nie później niż 3 minuty od momentu przyjęcia Zamówienia do realizacji wyłącznie w przypadku, gdy Klient podał niepoprawne dane kontaktowe lub adresowe lub w przypadku wystąpienia Siły Wyższej. Ponadto, możliwe jest anulowanie Zamówienia dotyczącego Napojów Alkoholowych w przypadku niespełnienia przez Klienta wymagań, o których mowa w pkt 6.3. Anulowanie Zamówienia przyjętego do realizacji jest możliwe po poinformowaniu Usługodawcy o przyczynach uzasadniających odstąpienie od realizacji Zamówienia (anulowanie Zamówienia), zaś anulowania dokonuje Usługodawca.</li>
                    <li>Restauracja jako strona Umowy dot. Zamówienia jest wyłącznie uprawniona i zobowiązana do rozpatrywania Reklamacji Klientów oraz zgłoszeń Klientów, przy czym w ramach Usług, Restauracja upoważnia Usługodawcę do realizowania obsługi posprzedażnej Klientów w imieniu i na rzecz Restauracji, w tym rozpatrywania Reklamacji Klientów oraz zgłoszeń Klientów. W ramach realizowania obsługi posprzedażowej, Usługodawca zobowiązuje się w szczególności odpowiadać Klientom na pytania związane z procedurą dotyczącą zwrotów i reklamacji Zamówień. Restauracja zobowiązuje się do współdziałania z Usługodawcą umożliwiającego realizację przez Usługodawcę obsługi posprzedażowej Klientów, w szczególności zobowiązuje się do zapewnienia Usługodawcy informacji dotyczących przebiegu realizacji Zamówień, a także udziela Usługodawcy wszelkich upoważnień i pełnomocnictw do działania w jej imieniu i na jej rzecz (w tym składania wobec Klientów wiążących oświadczeń i deklaracji m.in. w zakresie przyznania zwrotu Ceny) w związku z prowadzeniem obsługi posprzedażowej przez Usługodawcę, w tym rozpatrywaniem Reklamacji Klientów oraz ich zgłoszeń. Wszelka rekompensata wypłacona przez Usługodawcę na rzecz Klienta w związku z Reklamacją Klienta zostanie odliczona od płatności należnej Restauracji.</li>
                    <li>Restauracja zobowiązana jest do niezwłocznego informowania Usługodawcy o każdej otrzymanej bezpośrednio Reklamacji Klienta. Restauracja ponosi odpowiedzialność za wszelkie opóźnienia wynikające z nieprzekazywania Usługodawcy właściwych danych i informacji.</li>
                    <li>Restauracja zobowiązana jest zapewnić osobę kontaktową, od której Usługodawca będzie mógł uzyskać informacje dotyczące Zamówienia / Umowy dot. Zamówienia (w tym w szczególności zgłosić Reklamację Klienta, uzyskać informację w sprawie Produktów objętych Zamówieniem / Umową dot. Zamówienia, w tym zwłaszcza w zakresie alergenów, a także uzyskać informację w zakresie statusu realizacji Zamówienia). Restauracja zobowiązuje się zapewnić dostępność osoby kontaktowej (lub osób kontaktowych) pod wskazanym w ramach Konta numerem telefonu i adresem e-mail, codziennie w godzinach przyjmowania Zamówień przez Restaurację, a także godzinę po zakończeniu ww. okresu.</li>
                    <li>Restauracja lub wyznaczona przez nią osoba kontaktowa jest zobowiązana odpowiedzieć na pytania Usługodawcy niezwłocznie, nie później niż w ciągu 12 godzin od ich zadania (w przypadku pytań wysłanych elektronicznie – od ich wysłania).</li>
                    <li>Restauracja zobowiązuje się do prawidłowego informowania Usługodawcy o wszelkich zdarzeniach pozostających w związku Dostawą, w tym w szczególności dotyczących czasu Dostawy (dokładnej godziny dojazdu Kuriera do Lokalu, godziny odbioru Produktów z Lokalu), wszelkich nieprawidłowości w zakresie Produktów lub ich opakowań, potwierdzenia doręczenia Produktów, a także innych istotnych okoliczności dotyczących przebiegu realizacji Dostawy.</li>
                </ol>
            </li>
            <li>METODY PŁATNOŚCI ORAZ ZASADY DOSTAWY
                <ol>
                    <li>Niezależnie od formy odbioru i/lub doręczenia Produktu:
                        <ol>
                            <li>Klient zapłaci Cenę za pośrednictwem operatora płatności współpracującego z Usługodawcą, za pośrednictwem Platformy, tj. PayU S.A., ul. Grunwaldzka 186, 60-166 Poznań;</li>
                            <li>Restauracja nie pobierze od Klienta dodatkowych opłat i/lub należności;</li>
                            <li>Restauracja każdorazowo wystawi i załączy do Produktu paragon fiskalny (lub fakturę VAT, jeżeli dotyczy) potwierdzający uiszczenie Ceny (z wyróżnieniem kosztu dostawy poniesionego przez Klienta); Usługodawca nie ponosi odpowiedzialności za wystawienie i wydanie paragonu fiskalnego (lub faktury VAT, jeżeli dotyczy) przez Restaurację.</li>
                        </ol>
                    </li>
                    <li>Restauracja jest zobowiązana do dokonania czynności koniecznych do rozpoczęcia współpracy z operatorem płatności, o którym mowa w pkt 8.1.1 powyżej, w szczególności w zakresie identyfikacji, o której mowa w ustawie z dnia 1 marca 2018 r. o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu (t.j. Dz.U. z 2020 r. poz. 971, ze zm.). Zasady współpracy z operatorem płatności, o którym mowa w pkt 8.1.1. powyżej, w związku z korzystaniem przez Restaurację z usług operatora płatności są określone w Załączniku nr 1 do Regulaminu – Zasady współpracy z Operatorem Płatności.</li>
                    <li>Klient zawierając Umowę dot. Zamówienia ma możliwość wyboru formy realizacji Zamówienia:
                        <ol>
                            <li>Zamówienie z Dostawą;</li>
                            <li>Zamówienie z odbiorem osobistym;</li>
                            <li>Zamówienie do stolika w Lokalu.</li>
                        </ol>
                    </li>
                    <li>Klient, zawierając Umowę dot. Zamówienia na Napoje Alkoholowe, nie może wybrać formy realizacji Zamówienia wskazanej w pkt 8.3.1. Ponadto, w przypadku wyboru formy realizacji Zamówienia określonej w pkt 8.3.2. oraz 8.3.3., odbiór lub podanie Produktów będących Napojami Alkoholowymi musi nastąpić w miejscu, które stanowi punkt sprzedaży w rozumieniu ustawy z dnia 26 października 1982 r. o wychowaniu w trzeźwości i przeciwdziałaniu alkoholizmowi.</li>
                    <li>W przypadku, gdy Usługodawca wyraził zgodę na świadczenie usługi Dostawy, Usługodawca jest uprawniony do określenia czasu świadczenia usługi Dostawy (czas może się różnić w zależności od Lokalizacji lub położenia Lokalu), określenia maksymalnej możliwej odległości między Lokalem a Klientem, określenia minimalnej wartości Zamówienia, dla którego będzie możliwa usługa Dostawy.</li>
                    <li>W przypadku wyboru przez Klienta Dostawy na wskazany adres:
                        <ol>
                            <li>Kurier odbiorze Produkty z Lokalu w oznaczonym czasie;</li>
                            <li>Kurier dostarczy wraz z Produktami wszelkie dokumenty załączone przez Restaurację (w szczególności paragon fiskalny lub fakturę VAT), przy czym Restauracja zobowiązana jest do właściwego zabezpieczenia ww. dokumentów wraz z Produktem (tj. dokumenty nie mogą być wydane osobno Kurierowi lub dołączone do Produktu w sposób, który wiąże się z ryzykiem ich utraty / zgubienia);</li>
                            <li>Restauracja akceptuje, iż Kurier nie jest zobowiązany do weryfikacji kompletności Produktów przekazanych do odbioru, Produkty powinny być właściwie oznaczone (tj. w przypadku kilku opakowań właściwie ponumerowane lub umieszczone w zbiorczym opakowaniu / torbie oraz opatrzone numerem Zamówienia).</li>
                        </ol>
                    </li>
                    <li>Strony postanawiają, że w przypadku opóźnienia Restauracji względem terminu przez nią określonego w momencie przyjęcia Zamówienia do realizacji Kurier będzie oczekiwać na wydanie Produktów przez oznaczony czas oczekiwania Kuriera, a po bezskutecznym upływie czasu oczekiwania Kuriera, Kurier może opuścić Restaurację. Strony dopuszczają aktualizację oznaczenia czasu realizacji i wydania Produktu, przy czym w takim przypadku, Restauracja zobowiązana jest do pokrycia wszystkich dodatkowych kosztów związanych ze zmianą czasu, które zostały poniesione przez Usługodawcę z przyczyn leżących po stronie Restauracji (np. dodatkowe koszy związane Dostawą).</li>
                    <li>Usługodawca ma prawo do wycofania usługi Dostawy związanej z konkretnym Zamówieniem, o czym niezwłocznie poinformuje Restaurację.</li>
                    <li>Strony postanawiają, że w przypadku braku odbioru Produktów przez Klienta, który wybrał Dostawę, Produkty zostaną zutylizowane lub zostaną zwrócone do Restauracji (na koszt i ryzyko Restauracji).</li>
                    <li>Restauracja udostępni Klientowi do odbioru Produkt w przypadku wyboru przez Klienta odbioru osobistego w Lokalu w czasie określonym przez Restaurację w momencie przyjęcia Zamówienia do realizacji. W przypadku jakichkolwiek problemów związanych z odbiorem osobistym Produktów w Lokalu, Restauracja podejmie dwukrotną próbę kontaktu z Klientem na numer telefonu wskazany przez Klienta w Zamówieniu.</li>
                </ol>
            </li>
            <li>PLASOWANIE
                <ol>
                    <li>Restauracje, w tym ich Oferty, są wyświetlane Klientom w ramach Platformy z uwzględnieniem zasad plasowania, które są szczegółowo opisane w ramach Konta, po zalogowaniu do panelu restauracji. Zasady plasowania w szczególności mogą uwzględniać oceny Klientów dot. Restauracji, a także odległość Lokalu od ustalonej lokalizacji Klienta.</li>
                </ol>
            </li>
            <li>WYNAGRODZENIE
                <ol>
                    <li>Restauracja jest zobowiązana do uiszczania na rzecz Usługodawcy Wynagrodzenia na zasadach opisanych w Umowie oraz Regulaminie.</li>
                    <li>Wynagrodzenie obejmuje (i) prowizję za Zamówienia realizowane przez Restaurację, a także (ii) inne opłaty związane z Usługami świadczonymi przez Usługodawcę przewidziane w Umowie (np. opłata z usługę Dostawy oraz opłata subskrypcyjna). Wysokość prowizji oraz opłat jest określona w Umowie.</li>
                    <li>Wynagrodzenie jest każdorazowo powiększane o należny podatek od towarów i usług w obowiązującej stawce.</li>
                    <li>Wynagrodzenie jest obliczane w oparciu o raport sprzedażowy danej Restauracji, który będzie dostępny w ramach Konta.</li>
                    <li>Usługodawca ma prawo do bieżącego potrącania wszelkich wierzytelności przysługujących Usługodawcy względem Restauracji w szczególności wierzytelności z tytułu Wynagrodzenia, z wierzytelnościami przysługującymi Restauracji względem Usługodawcy z tytułu Umów dot. Zamówienia zawartych za pośrednictwem Platformy, a także wszelkich innych należności.</li>
                    <li>Restauracja udziela Usługodawcy upoważnienia do przekazania operatorowi płatności, o którym mowa w pkt 8.1. Regulaminu, polecenia zapłaty na rzecz Usługodawcy należności przysługujących Usługodawcy z tytułu korzystania z Platformy, w tym Usług, w szczególności Wynagrodzenia.</li>
                    <li>Środki zgromadzone na rachunku dedykowanym Restauracji, po potrąceniu wszelkich wierzytelności przysługujących Usługodawcy będą wypłacane Restauracji na zasadach określonych w Załączniku nr 1 do Regulaminu – Zasady Współpracy z Operatorem Płatności.</li>
                    <li>W terminie do 5 dni od zakończenia każdego Okresu rozliczeniowego, Usługodawca wystawi i przekaże Restauracji fakturę VAT opiewającą na wysokość należnego Usługodawcy Wynagrodzenia za dany Okres rozliczeniowy. Płatność Wynagrodzenia należnego Usługodawcy nastąpi w terminie 15 dni od dnia wystawienia faktury VAT na rachunek bankowy wskazany na fakturze VAT, chyba, że należna kwota została wcześniej potrącona na zasadach przewidzianych w ust. 10.5 oraz 10.6 powyżej. Za datę dokonania płatności uznaje się datę uznania rachunku bankowego Usługodawcy. Elektroniczna faktura VAT będzie dostępna w ramach Konta.</li>
                </ol>
            </li>
            <li>WŁASNOŚĆ INTELEKTUALNA / LICENCJA
                <ol>
                    <li>Restauracja oświadcza, iż jest uprawniona do przekazania Usługodawcy Treści w ramach Umowy, w tym uzyskała wszelkie zgody i oświadczenia umożliwiające zgodne z obowiązującym prawem udostępnienie Treści. Restauracja oświadcza, że Treści udostępnione Usługodawcy w ramach Umowy nie są obciążone jakimikolwiek wadami, w szczególności prawnymi, które mogłyby utrudnić lub uniemożliwić Usługodawcy korzystanie z Treści w sposób opisany Umową, w tym Regulaminem.</li>
                    <li>Z chwilą udostępnienia przez Restaurację Usługodawcy Treści (w tym umieszczenie Treści w ramach Platformy), Restauracja udziela Usługodawcy niewyłącznej licencji na korzystanie z Treści, bez konieczności podejmowania dodatkowych czynności, na wszystkich znanych w dniu zawarcia Umowy polach eksploatacji, w szczególności:
                        <ol>
                            <li>w zakresie utrwalania i zwielokrotnienia Treści – wytwarzanie określoną techniką egzemplarzy Treści, w tym techniką drukarską, reprograficzną, zapisu magnetycznego oraz techniką cyfrową;</li>
                            <li>w zakresie rozpowszechniania Treści w sposób inny niż określony w pkt 11.2.1, udostępnianie Treści w taki sposób, aby każdy mógł mieć do nich dostęp za pośrednictwem sieci Internet w miejscu i w czasie przez siebie wybranym.</li>
                        </ol>
                    </li>
                    <li>Licencja na Treści jest udzielana bez ograniczeń terytorialnych, na okres 5 lat. Po upływie okresu, o którym mowa w zdaniu poprzedzającym, licencja na Treści ulega automatycznemu przedłużeniu na okres kolejnych 5 lat, chyba że którakolwiek ze Stron złoży oświadczenie o braku woli przedłużenia licencji na Treści nie później niż na 14 dni przed upływem okresu 5 lat. Oświadczenia Stron, o których mowa w niniejszym punkcie wymagają formy dokumentowej pod rygorem nieważności. Strony składają swoje oświadczenia na zasadach opisanych w pkt. 15.5. Regulaminu. Restauracja zobowiązuje się do nieskładania oświadczenia o braku woli przedłużenia licencji na Treści oraz do niewypowiadania licencji na Treści w trakcie obowiązywania Umowy. Do przedłużenia okresu obowiązywania licencji na Treści na kolejne pięcioletnie okresy, postanowienia niniejszego punktu stosuje się odpowiednio.</li>
                    <li>Usługodawca ma prawo do udzielenia dalszej licencji na Treści (sublicencja), w szczególności w zakresie koniecznym do realizowania działań marketingowych związanych z Platformą. Restauracja udziela Usługodawcy zezwolenia do wykonywania praw zależnych, a także korzystania i rozporządzania utworami zależnymi, które powstaną z wykorzystaniem Treści.</li>
                    <li>Restauracja zobowiązuje się i gwarantuje, że osoby uprawnione z tytułu autorskich praw osobistych do Treści nie będą wykonywać tych praw w stosunku do Usługodawcy i/lub osób trzecich działających na zlecenie Usługodawcy.</li>
                    <li>Wszelkie prawa własności intelektualnej do Platformy, w tym w zakresie praw autorskich i własności przemysłowej, przysługują wyłącznie Usługodawcy lub innym podmiotom, z którym Usługodawca zawarł stosowne umowy, zaś zawarcie Umowy nie skutkuje przeniesieniem na rzecz Restauracji jakichkolwiek praw własności intelektualnej do Platformy.</li>
                    <li>W przypadku, gdy w trakcie współpracy Stron, Usługodawca dokona modyfikacji i/lub rozwoju Platformy, w tym w zakresie dodatkowych funkcjonalności umożliwiających integrację z zewnętrznymi systemami informatycznymi, całość praw własności intelektualnej do wszelkiego rodzaju prac rozwojowych przysługuje wyłącznie Usługodawcy lub kontrahentom, z którymi zawarto stosowne umowy, zaś Restauracja upoważniona jest do korzystania z tych prac na podstawie licencji (odpowiednie zastosowanie znajduje pkt 11.8 Regulaminu).</li>
                    <li>Z chwilą zawarcia Umowy, Usługodawca udziela Restauracji na okres trwania Umowy niewyłącznej i nieprzenaszalnej licencji (tj. bez prawa do udzielania dalszych licencji) na korzystanie z funkcjonalności Platformy dedykowanych dla Restauracji w zakresie oraz w celu zgodnym z Umową (w tym postanowieniami Regulaminu), bez konieczności podejmowania dodatkowych czynności, na następujących polach eksploatacji: czasowe zwielokrotnianie techniką zapisu magnetycznego oraz techniką cyfrową w takim zakresie, aby Restauracja lub osoby upoważnione przez Restaurację do korzystania z Konta mogły mieć dostęp do Platformy, w tym Usług, w miejscu i w czasie przez siebie wybranym.</li>
                    <li>Wynagrodzenie z tytułu licencji, o których mowa w pkt 11 Regulaminu, jest rozliczone w ramach Wynagrodzenia.</li>
                    <li>Strony zgodnie ustalają, iż licencja udzielana przez Usługodawcę nie obejmuje: (i) kodu źródłowego Platformy, (ii) praw zależnych, ani (iii) prawa do dokonywania jakichkolwiek zmian, modyfikacji lub przeróbek w ramach Platformy, w tym Usług, lub jej funkcjonalności.</li>
                    <li>Restauracja zobowiązuje się do korzystania z Platformy, w tym Usług, wyłącznie w zakresie licencji, tj. zobowiązuje się do niepodejmowania działań mających na celu sprzedaż, najem, dzierżawę lub inną formę eksploatacji rozwiązania informatycznego stanowiącego Platformę (w tym również żadnej z jej części składowych).</li>
                </ol>
            </li>
            <li>DZIAŁANIA MARKETINGOWE
                <ol>
                    <li>Usługodawca może prowadzić działania marketingowe zmierzające do promowania Platformy, Restauracji, jak i Produktów oferowanych przez Restauracje w ramach Platformy, w szczególności Usługodawca może organizować: kampanie reklamowe, konkursy, akcje rabatowe / promocyjne.</li>
                    <li>Restauracja może dobrowolnie przystępować do działań marketingowych organizowanych przez Usługodawcę na zasadach określonych odrębnie przez Usługodawcę. Udział Restauracji w określonym działaniu marketingowym może mieć wpływ na widoczność lub kolejność wyświetlania jej ofert w ramach Platformy.</li>
                    <li>Restauracja przyjmuje do wiadomości, iż w działaniach marketingowych, o których mowa powyżej mogą brać udział wszystkie Restauracje współpracujące z Usługodawcą.</li>
                    <li>Usługodawca nie składa żadnych oświadczeń ani gwarancji związanych z udziałem w działaniach marketingowych, w tym zwłaszcza związanych ze zwiększeniem sprzedaży, czy też zainteresowania ofertą Restauracji.</li>
                    <li>Restauracja pozostaje uprawniona do informowania w ramach swojej strony internetowej lub prowadzonych mediach społecznościowych o współpracy z Usługodawcą, w tym również umieszczać logotyp Usługodawcy oraz zdjęcia wykonane podczas sesji zdjęciowej przewidzianej w Umowie w ramach ww. form działań marketingowych.</li>
                    <li>Szczegółowe zasady korzystania z logotypu, zdjęć wykonanych podczas sesji zdjęciowej przewidzianej w Umowie oraz informowania o współpracy zostaną udostępnione przez Usługodawcę w ramach Platformy, przy czym niezależnie od powyższego, Restauracja zobowiązana jest umieszczać wszelkie informacje dotyczące współpracy w materiałach nienaruszających praw osób trzecich, zasad współżycia społecznego czy też powszechnie obowiązujących przepisów prawa.</li>
                    <li>Usługodawcy przysługuje prawo sprzeciwu wobec określonego sposobu wykorzystania marki i/lub logotypu identyfikującego Platformę i/lub Usługodawcę. Restauracja zobowiązana jest do niezwłocznego usunięcia materiałów lub wprowadzenia w nich odpowiednich zmian (zgodnie z żądaniem Usługodawcy), nie później jednak niż w ciągu 24 godzin od zgłoszenia żądania przez Usługodawcę. Ponowna publikacja materiału jest możliwa po uprzedniej akceptacji Usługodawcy.</li>
                    <li>Jedną z funkcjonalności Platformy pozostaje dodawanie ocen, opinii oraz recenzji Produktów jak i Restauracji. Restauracja niniejszym wyraża zgodę na umieszczanie ocen, opinii i recenzji przez Klientów w dedykowanych miejscach w ramach Platformy. Dodawane opinie, recenzje i oceny mogą wpływać na sumaryczną ocenę Restauracji lub Produktów w ramach Platformy. Klienci będą mieli możliwość filtrowania Produktów lub Restauracji z wyborem kryterium ocen innych Klientów. Usługodawca nie będzie ingerował w treści opinii i recenzji, przy czym zastrzega sobie prawo do usuwania treści niezgodnych z prawem, naruszających prawa osób trzecich, wulgarne lub sprzeczne z dobrymi obyczajami.</li>
                </ol>
            </li>
            <li>ODPOWIEDZIALNOŚĆ
                <ol>
                    <li>Usługodawca nie ponosi odpowiedzialności za działalność Restauracji w ramach prowadzonej przez Restaurację działalności gospodarczej, w tym w związku z korzystaniem z Platformy, w tym Usług. W szczególności Usługodawca nie jest odpowiedzialny za realizację Umów dot. Zamówień, Produkty sprzedawane przez Restaurację z wykorzystaniem Platformy, za przypadki sprzedaży Produktów podlegających ograniczeniom (np. Napojów Alkoholowych) z naruszeniem obowiązków i wymagań wynikających z powszechnie obowiązujących przepisów prawa, za treści umieszczane przez Restaurację w ramach Platformy, oraz prawdziwość informacji przekazywanych przez Restaurację, w szczególności umieszczonych w ramach strony Produktu lub Menu.</li>
                    <li>Restauracja ponosi wyłączną odpowiedzialność za należytą realizację Umowy dot. Zamówienia wobec Klienta. W przypadku niewykonania lub nienależytego wykonania Umowy dot. Zamówienia, Restauracja jest zobowiązana do naprawy wszelkich szkód wyrządzonych Klientowi, w tym zwrotu kwot uiszczonych przez Klienta w związku z Zamówieniem. Niezależenie, Restauracja jest zobowiązana także do naprawienia szkody wyrządzonej Usługodawcy – w tym zwrotu wszelkich opłat prowizyjnych związanych z koniecznością zwrotu środków Klientowi.</li>
                    <li>Strony postanawiają, iż w przypadku, gdy niewykonanie lub nienależyte wykonanie Umowy dot. Zamówienia wynika z wyłącznej winy Usługodawcy (np. uchybienia w zakresie Dostawy), Usługodawca zobowiązany jest do zwrotu kosztów poniesionych przez Restaurację, przy czym odpowiedzialność w tym zakresie jest ograniczona do kwoty na jaką opiewa dana Umowa dot. Zamówienia.</li>
                    <li>W sytuacji, gdy w związku z działalnością Restauracji w ramach Platformy, w tym korzystania Usług, niewykonywaniem lub nienależytym wykonywaniem zobowiązań przewidzianych w Umowie (w tym Regulaminie) przez Restaurację lub nieprawdziwością / nieaktualnością oświadczeń Restauracji wynikających z zawartej Umowy (w tym zwłaszcza w związku z Treściami), Usługodawca poniesie szkodę lub w stosunku do Usługodawcy zostaną zgłoszone roszczenia przez Klienta lub podmioty trzecie, Restauracja jest zobowiązana do naprawienia szkody, a ponadto Restauracja w najszerszym możliwym zakresie zwolni Usługodawcę z ewentualnej odpowiedzialności względem Klienta lub podmiotów trzecich, z zastrzeżeniem postanowień Regulaminu. Obowiązek naprawienia szkody przez Restaurację dotyczy osobno każdej Umowy dot. Zamówienia. Restauracja zobowiązana jest w szczególności każdorazowo do pokrycia kosztów poniesionych przez Usługodawcę lub wynikających z zapłaty odszkodowania przez Usługodawcę na rzecz Klienta, i/lub podmiotu trzeciego (w tym operatora płatności w ramach Platformy).</li>
                    <li>Restauracja w szczególności zobowiązuje się do podjęcia na swój koszt i ryzyko wszelkich kroków prawnych zapewniających należytą ochronę Usługodawcy przed żądaniami określonymi w punkcie 13.4. Regulaminu lub ich skutkami, w tym zobowiązuje się: (i) wstąpić w miejsce Usługodawcy lub, w przypadku braku takiej możliwości, (ii) przystąpić po stronie Usługodawcy do wszelkich postępowań, a także (iii) zobowiązuje się zrekompensować Usługodawcy w całości wszelkie koszty, jakie Usługodawca będzie zobowiązany ponieść w stosunku do Klienta lub podmiotów trzecich, oraz koszty postępowania w pełnej wysokości.</li>
                    <li>Postanowienia ust. 13.4. i 13.5. powyżej obowiązują odpowiednio również po wygaśnięciu Umowy lub rozwiązaniu Umowy przez którąkolwiek ze Stron.</li>
                    <li>Z zastrzeżeniem postanowień Umowy (w tym Regulaminu) Usługodawca nie ponosi odpowiedzialności za jakiekolwiek szkody po stronie Restauracji, w tym utratę zysków, chyba że szkoda została spowodowana przez niego umyślnie. Odpowiedzialność Usługodawcy z tytułu utraconych korzyści i/lub strat pośrednich jest wyłączona, a w szczególności w związku z utratą wartości firmy, niekorzystnymi zmianami w opiniach i/lub reputacji, zmniejszeniem i/lub utratą zysków i/lub przychodów, utratą danych i/lub kontrahentów, jak też jakichkolwiek innych pośrednich strat lub utraconych korzyści. Odpowiedzialność Usługodawcy wobec Restauracji w każdym przypadku ograniczona jest do wysokości wynagrodzenia pobranego przez Usługodawcę w ostatnich dwóch Okresach rozliczeniowych przed dniem wystosowania roszczenia wobec Usługodawcy.</li>
                    <li>Strony nie ponoszą odpowiedzialności, jeśli niewykonanie lub nienależyte wykonanie Umowy spowodowane jest Siłą Wyższą. Każda Strona zobowiązana jest do niezwłocznego poinformowania drugiej Strony o fakcie wystąpienia działania Siły Wyższej, a także do udowodnienia tych okoliczności poprzez przedstawienie dokumentacji potwierdzającej wystąpienie zdarzeń mających charakter Siły Wyższej oraz do wskazania wpływu, jaki takie zdarzenie miało na przebieg realizacji Umowy (w tym poszczególnych zobowiązań Strony).</li>
                    <li>Każda ze Stron zobowiązana jest do samodzielnego naliczania, pobierania i odprowadzania wszystkich mających zastosowanie podatków i opłat we własnym zakresie, w tym wystawiania i przekazywania Klientom dokumentów sprzedaży, w tym paragonów oraz faktur VAT, jeżeli dotyczy.</li>
                </ol>
            </li>
            <li>REKLAMACJE ZWIĄZANE Z PLATFORMĄ
                <ol>
                    <li>Reklamacje związane z Platformą, w tym Usługami, Restauracja może składać na adres poczty elektronicznej: restauracje@apetigo.com. W zgłoszeniu reklamacji należy opisać przyczyny reklamacji, żądanie Restauracji oraz informacje pozwalające zidentyfikować Restaurację, tj. np. dane identyfikacyjne Restauracji, Login.</li>
                    <li>Usługodawca rozpatruje reklamacje spełniające wymogi określone w pkt. 14.1 powyżej w terminie do 30 dni od dnia ich otrzymania oraz informuje Restaurację o wyniku ich rozpatrzenia. W sytuacji, gdy podane w reklamacjach dane lub informacje wymagają uzupełnienia, Usługodawca zwraca się do Restauracji, przed ich rozpatrzeniem, o ich uzupełnienie. Brak odpowiedzi na Reklamację w powyższym terminie nie jest tożsamy z jej uznaniem.</li>
                    <li>Usługodawca zobowiązuje się działać z należytą starannością, aby Platforma oraz Usługi działały w sposób ciągły, bez zakłóceń uniemożliwiających obsługę Zamówień i realizację Umów dot. Zamówień.</li>
                    <li>Wszelkie oświadczenia i zawiadomienia przewidziane w Umowie, w tym w Regulaminem, jeśli odmiennie nie wskazano, będą miały formę dokumentową i zostaną wysłane odpowiednio na adres Restauracji stanowiący jej Login lub adres Usługodawcy restauracje@apetigo.com.</li>
                </ol>
            </li>
            <li>ROZWIĄZANIE UMOWY
                <ol>
                    <li>Umowa zostaje zawarta na czas nieokreślony. W przypadku, gdy Restauracja nie zarejestruje się w ramach Platformy (założenia Konta) w terminie 7 dni od dnia podpisania Umowy, Usługodawca ma prawo do rozwiązania Umowy z zachowaniem 7-dniowego okresu wypowiedzenia.</li>
                    <li>Każda ze Stron może wypowiedzieć Umowę w każdym czasie z zachowaniem miesięcznego okresu wypowiedzenia ze skutkiem na koniec miesiąca kalendarzowego. Usługodawca może wypowiedzieć Umowę w szczególności z uwagi na zmiany techniczne lub organizacyjne w ramach działalności Usługodawcy lub w przypadku naruszenia przez Restaurację postanowień Umowy, w tym Regulaminu.</li>
                    <li>Po upływie okresu wypowiedzenia, Usługodawca ograniczy możliwość korzystania z Konta przez Restaurację wyłącznie do realizacji i obsługi zobowiązań powstałych do dnia upływu terminu wypowiedzenia bez możliwości oferowania Produktów do sprzedaży.</li>
                    <li>Niezależnie od uprawnienia wskazanego w pkt 15.2 pow
                        <ol>
                            <li>Restauracja rażąco narusza Umowę, w tym Regulamin, lub wielokrotnie narusza postanowienia Umowy, w tym Regulaminu;</li>
                            <li>Restauracja nie uiszcza we wskazanym terminie należności wynikających ze współpracy z Usługodawcą w związku z zawarciem Umowy;</li>
                            <li>działalność Restauracji jest sprzeczna z obowiązującymi normami obyczajowymi, a także zasadami współżycia społecznego, a także jeśli narusza prawa osób trzecich.</li>
                        </ol>
                    </li>
                    <li>Oświadczenie o wypowiedzeniu Umowy powinno zostać złożone drugiej Stronie w formie dokumentowej pod rygorem nieważności. Usługodawca oświadczenie o wypowiedzeniu Umowy skieruje na adres poczty elektronicznej Restauracji stanowiący Login, dodatkowo określając podstawę wypowiedzenia określoną Regulaminem. Restauracja skieruje oświadczenie o rozwiązaniu Umowy na adres e-mail: restauracje@apetigo.com.</li>
                    <li>Usługodawca, niezależnie od możliwości wypowiedzenia Umowy, o którym mowa w pkt 15.4 powyżej, może podjąć decyzję o zawieszeniu Restauracji dostępu do Platformy, w tym Usług (lub części Usług), w trybie natychmiastowym w przypadkach wskazanych w pkt 15.4 powyżej do czasu wyjaśnienia sytuacji i wszystkich jej okoliczności. Usługodawca prześle Restauracji w momencie zawieszenia dostępu do Platformy, w tym Usług, informację o podjętej decyzji zawieszenia na adres poczty elektronicznej stanowiący Login wraz ze wskazaniem przyczyny zawieszenia oraz ewentualnych dalszych kroków (m.in. prośby o dodatkowe informacje). Przed zawieszeniem dostępu do Platformy, w tym Usług, nastąpi ograniczenie korzystania z Konta przez Restaurację wyłącznie do realizacji i obsługi zobowiązań powstałych do dnia podjęcia decyzji o zawieszeniu. Po wyjaśnieniu sytuacji i okoliczności jej towarzyszących przez Usługodawcę, Usługodawca może podjąć decyzję o cofnięciu zawieszenia Konta lub rozwiązaniu Umowy w trybie określonym w pkt 15.4 powyżej.</li>
                    <li>Usługodawca, w zależności od swojej decyzji i ciążących na nim obowiązków prawnych, po rozwiązaniu Umowy może przechowywać informacje przekazane lub wygenerowane przez Restaurację w związku z korzystaniem z Platformy, w tym Usług. Restauracja, w zależności od decyzji Usługodawcy, w uzasadnionych przypadkach może po rozwiązaniu Umowy uzyskać dostęp do informacji, o których mowa w zdaniu poprzedzającym, na zasadach każdorazowo określonych przez Usługodawcę.</li>
                </ol>
            </li>
            <li>DANE OSOBOWE
                 <ol>
                    <li>W ramach wykonywania Umowy każda ze Stron pozostaje odrębnym administratorem danych osobowych swoich pracowników i współpracowników zaangażowanych w wykonywanie Umowy w rozumieniu RODO.</li>
                    <li>Restauracja zobowiązuje się przetwarzać dane osobowe Klientów zgodnie z obowiązującymi przepisami prawa, w szczególności RODO oraz jest zobowiązana do należytej realizacji obowiązku informacyjnego względem Klientów.</li>
                    <li>Strony co do zasady są niezależnymi administratorami danych osobowych Klientów. W przypadku, gdy między Stronami dochodziłoby do powierzenia danych osobowych do przetwarzania (w szczególności - w zakresie, w jakim Usługodawca przetwarzać będzie dane Klientów w imieniu i na rzecz Restauracji), odbywa się ono zgodnie z przepisami regulującymi ochronę danych osobowych oraz umową powierzenia przetwarzania danych osobowych, która stanowi Załącznik nr 2 do Regulaminu - Umowa powierzenia przetwarzania danych osobowych.</li>
                </ol>
            </li>
            <li>ZMIANY UMOWY / REGULAMINU
                 <ol>
                    <li>Usługodawca może dokonać zmiany Umowy lub Regulaminu w każdym czasie, w szczególności w przypadku wystąpienia jednej z poniżej wskazanych ważnych przyczyn:
                        <ol>
                            <li>zmiana przepisów prawa regulujących świadczenie usług drogą elektroniczną wpływająca na wzajemne prawa i obowiązki określone w Umowie lub Regulaminie, lub zmiana interpretacji powyższych przepisów prawa wskutek orze</li>
                            <li>zmiana sposobu / zasad działania Platformy, w tym Usług (np. aktualizacja Wymagań Technicznych wskazanych w Regulaminie, aktualizacja zasad plasowania, zmiany dot. Wynagrodzenia, zmiany wprowadzone z uwagi na względy cyberbezpieczeństwa, ochrony danych osobowych, zapobieganiu zagrożeniu bezpieczeństwa Platformy, Klientów i Restauracji);</li>
                            <li>zmiana zakresu świadczenia Usług w ramach Platformy poprzez wprowadzenie nowych, modyfikację lub wycofanie dotychczasowych funkcjonalności objętych Umową lub Regulaminem;</li>
                        </ol>
                    </li>
                    <li>W przypadku wprowadzania zmian w Umowie lub Regulaminie, Usługodawca z 15-dniowym wyprzedzeniem udostępni zmiany poprzez publikację w ramach Platformy oraz za pomocą wiadomości przesłanej na podany przez Restaurację adres poczty elektronicznej stanowiący Login, co Strony uznają za wprowadzenie informacji o zmianie do środka komunikacji elektronicznej w taki sposób, aby Restauracja mogła zapoznać się z jej treścią.</li>
                    <li>Restauracja ma możliwość wypowiedzenia Umowy przed upływem okresu, o którym mowa w pkt 17.2 powyżej. W przypadku wypowiedzenia Umowy przez Restaurację, Umowa ulega rozwiązaniu z upływem okresu, o którym mowa w pkt 17.2. Brak wypowiedzenia Umowy przed upływem okresu, o którym mowa w pkt 17.2 powyżej, oznacza akceptację przez Restaurację Umowy lub Regulaminu w nowym brzmieniu. Zmiana wchodzi w życie z upływem okresu, o którym mowa w pkt 17.2 powyżej.</li>
                    <li>Jeżeli jedno lub więcej postanowień Umowy lub Regulaminu będzie lub stanie się nieważne lub bezskuteczne, nie wpływa to na ważność lub skuteczność pozostałych jego postanowień. W miejsce postanowienia nieważnego lub bezskutecznego będzie miało zastosowanie postanowienie (zmodyfikowane i/lub dostosowane w odpowiednim stopniu), które jest najbardziej zbliżone do celu założonego przez Strony i wynikające ze spójności Umowy lub Regulaminu.</li>
                </ol>
            </li>
            <li>POUFNOŚĆ
                 <ol>
                    <li>Strony mogą wykorzystywać Informacje Poufne wyłącznie w celu realizacji Umowy, zgodnie z przyjętymi zobowiązaniami oraz uprawnieniami.</li>
                    <li>Strony mogą informować osoby trzecie o podjętej współpracy, jak i świadczonych usługach w ramach współpracy na zasadach opisanych w Umowie oraz Regulaminie.</li>
                    <li>Informacje Poufne: (i) będą traktowane przez Strony ze starannością odpowiadającą co najmniej staranności przyjętej dla ochrony własnych informacji o poufnym charakterze, jednakże ze starannością nie mniejszą niż należyta, (ii) z zastrzeżeniem poniższych postanowień, nie będą ujawniane podmiotom trzecim bez uprzedniego uzyskania pisemnej zgody Strony będącej właścicielem Informacji Poufnej pod rygorem nieważności, (iii) zostaną udostępnione wyłącznie pracownikom Stron, ich podwykonawcom lub podmiotom kontrolowanym przez daną Stronę, w zakresie i w sposób niezbędny do wykonania Umowy, oraz (iv) zostaną zabezpieczone przed kopiowaniem, powielaniem lub innym rozpowszechnianiem, chyba że powyższe czynności pozostają niezbędne do wykonania Umowy.</li>
                    <li>Zobowiązanie do zachowania w poufności i ograniczonego korzystania z Informacji Poufnych nie mają zastosowania do informacji, które: (i) stały się publicznie znane i dostępne bez naruszenia Umowy, (ii) zostały ujawnione na podstawie uprzedniej pisemnej zgody Strony będącej właścicielem Informacji Poufnej, (iii) zostały ujawnione w związku z wykonaniem orzeczenia sądu albo zastosowania powszechnie obowiązujących przepisów prawa.</li>
                    <li>W granicach wynikających z powszechnie obowiązujących przepisów prawa, Strony będą niezwłocznie informowały się nawzajem o żądaniu ujawnienia Informacji Poufnych oraz zobowiązują się do właściwej współpracy w celu zastosowania odpowiednich środków ochronnych wobec Informacji Poufnych.</li>
                    <li>Zobowiązanie do zachowania poufności obowiązuje przez cały okres trwania Umowy, jak również przez okres 5 lat po jej rozwiązaniu, wypowiedzeniu, wygaśnięciu lub innym zakończeniu obowiązywania.</li>
                    <li>Strony zobowiązują się zawrzeć z osobami z którymi współpracują lub którymi posługują się przy wykonywaniu Umowy odrębne umowy zobowiązujące do zachowania poufności lub zawrzeć klauzule poufności w umowach już zawartych.</li>
                    <li>Restauracja zobowiązuje się, że nie będzie pośrednio lub bezpośrednio zbierać, przetwarzać ani analizować danych związanych z wykonywaniem Umowy w innym celu niż realizacja Umowy. Strony postanawiają, że dane związane z wykonywaniem Umowy (w szczególności: pozyskane kontakty, informacje o stawkach, czasie dostawy, obszarach dostawy) stanowią Informacje Poufne i stanowią wyłączną własność Usługodawcy o charakterze tajemnicy przedsiębiorstwa.</li>
                </ol>
            </li>
            <li>POSTANOWIENIA KOŃCOWE
                 <ol>
                    <li>Prawem właściwym dla Umowy (w tym Regulaminu) jest prawo Rzeczypospolitej Polskiej, a sądami właściwymi są sądy powszechne w Rzeczypospolitej Polskiej, chyba że co innego wynika z bezwzględnie obowiązujących przepisów prawa. Ewentualne spory pomiędzy Usługodawcą a Restauracją związane lub wynikające z Umowy zostają poddane sądowi właściwemu ze względu na siedzibę Usługodawcy.</li>
                    <li>Usługodawca, bez zmiany Umowy (w tym Regulaminu), może modyfikować techniczny sposób działania Platformy, w tym realizacji Usług, w szczególności z przyczyn natury technologicznej (rozwój przeglądarek i technologii), jednak nie może mieć to wpływu na zakres oraz jakość Platformy, w tym Usług, a także zakres praw i obowiązków Restauracji i Usługodawcy.</li>
                    <li>Zbycie, cesja lub zlecenie podwykonawstwa przez Restaurację uprawnień lub obowiązków związanych z Umową może nastąpić wyłącznie na podstawie uprzedniej zgody Usługodawcy wyrażonej w formie pisemnej pod rygorem nieważności. Wyrażona zgoda, nie ma wpływu na zobowiązania lub odpowiedzialność Restauracji wynikające z Umowy, w tym Regulaminu.</li>
                    <li>Strony będą dążyć do ugodowego załatwienia wszelkich sporów między Usługodawcą a Restauracją związanych z Umową, w szczególności w ramach mediacji. W przypadku wyrażenia przez Strony zgody na prowadzenie mediacji w formie pisemnej pod rygorem nieważności, mediacja będzie prowadzona przez mediatora z Europejskiego Instytutu Mediacji lub mediatora z Centrum Mediacji przy Krajowej Radzie Radców Prawnych na zasadach określonych przez wskazany podmiot. Usługodawca ponosi rozsądną część całkowitych kosztów mediacji, które każdorazowo zostaną ustalone między Stronami. Lista mediatorów oraz aktualne regulaminy mediacji są dostępne pod następującymi linkami https://instytutmediacji.eu/ (dla Europejskiego Instytutu Mediacji), lub http://mediacje.kirp.pl/ dla Centrum Mediacji przy Krajowej Radzie Radców Prawnych.</li>
                    <li>Integralną częścią Regulaminu stanowią następujące Załączniki:
                        <ol>
                            <li>Załącznik nr 1 do Regulaminu – Zasady Współpracy z Operatorem Płatności;</li>
                            <li>Załącznik nr 2 do Regulaminu – Umowa powierzenia przetwarzania danych osobowych;</li>
                            <li>Załącznik nr 3 do Regulaminu – Klauzula informacyjna dot. przetwarzania danych osobowych.</li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
        <br/>
        <br/>
        <br/>
        <h1>ZAŁĄCZNIK NR 1 – ZASADY WSPÓŁPRACY Z OPERATOREM PŁATNOŚCI</h1>
        <ol>
            <li> 
                <ol>
                    <li>Pojęcia pisane wielką literą a niezdefiniowane w Zasadach mają znaczenie, jakie nadaje im Regulamin, z zastrzeżeniem ust. 1.2. poniżej.</li>
                    <li>Na potrzeby niniejszego Załącznika użyto niżej wymienionych zwrotów pisanych wielką literą i należy je rozumieć w podanym niżej znaczeniu, chyba że z kontekstu ich użycia wyraźnie wynika co innego:
                        <ol>
                            <li>Zasady – niniejsze Zasady współpracy z operatorem płatności określające zasady współpracy z operatorem płatności w zakresie wpłat dokonywanych przez Klientów, zwrotów dokonywanych przez Klientów oraz rozliczeń Restauracji z Usługodawcą w ramach Usługi;</li>
                            <li>Operator płatności – operator płatności wskazany w Regulaminie;</li>
                            <li>Usługa - usługi świadczone przez Operatora płatności, w tym usługi płatnicze.</li>
                        </ol>
                    </li>
                    <li>Restauracja zobowiązana jest do zapoznania się, zaakceptowania oraz stosowania przyjętych przez Operatorów płatności regulaminów Usług. Operator płatności oraz organizacje płatnicze (przedsiębiorcy oferujący usługę płatniczą, w ramach której Klient przekazuje Operatorowi płatności środki pieniężne) mogą kontaktować się bezpośrednio z Restauracją, w tym kierować do Restauracji zapytania. Restauracja jest zobowiązana do odpowiedzi na zapytania kierowane do niej przez Operatora płatności oraz organizacje płatnicze.</li>
                    <li>W ramach Usługi Operator płatności zapewni możliwość udostępnienia Klientom Restauracji opłacającym Zamówienia złożone Restauracji za pośrednictwem Platformy następujących sposobów płatności:
                        <ol>
                            <li>karta płatnicza akceptowana przez bank;</li>
                            <li>płatność elektroniczna przelewami automatycznymi (w tym BLIK oraz za pośrednictwem PayU).</li>
                        </ol>
                    </li>
                    <li>Restauracja może korzystać z Usługi wyłącznie osobiście i korzystanie takie ograniczone jest do przypadków wskazanych w Umowie, w tym Regulaminie, oraz w niniejszych Zasadach.</li>
                    <li>Usługa będzie świadczona wyłącznie dla płatności w walutach: złoty polski (PLN).</li>
                    <li>W celu korzystania z Usługi niezbędne jest posiadanie urządzenia pozwalającego na dostęp do Internetu, włącznie z programem służącym do przeglądania jego zasobów oraz posiadanie rachunku prowadzonego przez bank lub instytucję finansową mającą siedzibę w jednym z krajów Unii Europejskiej lub w państwie podlegającym równoważnym standardom w zakresie przeciwdziałania praniu pieniędzy i finansowania terroryzmu.</li>
                    <li>Restauracja nie jest uprawniona do kopiowania ani przechwytywania szczególnie chronionych danych dotyczących płatności, takich jak numer karty płatniczej, kody CVV, kody „PIN” itp., które mogą być dostarczane przez Klientów na potrzeby zapłaty Ceny z tytułu Umów dot. Zamówienia z wykorzystaniem Usługi.</li>
                    <li>Operator płatności prowadzi dla każdej Restauracji osobne subkonto, w ramach którego księgowane są wpłaty od Klientów z tytułu Umów dot. Zamówienia.</li>
                    <li>Wypłaty z subkonta prowadzonego dla Restauracji są możliwe w trzecim dniu roboczym po realizacji danej Umowy dot. Zamówienia.</li>
                    <li>Wypłaty będą realizowane na podany przez Restaurację rachunek bankowy w złotych polskich (PLN) prowadzony przez bank lub instytucję finansową mającą siedzibę w jednym z krajów Unii Europejskiej lub w państwie podlegającym równoważnym standardom w rozumieniu przepisów o przeciwdziałaniu praniu brudnych pieniędzy i finansowaniu terroryzmu.</li>
                    <li>Wypłaty następują w każdy poniedziałek i czwartek danego tygodnia. Wypłaty obejmują środki wpłacone przez Klientów na poczet Ceny z tytułu zawartych Umów dot. Zamówienia, które zostaną zaksięgowane na subkoncie Restauracji do dnia wypłaty. Wypłaty są każdorazowo pomniejszane o wszelkie kwoty należne Usługodawcy zgodnie z Umową, w tym Regulaminem, w szczególności Wynagrodzenie.</li>
                    <li>Operator płatności będzie dokonywał zwrotu środków (Ceny) na rzecz Klientów w przypadku niewykonania lub nienależytego wykonania Umowy dot. Zamówienia, a także w przypadku odstąpienia przez Restaurację od Umowy dot. Zamówienia. Zwrot nastąpi na podstawie raportu przekazywanego przez Usługodawcę Operatorowi płatności.</li>
                    <li>Jeżeliwciągu24godzinodotrzymaniaraportuodUsługodawcy,nasubkoncieRestauracjiniebędziewystarczających środków do dokonania zwrotu środków, Usługodawca może podjąć decyzję o zleceniu (z zastrzeżeniem zwrotu) przeksięgowania przez Operatora płatności odpowiednich środków na subkonto Restauracji z subkonta Usługodawcy i zwrócenie ich Klientowi. Usługodawca jednocześnie zastrzega sobie zwrot przez Restaurację wszelkich przeksięgowanych w opisany niniejszym punkcie sposób środków przeznaczonych na spełnienie zobowiązania Restauracji wobec Klienta (do spełnienia, którego Usługodawca nie jest zobligowany). Rozliczenie środków przeksięgowanych na subkonto Restauracji z subkonta Usługodawcy może w szczególności nastąpić wraz z najbliższą wypłatą środków z subkonta prowadzonego dla Restauracji. Niniejszy punkt nie stanowi zobowiązania do przeksięgowywania odpowiednich środków w każdym przypadku.</li>
                 </ol>
            </li>
        </ol>
        <br/>
        <br/>
        <br/>
        <h1>ZAŁĄCZNIK NR 2 - UMOWA POWIERZENIA PRZETWARZANIA DANYCH OSOBOWYCH</h1>
        <ol>
            <li>DEFINICJE
                <br/>
                Na potrzeby niniejszego załącznika przyjmuje się następujące definicje:
                <ol>
                    <li><strong>Administrator</strong> - osoba fizyczna lub prawna, organ publiczny, jednostka lub inny podmiot, który samodzielnie lub wspólnie z innymi ustala cele i sposoby przetwarzania danych osobowych; jeżeli cele i sposoby takiego przetwarzania są określone w prawie Unii lub w prawie państwa członkowskiego, to również w prawie Unii lub w prawie państwa członkowskiego może zostać wyznaczony administrator lub mogą zostać określone konkretne kryteria jego wyznaczania w rozumieniu art. 4 pkt 7 RODO;</li>
                    <li><strong>Akty Prawne </strong> - bezwzględnie obowiązujące przepisy prawa w dacie zawarcia Umowy Powierzenia, z uwzględnieniem ich ewentualnych zmian, które nastąpią w okresie obowiązywania Umowy Powierzenia, jak również bezwzględnie obowiązujące przepisy prawa, które wejdą w życie w okresie jej obowiązywania, z uwzględnieniem ich zmian, które nastąpią w tym okresie;</li>
                    <li><strong>Dane Osobowe</strong> - wszelkie informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej („osobie, której dane dotyczą”); możliwa do zidentyfikowania osoba fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej, w rozumieniu art. 4 pkt 1 RODO;</li>
                    <li><strong>Procesor</strong> - osoba fizyczna lub prawna, organ publiczny, jednostka lub inny podmiot, który przetwarza dane osobowe w imieniu Administratora, w rozumieniu art. 4 pkt 8 RODO;</li>
                    <li><strong>RODO</strong> - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych);</li>
                    <li><strong>Umowa Powierzenia</strong> - niniejszy załącznik, stanowiący umowę powierzenia przetwarzania Danych Osobowych, o której mowa w art. 28 RODO.</li>
                </ol>
            </li>
            <li>PRZEDMIOT UMOWY
                <ol>
                    <li>Administratorem w rozumieniu Umowy Powierzenia jest Restauracja, a Procesorem jest Usługodawca Umowa Powierzenia zostaje zawarta w związku z Umową pomiędzy Administratorem a Procesorem, której celem, jest świadczenie przez Procesora na rzecz Administratora Usług za pośrednictwem Platformy.</li>
                    <li>Administrator powierza Procesorowi do przetwarzania Dane Osobowe w zakresie określonym Umową Powierzenia i poleca Procesorowi ich przetwarzanie, a Procesor zobowiązuje się do ich przetwarzania zgodnego z obowiązującymi Aktami Prawnymi i Umową Powierzenia.</li>
                    <li>Procesor ponosi odpowiedzialność za działania osób, przy pomocy których przetwarza Dane Osobowe (w tym podwykonawców), jak za własne działanie i zaniechanie.</li>
                </ol>
            </li>
            <li>ZAKRES POWIERZONYCH DANYCH OSOBOWYCH
                <ol>
                    <li>Wykaz kategorii osób, zakres powierzonych Danych Osobowych, cel przetwarzania oraz wykaz operacji na Danych Osobowych zawiera Załącznik A do Umowy Powierzenia.</li>
                </ol>
            </li>
            <li>ZASADY PRZETWARZANIA DANYCH OSOBOWYCH
                 <ol>
                    <li>Administrator ma prawo i obowiązek podejmowania decyzji w zakresie celów i sposobów przetwarzania Danych Osobowych i wydawania w tym zakresie poleceń Procesorowi. Polecenia zostały określone w Umowie Powierzenia oraz w każdym czasie mogą być wydawane przez Administratora i przekazywane Procesorowi w udokumentowanej formie, tj. w formie pisemnej, w tym w formie elektronicznej.</li>
                    <li>Procesor przetwarza Dane Osobowe wyłącznie na udokumentowane polecenie Administratora, chyba że obowiązek taki nakładają na niego Akty Prawne. W takim przypadku przed rozpoczęciem przetwarzania Procesor informuje Administratora o tym obowiązku prawnym, o ile Akty Prawne nie zabraniają udzielania takiej informacji z uwagi na ważny interes publiczny.</li>
                    <li>Udokumentowane polecenie może być działaniem dokonanym za pośrednictwem Platformy. Akceptacja Regulaminu przez Administratora stanowi polecenie wydane Procesorowi do przetwarzania Danych Osobowych zgodnie z Regulaminem na podstawie Umowy Powierzenia.</li>
                    <li>Procesor zobowiązany jest niezwłocznie zastosować się do zaleceń Administratora dotyczących przetwarzania Danych Osobowych, w szczególności dotyczących ich zabezpieczenia, chyba że zalecenia te są sprzeczne z Aktami Prawnymi.</li>
                    <li>W przypadku, gdy w ocenie Procesora, polecenie wydane przez Administratora stanowić może naruszenie Aktów Prawnych, Procesor niezwłocznie informuje Administratora, uzasadniając swoje stanowisko, poprzez wskazanie konkretnych przepisów jakie naruszać mogłoby polecenie oraz sposób tego naruszenia. Administrator, nie później niż w terminie 7 dni od dnia doręczania ww. informacji Procesora, ustosunkowuje się do zgłoszenia Procesora i decyduje o wycofaniu polecenia lub jego utrzymaniu (w takim przypadku, przedstawiając w tym zakresie uzasadnienie zgodności polecenia z Aktami Prawnymi). Do czasu przedstawienia decyzji Administratora, Procesor jest uprawniony do wstrzymania przetwarzania Danych Osobowych w zakresie objętym tym poleceniem. W przypadku decyzji o utrzymaniu w mocy polecenia, co do którego Procesor zgłosił swoje zastrzeżenia, odpowiedzialność za przetwarzanie Danych Osobowych ponosi Administrator.</li>
                </ol>
            </li>
            <li>BEZPIECZEŃSTWO PRZETWARZANIA DANYCH OSOBOWYCH
                 <ol>
                    <li>Procesor oświadcza, że podejmuje wszelkie wymagane przepisami prawa środki, tak by przetwarzanie spełniało wymogi Aktów Prawnych, w tym wynikające z art. 32 RODO i chroniło prawa osób, których dane dotyczą. W szczególności, Procesor stosuje techniczne i organizacyjne środki bezpieczeństwa zapewniające ochronę powierzonych do przetwarzania Danych Osobowych, odpowiednią do zagrożeń oraz kategorii Danych Osobowych objętych ochroną, a w szczególności zabezpiecza Dane Osobowe przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem Aktów Prawnych oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.</li>
                    <li>Procesor jest uprawniony i zobowiązany do podejmowania decyzji dotyczących technicznych i organizacyjnych środków bezpieczeństwa, które mają być stosowane w celu stworzenia niezbędnego (i uzgodnionego) poziomu bezpieczeństwa Danych Osobowych. Procesor wdraża jednak w każdym przypadku, co najmniej następujące środki, uzgodnione z Administratorem:
                        <ol>
                            <li>udzielenie dostępu do Danych Osobowych wyłącznie niezbędnemu (ograniczonemu) kręgowi osób (pracowników / współpracowników);</li>
                            <li>w przypadku Danych Osobowych przetwarzanych w postaci papierowej – przechowywanie w zamkniętych szafkach / pomieszczeniach, do których dostęp mają tylko osoby upoważnione;</li>
                            <li>w przypadku Danych Osobowych przetwarzanych w postaci elektronicznej (w systemach teleinformatycznych) – zapewnienie dostępu wyłącznie po zalogowaniu do systemu teleinformatycznego, przy wykorzystaniu dedykowanego, unikalnego identyfikatora przeznaczonego do wyłącznego użytku upoważnionej osoby i hasła o odpowiednim poziomie trudności.</li>
                        </ol>
                    </li>
                    <li>Procesor będzie przetwarzał Dane Osobowe w szczególności w postaci papierowej lub w postaci cyfrowej, w tym za pośrednictwem systemów informatycznych wykorzystywanych przez Procesora do obsługi Platformy, w tym świadczenia Usług na rzecz Administratora.</li>
                    <li>Procesor zapewnia by osoby upoważnione do przetwarzania Danych Osobowych zobowiązały się do zachowania tajemnicy lub by podlegały odpowiedniemu ustawowemu obowiązkowi zachowania tajemnicy. Procesor zobowiązuje się nie ujawniać osobom nieupoważnionym informacji o Danych Osobowych, w szczególności o środkach ochrony i zabezpieczeniach stosowanych w odniesieniu do Danych Osobowych przez niego lub przez Administratora.</li>
                </ol>
            </li>
            <li>WSPARCIE ADMINISTRATORA
                 <ol>
                    <li>Procesor, uwzględniając charakter przetwarzania, w miarę możliwości pomaga Administratorowi poprzez odpowiednie środki techniczne i organizacyjne wywiązać się z obowiązku odpowiadania na żądania osoby, której dane dotyczą, w zakresie wykonywania jej praw określonych Aktami Prawnymi, tj. wspiera Administratora w realizacji praw osób których dane dotyczą do:
                        <ol>
                            <li>dostępu do Danych Osobowych, w tym do uzyskania ich kopii;</li>
                            <li>sprostowania Danych Osobowych;</li>
                            <li>usunięcia Danych Osobowych („prawo do bycia zapomnianym”);</li>
                            <li>ograniczenia przetwarzania Danych Osobowych;</li>
                            <li>przenoszenia Danych Osobowych;</li>
                            <li>sprzeciwu;</li>
                            <br/>
                            oraz obowiązku powiadamiania o sprostowaniu, usunięciu lub ograniczeniu przetwarzania Danych Osobowych.
                        </ol>
                    </li>
                    <li>Uwzględniając charakter przetwarzania oraz dostępne Procesorowi informacje, Procesor pomaga Administratorowi wywiązać się z obowiązków określonych Aktami Prawnymi, w zakresie wymaganym przez przepisy, obejmujące także art. 32-36 RODO, w tym Procesor, uwzględniając charakter przetwarzania oraz dostępne mu informacje, pomaga Administratorowi wywiązać się z w szczególności w następującym zakresie:
                        <ol>
                            <li>dostarcza Administratorowi informacje na temat wdrożonych środków technicznych i organizacyjnych, a w przypadku, gdy w ocenie Administratora środki te będą niewystarczające, wdraża dodatkowe środki techniczne i organizacyjne określone przez Administratora;</li>
                            <li>wspiera Administratora w ocenie skutków dla ochrony danych oraz na jego żądanie – w zapewnieniu przestrzegania obowiązków wynikających z tej oceny oraz konsultacjach z organem nadzorczym;</li>
                            <li>w przypadku wystąpienia naruszenia ochrony Danych Osobowych, w zależności od jego rodzaju oraz decyzji Administratora – wspiera Administratora w zgłoszeniu do organu nadzorczego lub zawiadomieniu osób, których Dane Osobowe dotyczą.</li>
                        </ol>
                    </li>
                    <li>Procesor każdorazowo poinformuje Administratora o wszelkich zdarzeniach stanowiących naruszenie Aktów Prawnych lub Umowy Powierzenia, w szczególności mogących skutkować odpowiedzialnością Administratora lub Procesora na podstawie Aktów Prawnych lub Umowy Powierzenia, niezwłocznie, od zaistnienia danego zdarzenia.</li>
                </ol>
            </li>
            <li>POWIERZENIE DANYCH OSOBOWYCH PODMIOTOM TRZECIM
                 <ol>
                    <li>Procesor przestrzega warunków korzystania z usług innego podmiotu przetwarzającego, o których mowa w Aktach Prawnych oraz niniejszym punkcie.</li>
                    <li>Procesor może w zakresie przetwarzania Danych Osobowych korzystać z usług innego podmiotu przetwarzającego (ogólna zgoda Administratora), informując Administratora o wszelkich zamierzonych zmianach dotyczących dodania lub zastąpienia innych podmiotów przetwarzających, a Administratorowi przysługuje możliwość wyrażenia sprzeciwu wobec takich zmian.</li>
                    <li>W razie powierzenia, o którym mowa w pkt 7.2. powyżej, Procesor zapewni by na podmiot trzeci nałożone zostały te same obowiązki ochrony danych jak w Umowie Powierzenia, w szczególności obowiązek zapewnienia wystarczających gwarancji wdrożenia odpowiednich środków technicznych i organizacyjnych, by przetwarzanie odpowiadało wymogom Aktów Prawnych. Procesor zapewni, aby Administrator mógł wykonywać swoje uprawnienia, jakie przysługują mu względem Procesora (w szczególności przeprowadzania audytów i inspekcji) zgodnie z prawem lub Umową Powierzenia, także bezpośrednio względem tego podmiotu. Jeżeli ten inny podmiot przetwarzający nie wywiąże się ze spoczywających na nim obowiązków ochrony Danych Osobowych, pełna odpowiedzialność wobec Administratora za wypełnienie obowiązków tego innego podmiotu przetwarzającego spoczywa na Procesorze.</li>
                </ol>
            </li>
            <li>PRZEKAZYWANIE DANYCH OSOBOWYCH DO PAŃSTW TRZECICH
                 <ol>
                    <li>Przekazanie Danych Osobowych przez Procesora do państwa trzeciego lub organizacji międzynarodowej, jak również korzystanie przez Procesora z usług innego podmiotu przetwarzającego w państwie trzecim, może nastąpić jedynie na udokumentowane polecenie Administratora, chyba że obowiązek taki nakłada na Procesora prawo Unii lub prawo państwa członkowskiego, któremu podlega Procesor. W takim przypadku przed rozpoczęciem przetwarzania Procesor informuje Administratora o tym obowiązku prawnym, o ile prawo to nie zabrania udzielania takiej informacji z uwagi na ważny interes publiczny.</li>
                    <li>W przypadku przekazania Danych Osobowych do państwa trzeciego lub organizacji międzynarodowej wobec którego Komisja nie stwierdziła odpowiedniego stopnia ochrony, takie przekazanie nastąpi z wykorzystaniem odpowiednich zabezpieczeń takich jak standardowe klauzule ochrony danych. Procesor i Administrator zobowiązują się w takim przypadku podjąć negocjacje w dobrej wierze, celem niezwłocznego wdrożenia takich odpowiednich zabezpieczeń.</li>
                </ol>
            </li>
            <li>UPRAWNIENIA KONTROLNE
                 <ol>
                    <li>Procesor udostępnia Administratorowi wszelkie informacje niezbędne do wykazania spełnienia obowiązków Procesora, jako podmiotu przetwarzającego Dane Osobowe, wynikających z Aktów Prawnych, oraz umożliwia Administratorowi lub audytorowi upoważnionemu</li>
                    <li>Strony uzgadniają, że Administrator będzie uprawniony do przeprowadzenia audytu zgodności przetwarzania Danych Osobowych przez Procesora, wyłącznie w sytuacji, gdy poweźmie on uzasadnione podejrzenie naruszenia przez Procesora Aktów Prawnych lub Umowy Powierzenia i nie częściej niż raz na 12 miesięcy. Administrator jest zobowiązany przedstawić okoliczności uzasadniające jego podejrzenie w zawiadomieniu o zamiarze wszczęcia audytu doręczonym Procesorowi, co najmniej na 60 dni przed planowanym audytem.</li>
                    <li> Audyt, o którym mowa w pkt 9.2. powyżej, może obejmować wyłącznie kontrolę właściwej dokumentacji oraz prawo uzyskania niezbędnych informacji / wyjaśnień dotyczących realizacji postanowień Umowy oraz Umowy Powierzenia. Procesor ma prawo do odmowy przekazana dokumentacji lub udzielenia informacji / wyjaśnień w zakresie, w którym audyt mógłby zagrażać ujawnieniu innych danych osobowych, aniżeli przetwarzanych przez Procesora na mocy Umowy Powierzenia lub ujawnieniem tajemnicy przedsiębiorstwa. W takim przypadku Procesor zobowiązany jest w sposób jasny i wyczerpujący, w formie pisemnej, w tym w formie elektronicznej, uzasadnić swoje stanowisko.</li>
                </ol>
            </li>
            <li>POSTANOWIENIA KOŃCOWE
                 <ol>
                    <li>Umowa Powierzenia zostaje zawarta na czas obowiązywania zawartej przez Strony Umowy.</li>
                    <li>Rozwiązanie Umowy przez którąkolwiek ze Stron, w każdym czasie i trybie, skutkuje rozwiązaniem Umowy Powierzenia.</li>
                    <li>Procesor, po zakończeniu przetwarzania Danych Osobowych na podstawie Umowy Powierzenia, zależnie od decyzji Administratora usuwa lub zwraca mu wszelkie Dane Osobowe oraz usuwa wszelkie ich istniejące kopie, chyba że Akty Prawne nakazują przechowywanie Danych Osobowych.</li>
                    <li>Strony wyznaczają osoby kontaktowe w sprawach związanych z wykonywaniem Umowy Powierzenia:
                        <ol>
                            <li>ze Strony Administratora: imię, nazwisko, numer kontaktowy i adres e-mail wskazane przez Administratora w Umowie;</li>
                            <li>ze Strony Procesora: dane kontaktowe wskazane przez Procesora w Umowie.</li>
                        </ol>
                    </li>
                    <li>W sprawach nieokreślonych Umową Powierzenia znajdują zastosowanie odpowiednie postanowienia Umowy (w tym Regulamin), a w zakreślenie nieokreślonym Umową - powszechnie obowiązujące przepisy prawa.</li>
                    <li>eżelijednolubwięcejpostanowieńUmowyPowierzeniabędzielubstaniesięnieważnelubbezskuteczne,niewpływa to na ważność lub skuteczność pozostałych jej postanowień. W miejsce postanowienia nieważnego lub bezskutecznego będzie miało zastosowanie postanowienie, które jest najbardziej zbliżone do celu założonego przez Strony.</li>
                    <li>Następujące załączniki stanowią integralną część Umowy Powierzenia:
                        <ol>
                            <li>Załącznik A: Dane Osobowe powierzone przez Administratora Procesorowi do przetwarzania;</li>
                            <li>Załącznik B: Lista zatwierdzonych dalszych podmiotów przetwarzających.</li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
        <br/>
        <br/>
        <br/>
        <h1>Załącznik A do Umowy Powierzenia</h1>
        Dane Osobowe powierzone przez Administratora Procesorowi do przetwarzania:
        <ol>
            <li>Kategorie osób, których dane dotyczą:
                <ol>
                    <li>Klienci Platformy, z którymi Restauracja zawarła Umowę dot. Zamówienia;</li>
                    <li>Pracownicy i Współpracownicy Administratora;</li>
                </ol>
            </li>
            <li>Zakres Danych Osobowych, w odniesieniu do każdej z ww. kategorii osób:
                <br/>
                Ad. 1.1.
                <br/>
                Procesor przetwarza Dane Osobowe powierzone przez Administratora:
                <ul>
                    <li>Dane identyfikacyjne, w szczególności: imię, nazwisko, numer NIP;</li>
                    <li>Dane kontaktowe, szczególności: adres e-mail, numer telefonu;</li>
                    <li>Dane adresowe, w szczególności: adres do realizacji Zamówienia / korespondencji;</li>
                    <li>Dane transakcyjne dotyczące Zamówień / Umów dot. Zamówień, w szczególności: numer identyfikacyjny płatności za Zamówienie, numer Zamówienia oraz nazwy Produktów składające się na Zamówienie, kwota Zamówienia, numer rachunku bankowego, oświadczenia Klientów składane w ramach Zamówienia.</li>
                </ul>
                <br/>
                Ad. 1.2.
                 <ul>
                    <li>imię,</li>
                    <li>nazwisko,</li>
                    <li>adres e-mail,</li>
                    <li>służbowy numer telefonu;</li>
                </ul>
            </li>
            <li>Cel przetwarzania przez Procesora powierzonych mu Danych Osobowych:
                <ol>
                    <li>Realizacja i rozliczenie Umów dot. Zamówień zawieranych pomiędzy Restauracją a Klientem;</li>
                    <li>Prowadzenie przez Procesora komunikacji z Klientami w zakresie dotyczącym Zamówień / Umów dot. Zamówienia oraz obsługi posprzedażowej oraz innych praw i obowiązków Procesora wynikających z Umowy i Regulaminu.</li>
                </ol>
            </li>
            <li>Operacje przetwarzania Danych Osobowych:
                <br/>
                <i>zbieranie, przechowywanie, przeglądanie, utrwalanie, organizowanie, porządkowanie, modyfikowanie, pobieranie, wykorzystywanie, ujawnianie poprzez przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie</i>
            </li>
        </ol>
        <br/>
        <br/>
        <br/>
        <h1>Załącznik B do Umowy Powierzenia</h1>
        <p>Lista zatwierdzonych dalszych podmiotów przetwarzających</p>
        <table className={"center-table"}>
        <thead>
          <tr>
            <th>Nazwa firmy i adres</th>
            <th>Kraj / Obszar</th>
            <th>Świadczone usługi/cel</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
                Microsoft Ireland Operations Limited, One Microsoft PlaceSouth County Business Park<br/>
                Leopardstown<br/>
                Dublin 18,<br/>
                D18 P521
            </td>
            <td>EOG</td>
            <td>Usługa przetwarzania w chmurze (Microsoft Azure)</td>
          </tr>
        </tbody>
      </table>
        <br/>
        <br/>
        <br/>
        <h1>ZAŁĄCZNIK NR 3 – KLAUZULA INFORMACYJNA DOT. PRZETWARZANIA DANYCH OSOBOWYCH</h1>
        <ol>
            <li>W związku z zawarciem Umowy Usługodawca możemy przetwarzać dane osobowe Restauracji, a także osób działających w jej imieniu. Pojęcia pisane wielką literą, niezdefiniowane w klauzuli informacyjnej, mają znaczenie, jakie nadaje im Umowa, w tym Regulamin.</li>
            <li>Administratorem danych osobowych jest APETIGO.COM spółka z ograniczoną odpowiedzialnością z siedzibą w Zielonej Górze, adres: ul. Wrocławska nr 17B, 65-427 Zielona Góra, adres e-mail: rodo@apetigo.com („Administrator”).</li>
            <li>Dane osobowe przetwarzamy zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), („RODO”).</li>
            <li>Podanie danych osobowych jest dobrowolne, jednak konieczne, aby zawrzeć Umowę oraz korzystać z Usług świadczonych odpłatnie przez Administratora.</li>
            <li>Dane osobowe podane w związku z zawarciem Umowy są przetwarzane przede wszystkim w celu świadczenia Usług na rzecz Restauracji. Podstawą prawną przetwarzania danych osobowych w tym celu jest uzasadniony interes prawny Administratora oraz Restauracji, w imieniu której działasz – art. 6 ust. 1 lit. f) RODO, a w przypadku, gdy Restauracja działa we własnym imieniu, to podstawą prawną przetwarzania danych osobowych w ww. celu jest realizacja umowy zawartej pomiędzy Administratorem a Restauracją – art. 6 ust. 1 lit. b) RODO.</li>
            <li>Dane osobowe mogą być również przetwarzane w celu:
                <ol>
                    <li>realizacji obowiązków prawnych ciążących na Administratorze, w szczególności wynikających z przepisów regulujących kwestie obowiązków księgowych i podatkowych - podstawa prawna: art. 6 ust. 1 lit. c) RODO, tj. niezbędność do wypełnienia obowiązku prawnego ciążącego na Administratorze;</li>
                    <li>działań marketingowych, analitycznych i statystycznych Administratora lub innych tzw. stron trzecich, z którymi Administrator współpracuje – podstawa prawna: art. 6 ust. 1 lit. f) RODO, tj. uzasadniony interes Administratora lub strony trzeciej;</li>
                    <li>ustalenia, obrony i dochodzenia roszczeń, które mogą powstać w ramach relacji z Administratorem oraz inne cele, które są niezbędne do realizacji prawnie uzasadnionych interesów Administratora lub strony trzeciej - podstawa prawna: art. 6 ust. 1 lit. f) RODO, tj. uzasadniony interes prawny Administratora lub strony trzeciej.</li>
                </ol>
            </li>
            <li>Podane dane osobowe będą przetwarzane:
                <ol>
                    <li>w związku z zawarciem Umowy – przez okres trwania Umowy;</li>
                    <li>dla prowadzonych przez Administratora działań marketingowych – przez okres istnienia uzasadnionego interesu lub do czasu wniesienia przez osobę, której dane dotyczą sprzeciwu wobec takiego przetwarzania,</li>
                </ol>
                chyba że przepisy prawa będą zobowiązywać Administratora do dłuższego przetwarzania tych danych lub będziemy je przechowywać dłużej na wypadek potencjalnych roszczeń, przez okres ich przedawnienia określony przepisami prawa, w szczególności kodeksu cywilnego, lub na wypadek innych celów wynikających z realizacji naszych prawnie uzasadnionych interesów. W każdym przypadku decyduje dłuższy termin przechowywania danych osobowych.
            </li>
            <li>W przetwarzaniu podanych danych osobowych w ograniczonym zakresie mogą brać udział podmioty współpracujące z Administratorem, w szczególności podmioty, które technicznie wspierają Administratora przy świadczeniu Usług, dostawcy usług hostingu lub usług teleinformatycznych, firmy, które serwisują oprogramowanie, wspierają Administratora w kampaniach marketingowych, jak również dostawcy usług prawnych i doradczych.</li>
            <li>W ramach korzystania przez Administratora z narzędzi wspierających jego bieżącą działalność udostępnianych podane dane osobowe mogą być przekazywane do państwa spoza Europejskiego Obszaru Gospodarczego („EOG”), w którym podmiot z nim współpracujący utrzymuje narzędzia służące do przetwarzania danych osobowych przy współpracy z Administratorem. Odpowiednie zabezpieczenia przekazywanych danych osobowych zostały zapewnione przez Administratora poprzez zastosowanie standardowych klauzul ochrony danych przyjętych na mocy decyzji Komisji Europejskiej oraz umów powierzenia danych do przetwarzania, spełniających wymogi RODO. W przypadku przekazywania danych do państw spoza EOG, Administrator dokłada wszelkich starań, żeby podmioty z którymi współpracuje zapewniały odpowiedni poziom ochrony poprzez podjęcie dodatkowych zabezpieczeń bezpieczeństwa danych osobowych. Kopię zabezpieczeń danych osobowych przekazywanych poza EOG można uzyskać kontaktując się z Administratorem.</li>
            <li>W związku z przetwarzaniem podanych danych osobowych, osobie, której dane dotyczą przysługują następujące prawa:
                <ol>
                    <li>przenoszenia danych osobowych, które zostały dostarczone Administratorowi, i które są przetwarzane w sposób zautomatyzowany, a przetwarzanie odbywa się na podstawie zgody lub na podstawie niezbędność do wykonania umowy, np. do innego administratora;</li>
                    <li>dostępu do danych osobowych;</li>
                    <li>żądania sprostowania i ograniczenia przetwarzania lub usunięcia danych osobowych;</li>
                    <li>wniesienia sprzeciwu wobec przetwarzania dotyczących jej danych osobowych w celu realizacji prawnie uzasadnionych interesów Administratora lub strony trzeciej (jeżeli nie ma innych ważnych prawnie uzasadnionych podstaw przetwarzania nadrzędnych wobec interesów osoby, której dane dotyczą). Jeżeli dane osobowe są przetwarzane na potrzeby marketingu bezpośredniego, osoba, której dane dotyczą ma prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania jej danych osobowych na potrzeby takiego marketingu – w takim wypadku danych osobowych nie wolno już przetwarzać do takich celów;</li>
                    <li>wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (ul. Stawki 2, 00-193 Warszawa).</li>
                </ol>
            </li>
        </ol>
    </article>
  );
}

export default Regulations;
