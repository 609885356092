import React from "react";
import "./App.scss";

declare type AppColorMode = "light" | "dark" | "white" | null | undefined;

function PrivacyPolicy() {
  const queryParams = new URLSearchParams(window.location.search);
  const theme = queryParams.get("theme") as AppColorMode;

  let className = 'light-theme';
  if(theme === "dark"){
     className = "dark-theme";
  } else if(theme === "white"){
     className = "white-theme"
  }
 
  return (
    <article className={className}>
      <h1>POLITYKA PRYWATNOŚCI I PLIKÓW COOKIE - PLATFORMA APETIGO.COM</h1>
      <h2>I. WPROWADZENIE</h2>
      <p>Polityka prywatności i plików cookie Platformy (dalej jako <strong>„Polityka”</strong>) ma charakter informacyjny i przeznaczona jest dla osób korzystających z Platformy. Użyte w Polityce pojęcia pisane wielką literą mają znaczenie, jakie nadaje im Regulamin – Platforma APETIGO.COM (https://apetigo.com/pl/privacy-policy).</p>
      <p>Celem Polityki jest przedstawienie zasad przetwarzania danych osobowych w ramach Platformy, a poprzez jej udostępnienie przekazanie podmiotom danych niezbędnych informacji na temat przetwarzania ich danych osobowych zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (dalej jako <strong>„RODO”</strong>).</p>
      <p>W związku z tym, że w ramach Platformy mogą się pojawiać odnośniki do zewnętrznych stron internetowych, które nie należą do Administratora i za które Administrator nie ponosi odpowiedzialności, Administrator zachęca do zapoznania się z politykami prywatności zamieszczonymi na zewnętrznych stronach internetowych, które należą do innych administratorów.</p>
      <h2>II. ADMINISTRATOR DANYCH OSOBOWYCH</h2>
      <p>Administratorem Twoich danych osobowych zbieranych za pośrednictwem Platformy jest APETIGO.COM spółka z ograniczoną odpowiedzialnością z siedzibą w Zielonej Górze, adres: ul. Wrocławska nr 17B, 65-427 Zielona Góra, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Zielonej Górze, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000925315, NIP: 9731080892; kapitał zakładowy 100.000,00 złotych (dalej jako <strong>„Administrator”</strong>). Administrator przetwarza Twoje dane osobowe w celach wskazanych w pkt II Polityki.</p>
      <p>Kontakt z Administratorem w sprawach związanych z ochroną danych osobowych jest możliwy pod ww. adresem, a także adresem e-mail: rodo@apetigo.com.</p>
      <p>W przypadku, gdy w ramach Platformy składasz Zamówienie oraz zawierasz Umowę dot. Zamówienia, Twoje dane osobowe są udostępnianie przez Administratora odpowiedniej Restauracji. Restauracja jest administratorem Twoich danych osobowych w zakresie realizacji Zamówienia, w tym Umowy dot. Zamówienia zawartej z Restauracją, obowiązków z nią związanych, obsługi posprzedażowej, a także ustalenia, dochodzenia lub obrony ewentualnych roszczeń związanych z taką Umową dot. Zamówienia. Restauracja jako odrębny administrator danych osobowych jest zobowiązana do przetwarzania danych osobowych zgodnie z obowiązującymi przepisami prawa, w szczególności RODO, i ponosi pełną odpowiedzialność za ich prawidłowe przetwarzanie. W przypadku pytań dotyczących sposobu, w jaki Restauracja przetwarza dane osobowe, należy skontaktować się bezpośrednio z Restauracją.</p>
      <h2>III. CELE ORAZ PODSTAWA PRAWNA PRZETWARZANIA DANYCH OSOBOWYCH</h2>
      <p>Dane osobowe zbierane za pośrednictwem Platformy (w szczególności imię i nazwisko; adres poczty elektronicznej, numer telefonu kontaktowego, adres dostawy, dane dotyczące aktywności w ramach Platformy, a w przypadku klientów niebędących konsumentami także firma (nazwa), numer identyfikacji podatkowej - NIP) mogą być przetwarzane w następujących celach:</p>
      <ol type={"a"}>
        <li>w celu umożliwienia Ci korzystania z Platformy, w tym Usług Elektronicznych świadczonych za jej pośrednictwem (np. obsługa Konta, składanie Zamówień, dodawanie relacji, ocen oraz opinii dotyczących Zamówienia, Produktów oraz Restauracji). W takich przypadkach podstawą prawną przetwarzania danych osobowych jest <strong>art. 6 ust. 1 lit. b) RODO</strong>, tj. niezbędność do wykonania umowy (Umowa o Świadczenie Usług) lub do podjęcia działań na Twoje żądanie przed zawarciem umowy, a w przypadku przetwarzania informacji o alergenach podstawą ich przetwarzania jest dobrowolne uzupełnienie profilu równoznaczne z wyrażeniem zgody na ich przetwarzanie informacji o nich.</li>
        <li>w celu wypełnienia obowiązków prawnych ciążących na Administratorze zgodnie z obowiązującymi przepisami. W takich przypadkach podstawą prawną przetwarzania danych osobowych jest <strong>art. 6 ust. 1 lit. c) RODO</strong>, tj. niezbędność do wypełnienia obowiązku prawnego ciążącego na Administratorze;</li>
        <li>w związku z prowadzeniem działań marketingowych, analitycznych, statystycznych przez Administratora (np. prezentowanie reklam i ofert - także dostosowywanych do Twoich preferencji, w tym z wykorzystaniem informacji zawartych w plikach cookie), a także zapewnieniem najwyższej jakości i bezpieczeństwa świadczonych usług oraz prowadzeniem z Tobą efektywnej komunikacji. W takich przypadkach podstawą prawną przetwarzania danych osobowych jest <strong>art. 6 ust. 1 lit. f) RODO</strong>, tj. prawnie uzasadniony interes realizowany przez Administratora lub przez stronę trzecią;</li>
        <li>w celu ustalenia, obrony i dochodzenia roszczeń, które mogą powstać w ramach relacji między Tobą a Administratorem oraz inne cele, które są niezbędne do realizacji prawnie uzasadnionych interesów Administratora lub strony trzeciej. W takich przypadkach podstawą prawną przetwarzania danych osobowych jest <strong>art. 6 ust. 1 lit. f) RODO</strong>, tj. prawnie uzasadniony interes realizowany przez Administratora lub przez stronę trzecią.</li>
      </ol>
      <p>Dodatkowo, jeśli podałeś swój adres e-mail lub nr telefonu oraz wyraziłeś zgodę na kontakt e-mailowy lub telefoniczny, możemy przesłać na Twój adres e-mail / nr telefonu informacje handlowe (np. naszą ofertę lub reklamę) lub zadzwonić do Ciebie w tym celu. Masz prawo wycofać udzieloną w tym zakresie zgodę w dowolnym momencie, wysyłając wiadomość na adres: rodo@apetigo.com lub klikając odpowiedni link podany w wiadomości e-mail wysłanej do Ciebie – nie ma to jednak wpływu na legalność operacji (wysyłkę informacji handlowych) dokonanych na podstawie Twojej zgody do czasu jej wycofania. W przypadku wycofania zgody nie będziemy przesyłać Ci informacji handlowych drogą mailową lub telefoniczną.</p>
      <p>Podanie danych osobowych jest dobrowolne, lecz konieczne dla korzystania z Platformy, a także realizacji Umowy o Świadczenie Usług zawartej z Administratorem oraz innych wymienionych wyżej celów przetwarzania danych osobowych.</p>
      <h2>IV. ODBIORCY DANYCH OSOBOWYCH</h2>
      <p>Odbiorcami Twoich danych osobowych mogą być w szczególności podmioty, które technicznie pomagają sprawnie prowadzić Platformę, w tym komunikację (np. wspierają nas w wysyłaniu wiadomości e-mail, a w przypadku działań reklamowych – także w kampaniach marketingowych), dostawcy usług hostingu lub usług teleinformatycznych, podmioty obsługujące płatności elektroniczne lub płatności kartą płatniczą w Platformie, organizacje, które serwisują oprogramowanie, jak również dostawcy usług prawnych i doradczych.</p>
      <p>Twoje dane osobowe mogą zostać również udostępnione Restauracji, u której zostało złożone Zamówienie w ramach Platformy, w celu wykonania Umowy o Świadczenie Usług zawartej z Administratorem oraz umożliwienia Restauracji zrealizowania Umowy dot. Zamówienia zawartej z Restauracją. Szczegółowe informacje dotyczące przetwarzania Twoich danych osobowych przez Restauracje są udostępniane przez Restauracje (np. w ramach strony internetowej Restauracji lub w inny sposób przewidziany przez Restaurację). W przypadku pytań dotyczących sposobu, w jaki Restauracja przetwarza dane osobowe, należy skontaktować się bezpośrednio z Restauracją.</p>
      <h2>V. OKRES PRZECHOWYWANIA DANYCH OSOBOWYCH</h2>
      <p>Twoje dane osobowe są przetwarzane <strong>(i)</strong> w związku z zawartą Umową o Świadczenie Usług - przez czas trwania zawartej z umowy, <strong>(ii)</strong> w związku z realizowaniem prawnie uzasadnionych interesów Administratora lub osób trzecich (np. działania marketingowe, statystyczne i analityczne) – przez czas istnienia prawnie uzasadnionego interesu Administratora lub strony trzeciej, ewentualnie do czasu wniesienia przez Ciebie sprzeciwu wobec takiego przetwarzania. Ponadto Twoje dane osobowe mogą być również przetwarzane przez czas niezbędny do (iii) realizacji obowiązków Administratora wynikających z obowiązujących przepisów prawa, oraz (iv) ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami. W zależności od zakresu danych osobowych i celów ich przetwarzania, Twoje dane osobowe są przechowywane przez różny okres. W każdym przypadku decyduje najdłuższy termin przechowywania.</p>
      <h2>VI. PRZEKAZYWANIE DANYCH OSOBOWYCH DO PAŃSTW TRZECICH</h2>
      <p>Twoje dane osobowe są co do zasady przetwarzane w ramach Europejskiego Obszaru Gospodarczego (dalej jako <strong>„EOG”</strong>). W przypadku nawiązania przez Administratora współpracy z podmiotem spoza tego obszaru, Twoje dane osobowe mogą być wyjątkowo przekazywane także do państwa, w którym podmiot ten ma siedzibę. W takim przypadku przekazanie danych będzie następowało tylko (i) w niezbędnym zakresie, związanym ze świadczeniem usług przez ten podmiot na rzecz Administratora; (ii) przy zastosowaniu wymogów określonych w rozdziale 5 RODO, w tym odpowiednich zabezpieczeń jak standardowe klauzule umowne przyjęte na mocy decyzji Komisji Europejskiej. Możesz uzyskać kopię zabezpieczeń danych osobowych przekazywanych poza EOG, kontaktując się z Administratorem.</p>
      <h2>VII. TWOJE PRAWA</h2>
      <p>W związku z przetwarzaniem Twoich danych osobowych przysługują Ci pewne prawa – piszemy o nich poniżej. Aby chronić Twoje dane, zastrzegamy sobie prawo do zwrócenia się do Ciebie z dodatkowymi pytaniami w celu potwierdzenia Twojej tożsamości przed wykonaniem Twojego żądania.</p>
      <p>Jako osobie, której dane osobowe przetwarzamy, przysługują Ci następujące uprawnienia:</p>
        <ol type={"a"}>
          <li>dostęp do danych osobowych (w tym np. otrzymania informacji, które dane osobowe są przetwarzane);</li>
          <li>żądanie sprostowania i ograniczenia przetwarzania danych osobowych (np. gdy dane osobowe są niedokładne lub niekompletne, możesz zażądać wprowadzenia odpowiednich zmian w swoich danych osobowych);</li>
          <li>usunięcia danych osobowych (np. gdy były one przetwarzane niezgodnie z prawem);</li>
          <li>wniesienie sprzeciwu wobec przetwarzania danych osobowych (możesz sprzeciwić się przetwarzaniu Twoich danych osobowych, gdy podstawą prawną ich przetwarzania jest prawnie uzasadniony interes Administratora lub osoby trzeciej);</li>
          <li>przeniesienie danych osobowych, które zostały przez Ciebie dostarczone Administratorowi i które są przetwarzane w sposób zautomatyzowany, a przetwarzanie odbywa się na podstawie zgody lub na podstawie niezbędności do wykonania umowy, np. do innego administratora;</li>
          <li>wniesienie skargi do organu nadzorczego - Prezes Urzędu Ochrony Danych Osobowych (ul. Stawki 2, 00-193 Warszawa);</li>
          <li>cofnięcie zgody (wyłącznie w zakresie w jakim podstawą przetwarzania jest zgoda) w dowolnym momencie, przy czym cofnięcie zgody nie wpływa na przetwarzanie dokonywane przez Administratora zgodnie z prawem przed jej cofnięciem – w przypadku cofnięcia zgody, pamiętaj, że nadal możemy przetwarzać Twoje dane osobowe, jeśli zaktualizowała się inna przesłanka przetwarzania (np. przetwarzanie danych osobowych jest konieczne, aby spełnić ciążący na Administratorze obowiązek prawny).</li>
        </ol>
      <h2>VIII. PLIKI COOKIE</h2>
      <h3>1. PLIKI COOKIE STOSOWANE PRZEZ ADMINISTRATORA</h3>
      <p>Administrator wykorzystuje w ramach Platformy pliki typu cookie (małe pliki tekstowe, tzw. ciasteczka) lub technologie o funkcjonalności podobnej do plików cookie, które są zapisywane w urządzeniu końcowym w związku z korzystaniem z Platformy.</p>
      <p>W ramach Platformy stosowane są pliki cookie: „sesyjne” (session cookie) oraz <strong>„stałe”</strong> (persistent cookie). Pliki cookie <strong>„sesyjne”</strong> są plikami tymczasowymi, które przechowywane są w Twoim urządzeniu końcowym do czasu wylogowania, opuszczenia Platformy. Pliki cookie „stałe” są przechowywane w Twoim urządzeniu końcowym przez czas określony w parametrach plików cookie lub do czasu ich usunięcia.</p>
      <p>W ramach Platformy mogą być stosowane następujące rodzaje plików cookie:</p>
      <ol type={"a"}>
        <li><strong>pliki cookie niezbędne</strong> - są zawsze aktywne i mają zapewnić poprawne i bezpieczne korzystanie z Platformy i jej podstawowych funkcji, w tym poruszanie się po Platformie, logowanie lub uzyskanie dostępu do Konta oraz innych funkcjonalności związanych z Kontem oraz naszymi usługami. Dzięki nim możemy także zapobiegać oszustwom i wykrywać błędy;</li>
        <li><strong>pliki cookie analityczne / funkcjonalne</strong> - możesz zdecydować, czy te pliki cookie mają być instalowane na urządzeniu końcowym. Pomagają nam one dostarczać wydajną i przyjazną Platformę, dopasowaną do Twoich preferencji i dostarczają nam narzędzi do jej stałego ulepszania. Pozwalają nam one weryfikować m.in. liczbę osób odwiedzających Platformę, sposób w jaki korzystasz z Platformy i czy kierowana przez nas komunikacja jest dla Ciebie interesująca, np. poprzez dane dotyczące częstotliwości odwiedzin Platformy lub najczęściej wykorzystywanych funkcjonalnościach;</li>
        <li><strong>pliki cookie reklamowe</strong> - możesz zdecydować, czy te pliki cookie mają być instalowane na urządzeniu końcowym. Służą one dostosowywaniu treści marketingowych do Twoich potrzeb i zainteresowań. Te pliki cookie mogą być także wykorzystywane do dostosowania treści i reklam przez podmioty trzecie, z którymi współpracujemy.</li>
      </ol>
      <p>Szczegółowe informacje w zakresie stosowanych plików cookie w ramach Platformy dostępne są w tabeli poniżej:</p>
      <table className={"center-table"}>
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Dostawca</th>
            <th>Cel</th>
            <th>Okres funkcjonowania</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>_ga</td>
            <td>Podmiot trzeci – Google Ireland</td>
            <td>Funkcjonalne i statystyczne</td>
            <td>1 rok</td>
          </tr>
          <tr>
            <td>_ga_{"{token}"}</td>
            <td>Podmiot trzeci – Google Ireland</td>
            <td>Personalizowane</td>
            <td>1 rok</td>
          </tr>
        </tbody>
      </table>
      <p>Poniżej wskazujemy także odnośniki do odpowiednich polityk prywatności podmiotów trzecich:</p>
      <table className={"center-table"}>
        <thead>
          <tr>
            <th>Podmiot trzeci</th>
            <th>Polityka prywatności</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>Google Ireland</td>
          <td>https://policies.google.com/?hl=pl</td>
        </tr>
        <tr>
          <td>CODE WHALE INC.</td>
          <td>https://whalestack.online/gdpr-privacy-notice/</td>
        </tr>
        <tr>
          <td>SENTRY</td>
          <td>https://sentry.io/trust/privacy/</td>
        </tr>
        </tbody>
      </table>
      <h3>2. PODSTAWA PRAWNA STOSOWANIA PLIKÓW COOKIE</h3>
      <p>Uzyskiwanie i przechowywanie informacji z wykorzystaniem plików cookies, za wyjątkiem sytuacji, gdy jest to konieczne dla zapewnienia prawidłowego działania Platformy oraz realizacji jej funkcjonalności (dostarczenia usługi świadczonej drogą elektroniczną, kiedy to Administrator bazuje na przepisie prawa – art. 173 ust. 3 pkt 2 Prawa Telekomunikacyjnego), jest możliwe wyłącznie na podstawie Twojej zgody.</p>
      <p>Możesz wyrazić zgodę na instalowanie plików cookie za pośrednictwem ustawień oprogramowania (przeglądarki internetowej) lub podczas pierwszego wejścia na Platformę z poziomu belki plików cookie, który pojawia się w widocznym i łatwo dostępnym miejscu. Wycofanie zgody nie wpłynie na zgodność z prawem działań, których dokonano na podstawie zgody przed jej wycofaniem. Ograniczenie stosowania plików cookie może wpłynąć na niektóre funkcjonalności dostępne w ramach Platformy, uniemożliwić lub istotnie utrudnić prawidłowe korzystanie z Platformy.</p>
      <p>Pliki cookie zbierają różnego rodzaju informacje, które co do zasady nie stanowią danych osobowych (nie pozwalają na Twoją identyfikację). Niektóre informacje, w zależności od ich zawartości i sposobu wykorzystania, mogą jednak zostać przypisane konkretnej osobie, a tym samym zostać uznane za dane osobowe. W stosunku do tego typu informacji stosuje się odpowiednio postanowienia Polityki dotyczące danych osobowych. W zakresie, w jakim pliki cookie będą zawierać Twoje dane osobowe, podstawą ich przetwarzania jest prawnie uzasadniony interes Administratora lub strony trzeciej – art. 6 ust. 1 lit. f) RODO. W niektórych przypadkach, w szczególności, jeśli wymaga tego dany dostawca plików cookie (podmiot trzeci), podstawą przetwarzania danych osobowych może być także Twoja dobrowolna zgoda. Dostawcy plików cookie (podmioty trzecie) w pewnych przypadkach mogą działać jako administratorzy Twoich danych osobowych.</p>
      <h2>IX. POSTANOWIENIA KOŃCOWE</h2>
      <p>Administrator zastrzega sobie prawo zmiany Polityki - może to nastąpić w szczególności z następujących ważnych powodów: (i) zmiany obowiązujących przepisów, w szczególności w zakresie ochrony danych osobowych, prawa telekomunikacyjnego, usług świadczonych drogą elektroniczną oraz regulujących prawa konsumentów, wpływające na prawa i obowiązki Administratora lub użytkownika Platformy; (ii) rozwój funkcjonalności lub usług elektronicznych podyktowany postępem technologii internetowej, w tym stosowanie / wdrożenie nowych rozwiązań technologicznych lub technicznych, mające wpływ na zakres Polityki.</p>
      <p>Administrator każdorazowo umieści w ramach Platformy informację o zmianach w Polityce. Wraz z każdą zmianą nowa wersja Polityki będzie się pojawiać z nową datą.</p>
      <p>Wersja Polityki z dnia 14.11.2022 r.</p>
    </article>
  );
}

export default PrivacyPolicy;
