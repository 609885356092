import "./App.scss";


declare type AppColorMode = "light" | "dark" | "white" | null | undefined;

function AlcoholRegulations() {
  const queryParams = new URLSearchParams(window.location.search);
  const theme = queryParams.get("theme") as AppColorMode;
  let className = 'light-theme';
  if(theme === "dark"){
     className = "dark-theme";
  } else if(theme === "white"){
     className = "white-theme"
  }
  return (
    <article className={`${className} numericWithLetters`}>
        <h1>REGULAMIN ZAMAWIANIA NAPOJÓW ALKOHOLOWYCH - PLATFORMA APETIGO.COM</h1>
        <p><strong>Drogi Użytkowniku!</strong></p>
        <p>Regulamin zamawiania napojów alkoholowych określa warunki i zasady składania Zamówień na Napoje Alkoholowe za pośrednictwem Platformy APETIGO. Pamiętaj, że Umowy dot. Zamówienia są zawierane bezpośrednio z Restauracją. W zakresie nieuregulowanym w niniejszym regulaminie, zastosowanie znajduje wiążący Cię Regulamin Platformy APETIGO - https://apetigo.com/regulamin-apetigo. W razie wątpliwości lub pytań dotyczących treści zachęcamy do kontaktu: info@apetigo.com.</p>
        <p>Pamiętaj, że sprzedaż Napojów Alkoholowych jest ściśle uregulowana prawnie i jest możliwa wyłącznie osobom spełniającym wymagania przewidziane w powszechnie obowiązujących przepisach prawa, w szczególności musisz mieć ukończone 18 lat oraz Twoje zachowanie nie może wskazywać na znajdowanie się w stanie nietrzeźwości.</p>
        <ol>
            <li>DEFINICJE
                <ol>
                   <li>Użyte w Regulaminie zwroty pisane wielką literą należy rozumieć w następujący sposób:
                       <ol>
                            <li><strong>Apetigo</strong> – APETIGO.COM spółka z ograniczoną odpowiedzialnością z siedzibą w Zielonej Górze, adres: ul. Wrocławska nr 17B, 65-427 Zielona Góra, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Zielonej Górze; VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000925315, NIP: 9731080892; kapitał zakładowy 100.000,00 złotych; adres poczty elektronicznej: info@apetigo.com;</li>
                            <li><strong>Cena</strong> – określona w złotych polskich lub w innej walucie kwota brutto (uwzględniająca podatek), którą Klient zobowiązany jest zapłacić w związku z Zamówieniem i zawarciem Umowy dot. Zamówienia;</li>
                            <li><strong>Klient</strong> – osoba fizyczna, która ukończyła 18 lat oraz spełnia wymogi określone w Regulaminie, posiadająca zdolność do czynności prawnych w zakresie umożliwiającym skuteczne dokonanie określonej czynności w ramach Platformy. Klientem może być również osoba prawna albo jednostka organizacyjna niebędąca osobą prawną, której przepisy przyznają zdolność prawną – w tej sytuacji osoba odbierająca Napoje Alkoholowe objęte Umową dot. Zamówienia musi mieć ukończone 18 lat oraz spełniać wymogi przewidziane w Regulaminie w stosunku do Klienta. Klient nie może dokonywać zakupu Napojów Alkoholowych w celu ich dalszej odsprzedaży;</li>
                            <li><strong>Konsument</strong> – Klient, który jest osobą fizyczną dokonującą z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową;</li>
                            <li><strong>Napój Alkoholowy / Produkt</strong> – towar przeznaczony do spożycia zawierający alkohol etylowy pochodzenia rolniczego w stężeniu przekraczającym 0,5% objętościowych alkoholu, znajdujący się w Menu;</li>
                            <li><strong>Platforma</strong> – oprogramowanie Apetigo (internetowa platforma handlowa), umożliwiająca Klientowi zapoznanie się z ofertą Restauracji oraz złożenie Zamówienia obejmującego Napoje Alkoholowe, którego zasady funkcjonowania określa wiążący Klienta Regulamin Platformy;</li>
                            <li><strong>Przedsiębiorca</strong> – Klient, niebędący Konsumentem, który zawiera Umowę dot. Zamówienia w celu związanym bezpośrednio lub pośrednio z prowadzoną działalnością gospodarczą lub zawodową;</li>
                            <li><strong>Punkt Sprzedaży</strong> – punkt sprzedaży Napojów Alkoholowych w rozumieniu Ustawy, w którym Restauracja prowadzi sprzedaż Napojów Alkoholowych, objęty odpowiednim zezwoleniem na ich sprzedaż;</li>
                            <li><strong>Regulamin</strong> – niniejszy dokument określający warunki i zasady składania Zamówień i zawierania Umów dot. Zamówień obejmujących Napoje Alkoholowe za pośrednictwem Platformy. Regulamin jest regulaminem, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, a także stanowi wzorzec umowny w rozumieniu art. 384 § 1 Kodeksu Cywilnego;</li>
                            <li><strong>Regulamin Platformy</strong> – dokument dostępny pod adresem: https://apetigo.pl/regulamin-apetigo, określający zasady funkcjonowania Platformy, w tym korzystania z Usług Elektronicznych, określający w szczególności prawa oraz obowiązki Klienta i Apetigo oraz Restauracji (w zakresie w jakim Restauracje są stronami umów z Klientami). Regulamin Platformy jest regulaminem, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, a także stanowi wzorzec umowny w rozumieniu art. 384 § 1 Kodeksu Cywilnego;</li>
                            <li><strong>Restauracja</strong> – przedsiębiorca w rozumieniu Kodeksu Cywilnego, z którym można za pośrednictwem Platformy zawierać Umowy dot. Zamówienia obejmujące Napoje Alkoholowe;</li>
                            <li><strong>Umowa dot. Zamówienia</strong> – umowa dot. Zamówienia zawierana pomiędzy Restauracją a Klientem za pośrednictwem Platformy, której przedmiotem jest sprzedaż Napojów Alkoholowych przez Restaurację na rzecz Klienta; Apetigo nie jest stroną Umowy dot. Zamówienia;</li>
                            <li><strong>Ustawa</strong> – ustawa z dnia 26 października 1982 r. o wychowaniu w trzeźwości i przeciwdziałaniu alkoholizmowi (tj. Dz. U. z 2023 r. poz. 165 z późn. zm.);</li>
                            <li><strong>Zamówienie</strong> – oświadczenie woli Klienta wyrażające wolę zawarcia Umowy dot. Zamówienia, określające Napoje Alkoholowe, co do których Klient składa ofertę zawarcia umowy, dane Klienta konieczne dla ewentualnego zawarcia i wykonania umowy, oraz formę odbioru. Zamówienie każdego Produktu jest traktowane jako niezależna oferta Klienta zawarcia umowy (ułatwienie techniczne). Akceptacja Zamówienia oznacza zawarcie Umowy dot. Zamówienia.</li>   
                       </ol>
                   </li>
                   <li>Pojęcia pisane wielką literą, a niezdefiniowane w Regulaminie, mają znaczenie jakie nadaje im Regulamin Platformy.</li>
                </ol>
            </li>
            <li>POSTANOWIENIA OGÓLNE
                <ol>
                    <li>Przedmiotem Regulaminu jest określenie ogólnych warunków i zasad składania Zamówień oraz zawierania Umów dot. Zamówień obejmujących Napoje Alkoholowe za pośrednictwem Platformy. Regulamin skierowany jest zarówno do Klientów będących Konsumentami, jak i Przedsiębiorców. Regulamin stosuje się wyłącznie do Zamówień oraz Umów dot. Zamówień obejmujących Napoje Alkoholowe. Akceptacja Regulaminu jest dobrowolna, ale każdorazowo konieczna w celu złożenia Zamówienia i zawarcia Umowy dot. Zamówienia obejmującą Napoje Alkoholowe za pośrednictwem Platformy.</li>
                    <li>Informacje prezentowane w ramach Platformy stanowią jedynie zaproszenie do zawarcia umowy w rozumieniu art. 71 Kodeksu Cywilnego, kierowane do Klientów, a nie ofertę w myśl przepisów Kodeksu Cywilnego.</li>
                    <li>Regulamin stosuje się wyłącznie do Zamówień oraz Umów dot. Zamówień obejmujących Napoje Alkoholowe dokonywanych za pośrednictwem Platformy. Regulamin nie ma zastosowania do innych umów zawieranych z Restauracją, w tym w Punktach Sprzedaży, bez wykorzystania przez Klienta Platformy.</li>
                    <li>W zakresie nieuregulowanym w Regulaminie, odpowiednie zastosowanie mają postanowienia Regulaminu Platformy oraz przepisy powszechnie obowiązującego prawa Rzeczypospolitej Polskiej.</li>
                </ol>
            </li>
            <li>ZŁOŻENIE ZAMÓWIENIA / ZAWARCIE UMOWY DOT. ZAMÓWIENIA
                <ol>
                    <li>W ramach Platformy, na zasadach określonych w Regulaminie, możliwe jest składanie Zamówień oraz zawieranie Umów dot. Zamówienia obejmujących Napoje Alkoholowe. Stroną Umów dot. Zamówień są Restauracje. Apetigo nie jest stroną Umów dot. Zamówienia i nie ponosi odpowiedzialności za ich realizację przez Restauracje.</li>
                    <li>Do składania Zamówień oraz zawarcia Umowy dot. Zamówienia uprawnieni są wyłącznie Klienci, którzy spełniają warunki przewidziane w Regulaminie oraz wymogi przewidziane w powszechnie obowiązujących przepisach prawa, w szczególności:
                        <ol>
                            <li>konieczne jest ukończenie 18 lat i posiadanie pełnej zdolności do czynności prawnych;</li>
                            <li>zachowanie Klienta nie może wskazywać, że znajduje się on w stanie nietrzeźwości;</li>
                            <li>zapoznanie się i akceptacja Regulaminu oraz złożenie oświadczeń przewidzianych w procesie składania Zamówienia, w szczególności w celu potwierdzenia spełniania przez Klienta wymogów przewidzianych w powszechnie obowiązujących przepisach prawa (w szczególności art. 15 Ustawy).</li>
                        </ol>
                    </li>
                    <li>Zawarcie Umowy dot. Zamówienia obejmującej Napoje Alkoholowe, pomiędzy Klientem a Restauracją, następuje po uprzednim złożeniu przez Klienta Zamówienia na wybrane Napoje Alkoholowe. Klient składa Zamówienie w ramach Platformy w następujący sposób:
                        <ol>
                            <li>Klient dodaje wybrany/e Napoje Alkoholowe do Koszyka;</li>
                            <li>Klient przechodzi do formularza danych do Zamówienia;</li>
                            <li>Klient składając Zamówienie ma możliwość wyboru formy realizacji Zamówienia w formie: (i) Zamówienie z odbiorem osobistym w Punkcie Sprzedaży; albo (ii) Zamówienie do wybranego stolika w Punkcie Sprzedaży (dostępne formy realizacji Zamówienia mogą się różnić w zależności od tego w jakiej Restauracji jest składane Zamówienie); w przypadku Zamówień obejmujących Napoje Alkoholowe nie ma możliwości wyboru Dostawy;</li>
                            <li>dodatkowo Klient wypełnia formularz Zamówienia w zakresie jego danych niezbędnych do zawarcia i realizacji Umowy dot. Zamówienia, a także składa odpowiednie oświadczenia (zaznaczenie odpowiednich checkboxów);</li>
                            <li>w przypadku Przedsiębiorców niezbędne jest także podanie nazwy (firmy) oraz numeru NIP. Dodatkowo podanie numeru NIP jest wymagane na etapie składania Zamówienia, jeśli Klient chce otrzymać fakturę VAT za Zamówienie. Odmowa wskazania numeru NIP na etapie składania Zamówienia przez Klientów wskazanych w niniejszym postanowieniu, oznacza brak możliwości wystawienia faktury VAT;</li>
                            <li>Klient wybiera sposób zapłaty Ceny i ewentualnych dodatkowych kosztów;</li>
                            <li>Klient z pomocą przycisku „Zamawiam i płacę” potwierdza Zamówienie za pomocą udostępnionej w tym celu funkcjonalności Platformy;</li>
                            <li>w zależności od wybranego sposobu zapłaty, Klient może zostać przekierowany do strony zewnętrznego dostawcy usług płatniczych, w celu dokonania płatności.</li>
                        </ol>
                    </li>
                    <li>Klient w odpowiedzi na Zamówienie otrzyma w ramach Platformy potwierdzenie otrzymania Zamówienia przez Restaurację i rozpoczęcia jego weryfikacji.</li>
                    <li>Po zweryfikowaniu Zamówienia, Klient otrzymuje powiadomienie w ramach Platformy z:
                        <ol>
                            <li>potwierdzeniem przyjęcia Zamówienia do realizacji albo</li>
                            <li>informacją o braku przyjęcia Zamówienia do realizacji.</li>
                        </ol>
                    </li>
                    <li>Umowa dot. Zamówienia zawarta jest z chwilą otrzymania przez Klienta powiadomienia wskazanego w pkt 3.5. lit. a). W przypadku nieprzyjęcia Zamówienia do realizacji, Umowa dot. Zamówienia nie zostaje zawarta, a Klient niezwłocznie, nie później niż w ciągu 5 dni, otrzyma zwrot płatności uiszczonych w związku Zamówieniem przy użyciu takiego samego sposobu płatności, jakiego użył. Restauracja ma prawo nie przyjąć Zamówienia do realizacji, w szczególności w przypadku naruszenia przez Klienta postanowień pkt 3 ust. 10 Regulaminu Platformy, powzięcia wątpliwości co do spełnienia przez Klienta wymagań wskazanych w pkt 3.2. Regulaminu, nieuiszczenia płatności w związku z Zamówieniem lub wystąpienia zdarzeń, które skutkują brakiem możliwości przyjęcia Zamówienia do realizacji, np. brak Produktów.</li>
                    <li>Całkowita wartość Zamówienia obejmuje Cenę oraz ew. inne koszty związane z Zamówieniem. O łącznej Cenie wraz z podatkami oraz ew. innych kosztach związanych z Zamówieniem, Klient jest informowany w trakcie składania Zamówienia, w tym również w chwili wyrażenia przez Klienta woli związania się Umową dot. Zamówienia.</li>
                    <li>Miejscem zawarcia Umowy dot. Zamówienia obejmującej Napoje Alkoholowe jest Punkt Sprzedaży prowadzony przez Restaurację, z którą zawierana jest Umowa dot. Zamówienia. Wydanie Napojów Alkoholowych będących przedmiotem Umowy dot. Zamówienia następuje w Punkcie Sprzedaży, o którym mowa w zdaniu poprzedzającym.</li>
                    <li>W razie wątpliwości co do wieku Klienta lub osoby odbierającej Napoje Alkoholowe, Restauracja może skontaktować się z Klientem w celu potwierdzenia tożsamości, a także zażądać potwierdzenia wieku przed wydaniem Napojów Alkoholowych, poprzez okazanie dokumentu stwierdzającego wiek Klienta lub osoby odbierającej Napoje Alkoholowe.</li>
                    <li>Jeżeli Klient lub osoba odbierająca Napoje Alkoholowe nie spełnia wymagań wskazanych w pkt 3.2. Regulaminu, Restauracja odmawia wydania Napojów Alkoholowych, a Umowa dot. Zamówienia ulega rozwiązaniu. Restauracja każdorazowo informuje Klienta o tym fakcie, a płatność uiszczona przez Klienta w związku z Umową dot. Zamówienia, która uległa rozwiązaniu, zostanie zwrócona Klientowi nie później niż w ciągu 5 dni od dnia rozwiązania Umowy dot. Zamówienia. Zwrot płatności uiszczonych w związku Zamówieniem zostanie dokonany przy użyciu takiego samego sposobu płatności, jakiego użył Klient.</li>
                    <li>Restauracja każdorazowo wystawi i wyda Klientowi paragon fiskalny (lub fakturę VAT, jeżeli dotyczy), potwierdzający uiszczenie Ceny. Nie ma możliwości zakupu Napojów Alkoholowych na kredyt lub pod zastaw.</li>
                </ol>
            </li>
            <li>REKLAMACJE
                <ol>
                    <li>Restauracje są zobowiązane do dostarczenia Napojów Alkoholowych zgodnych z Umową dot. Zamówienia i ponoszą odpowiedzialność za należytą realizację zawartej umowy, zgodnie z powszechnie obowiązującymi przepisami prawa, w tym przepisami ustawy z dnia 30 maja 2014 r. o prawach konsumenta oraz Kodeksu Cywilnego. Apetigo nie ponosi odpowiedzialności za należytą realizację Umów dot. Zamówień, a także nie udziela żadnych gwarancji. Apetigo jest upoważnione do realizowania obsługi posprzedażowej Klientów w imieniu i na rzecz Restauracji.</li>
                    <li>Reklamacje związane z Umową dot. Zamówienia można składać w szczególności w następujący sposób:
                        <ol>
                            <li>adres poczty elektronicznej: info@apetigo.com;</li>
                            <li>za pośrednictwem dedykowanego formularza w ramach Platformy.</li>
                        </ol>
                    </li>
                    <li>Zaleca się podanie w opisie reklamacji numeru Zamówienia oraz informacji i okoliczności dotyczących przedmiotu reklamacji, w szczególności rodzaju i daty wystąpienia niezgodności z Umową dot. Zamówienia oraz danych kontaktowych składającego reklamację – ułatwi to i przyspieszy rozpatrzenie reklamacji. Wymogi podane w zdaniu poprzednim mają formę jedynie zalecenia i nie wpływają na skuteczność reklamacji złożonych z pominięciem zalecanego opisu reklamacji.</li>
                    <li>Reklamacja zostanie rozpatrzona niezwłocznie, nie później niż w terminie 14 dni od dnia jej złożenia. Jeżeli reklamacja nie zostanie rozpatrzona w terminie 14 dni od dnia jej złożenia, uważa się, że reklamacja jest uzasadniona.</li>
                </ol>
            </li>
            <li>PRAWO ODSTĄPIENIA OD UMOWY
                <ol>
                    <li>Z zastrzeżeniem pkt 5.7. poniżej, Konsument, który zawarł umowę na odległość, może od niej odstąpić w terminie 14 dni bez podawania przyczyny i bez ponoszenia jakichkolwiek kosztów innych niż te przewidziane przez powszechnie obowiązujące przepisy prawa. Do zachowania terminu wystarczy złożenie przez Konsumenta odpowiedniego oświadczenia Restauracji. Konsument może złożyć jakiekolwiek jednoznaczne oświadczenie, w którym poinformuje o swoim odstąpieniu od umowy. Oświadczenie o odstąpieniu od umowy należy złożyć na adres określony przez Restaurację w ramach jego strony / profilu. W przypadku odstąpienia od umowy zawartej na odległość umowę uważa się za niezawartą.</li>
                    <li>Konsument może skorzystać z wzoru formularza odstąpienia, który stanowi załącznik do Regulaminu, jednak nie jest to obowiązkowe. W przypadku chęci odstąpienia od Umowy dot. Zamówienia prosi się o dodanie w miarę możliwości w oświadczeniu o odstąpieniu numeru Zamówienia – może to przyśpieszyć obsługę złożonego oświadczenia o odstąpieniu.</li>
                    <li>Bieg terminu do odstąpienia od Umowy dot. Zamówienia rozpoczyna się od dnia objęcia Produktu w posiadanie przez Konsumenta lub wskazaną przez niego osobę trzecią, a w przypadku Umowy dot. Zamówienia, która:
                        <ol>
                            <li>obejmuje wiele Produktów, które są dostarczane osobno, partiami lub w częściach – od objęcia w posiadanie ostatniego Produktu, partii lub części; albo</li>
                            <li>polega na regularnym dostarczaniu Produktów przez czas oznaczony – od objęcia w posiadanie pierwszego z Produktów.</li>
                        </ol>
                    </li>
                    <li>W przypadku odstąpienia przez Konsumenta od Umowy dot. Zamówienia, Restauracja ma obowiązek niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od umowy, zwrócić Konsumentowi wszystkie dokonane przez niego płatności.</li>
                    <li>Zwrot płatności następuje przy użyciu takiego samego sposobu płatności, jakiego użył Konsument, chyba że Konsument wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami. Jeżeli Restauracja nie zaproponowała, że sam odbierze Produkt od Konsumenta, może wstrzymać się ze zwrotem płatności otrzymanych od Konsumenta do chwili otrzymania Produktu z powrotem lub dostarczenia przez Konsumenta dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.</li>
                    <li>Konsument ma obowiązek niezwłocznie, nie później niż w terminie 14 dni od dnia, w którym odstąpił od Umowy dot. Zamówienia, zwrócić Produkt Restauracji lub przekazać go osobie upoważnionej przez Restaurację do odbioru, chyba że Restauracja zaproponowała, że sama odbierze Produkt. Konsument pokrywa wszystkie bezpośrednie koszty zwrotu Produktu tytułem odstąpienia od umowy tj. koszty opakowania, zabezpieczenia i nadania Produktu, chyba że Restauracja zaproponowała, że sama je poniesie (np. w ramach czasowej promocji obowiązującej w Platformie). Do zachowania terminu wystarczy odesłanie Produktu przed jego upływem. Konsument zwraca Produkt na adres Restauracji wskazany w Platformie.</li>
                    <li><strong>[WYJĄTKI OD PRAWA DO ODSTĄPIENIA OD UMOWY]</strong> Prawo odstąpienia od umowy zawartej na odległość nie przysługuje Konsumentowi w odniesieniu do umów: (i) o świadczenie usług, za które konsument jest zobowiązany do zapłaty ceny, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną i uprzednią zgodą Konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od umowy, i przyjął to do wiadomości; (ii) w której przedmiotem świadczenia jest towar nieprefabrykowany, wyprodukowana według specyfikacji Konsumenta lub służący zaspokojeniu jego zindywidualizowanych potrzeb; (iii) w której przedmiotem świadczenia jest towar ulegający szybkiemu zepsuciu lub mający krótki termin przydatności do użycia; (iv) o świadczenie usług w zakresie gastronomii, jeżeli w umowie wskazano dzień lub okres jej świadczenia, (v) w której przedmiotem świadczenia są napoje alkoholowe, których cena została uzgodniona przy zawarciu umowy sprzedaży, a których dostarczenie może nastąpić dopiero po upływie 30 dni i których wartość zależy od wahań na rynku, nad którymi przedsiębiorca nie ma kontroli; (iv) w której przedmiotem świadczenia jest towar dostarczany w zapieczętowanym opakowaniu, którego po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu; (vi) w której przedmiotem świadczenia są towary, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi towarami.</li>
                    <li>Restauracja jest uprawniona do odstąpienia od realizacji Zamówienia (Umowy dot. Zamówienia) niezwłocznie po przyjęciu Zamówienia do realizacji (nie później niż w ciągu godziny od przyjęcia Zamówienia do realizacji) w następujących przypadkach: (i) Klient podał nieprawidłowe lub nieprawdziwe dane w ramach Zamówienia, (ii) wystąpienie siły wyższej (zdarzenie nadzwyczajne, zewnętrzne, pozostają poza jakąkolwiek kontrolą strony, które pozostawało niemożliwe do przewidzenia i zapobieżenia). W takim przypadku Klient niezwłocznie, nie później niż w ciągu 5 dni, otrzyma zwrot płatności uiszczonych w związku Zamówieniem przy użyciu takiego samego sposobu płatności, jakiego użył Klient. Klient jest informowany o odstąpieniu od Umowy dot. Zamówienia w ramach Platformy.</li>
                </ol>
            </li>
            <li>POSTANOWIENIA KOŃCOWE
                <ol>
                    <li>Regulamin wchodzi w życie w dniu: 6 lipca 2023 roku.</li>
                    <li>Apetigo zastrzega sobie prawo zmiany Regulaminu, w szczególności w następujących przypadkach:
                        <ol>
                            <li>zmiana przepisów prawa regulujących sprzedaż Napojów Alkoholowych przez Sprzedającego wpływająca na wzajemne prawa i obowiązki wynikające z umowy określonej Regulaminem, zmiana interpretacji powyższych przepisów prawa wskutek orzeczeń sądów, decyzji, rekomendacji lub zaleceń właściwych w danym zakresie urzędów lub organów;</li>
                            <li>zmiana sposobu składania Zamówień i realizacji Umów dot. Zamówień spowodowana względami technicznymi lub technologicznymi (w szczególności ze względu na zmiany dokonane w ramach Platformy);</li>
                            <li>zmiana zakresu, do którego stosuje się postanowienia Regulaminu, poprzez objęciem nim innego zakresu Produktów.</li>
                        </ol>
                    </li>
                    <li>W przypadku dokonania zmian w Regulaminie, udostępniony zostanie tekst jednolity Regulaminu w ramach Platformy. Do Zamówień oraz Umów dot. Zamówień obejmujących Napoje Alkoholowe znajduje zastosowanie Regulamin w brzmieniu obowiązującym w momencie składania Zamówienia. Klient jest każdorazowo zobowiązany do zapoznania się i akceptacji Regulaminu w momencie składania Zamówienia na Napoje Alkoholowe. Zmiana Regulaminu pozostaje bez znaczenia dla Umów dot. Zamówienia zawartych przed zmianą Regulaminu.</li>
                    <li>Wybór prawa Rzeczypospolitej Polskiej na podstawie Regulaminu nie pozbawia Konsumenta ochrony przyznanej mu na podstawie przepisów, których nie można wyłączyć w drodze umowy, na mocy prawa które zgodnie z właściwymi regulacjami byłoby właściwe w przypadku braku jego wyboru.</li>
                    <li>Wszelkie wątpliwości wynikające z interpretacji Regulaminu należy interpretować w sposób zapewniający zgodność niniejszego Regulaminu z bezwzględnie obowiązującymi przepisami prawa.</li>
                </ol>
            </li>
        </ol>
        <hr />
        <h2>ZAŁĄCZNIK NR 1 - WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY</h2>
        <p><i>WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY</i></p>
        <p><i>(formularz ten należy wypełnić i odesłać tylko w przypadku chęci odstąpienia od umowy)</i></p>
        <p><i><strong>– Adresat (Umowa dot. Zamówienia)::</strong></i></p>
        <p><i>[w tym miejscu należy wpisać dane Restauracji, w tym: pełny adres pocztowy oraz, o ile są dostępne, numer faksu i adres e-mail]</i></p>
        <p><i>– Ja/My(*) niniejszym informuję/informujemy(*) o moim/naszym odstąpieniu od umowy sprzedaży następujących rzeczy(*), umowy dostawy następujących rzeczy(*) umowy o dzieło polegającej na wykonaniu następujących rzeczy(*)/o świadczenie następującej usługi(*)</i></p>
        <p><i>– Data zawarcia umowy(*)/odbioru(*)</i></p>
        <p><i>– Imię i nazwisko konsumenta(-ów)</i></p>
        <p><i>– Adres konsumenta(-ów)</i></p>
        <p><i>– Podpis konsumenta(-ów) (tylko jeżeli formularz jest przesyłany w wersji papierowej)</i></p>
        <p><i>– Data</i></p>
        <p><i>(*) Niepotrzebne skreślić.</i></p>
    </article>
  );
}

export default AlcoholRegulations;
