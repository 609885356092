import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import PrivacyPolicy from "./PrivacyPolicy";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Regulations from "./Regulations";
import UserRegulations from "./UserRegulations";
import UserRegulationsV1 from "./UserRegulationsV1";
import AlcoholRegulations from "./AlcoholRegulations";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<PrivacyPolicy />} />
              <Route path="/regulations" element={<Regulations />} />
              <Route path="/user-regulations" element={<UserRegulations />} />
              <Route path="/user-regulations/v1" element={<UserRegulationsV1 />} />
              <Route path="/alcohol-regulations" element={<AlcoholRegulations />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
